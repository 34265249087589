.icon-box {
  padding: 30px 0;
}
.icon-box .container .data {
  display: flex;
}
.icon-box .container .data .content {
  width: 60%;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-box .container .data .content h3 {
  font-size: 30px;
  font-style: italic;
  font-weight: 700;
  line-height: 53.16px;
  color: var(--black);
}
.icon-box .container .data .content ul {
  padding-left: 20px;
  text-align: left;
}
.icon-box .container .data .content ul li {
  list-style-type: disc;
}
.icon-box .container .data .image {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-box .container .data .image img {
  width: 100%;
  height: 600px;
  -o-object-fit: cover;
     object-fit: cover;
}
.icon-box.content-image .data {
  flex-direction: row-reverse;
}
.icon-box.content-image .data .image img {
  height: 400px;
}
.icon-box.image-content.imgheight .data .image img {
  height: 400px;
}

.impactCarousel {
  margin-bottom: 80px;
}
.impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item {
  text-align: center;
}
.impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item img {
  width: 1080px;
  height: 590px;
  -o-object-fit: cover;
     object-fit: cover;
}
.impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item h4 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: var(--black);
  margin: 30px 0 20px;
}
.impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item p {
  text-align: left;
  margin-bottom: 0;
}
.impactCarousel .container .data .alice-carousel ul.alice-carousel__dots li.alice-carousel__dots-item {
  margin-right: 10px;
  background-color: rgb(217, 217, 217);
}
.impactCarousel .container .data .alice-carousel ul.alice-carousel__dots li.alice-carousel__dots-item.__active {
  background-color: var(--primary);
  width: 35px;
  border-radius: 5px;
}

.counter {
  background-color: rgb(29, 37, 44);
  padding: 100px;
}
.counter .container .data {
  display: grid;
  grid-gap: 0px 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0px;
  text-align: center;
}
.counter .container .data h5 {
  color: var(--white);
  font-size: 60px;
  line-height: 55px;
  font-weight: 700;
  padding: 0;
  margin-bottom: 20px;
  font-style: normal;
}
.counter .container .data p {
  color: var(--white);
  margin-bottom: 0;
}

@media (min-width: 1369px) and (max-width: 1600px) {
  .icon-box {
    padding: 30px 0;
  }
  .icon-box .container .data .content {
    width: 60%;
    padding: 40px;
  }
  .icon-box .container .data .content h3 {
    font-size: 28px;
    line-height: 36px;
  }
  .icon-box .container .data .image {
    width: 40%;
  }
  .icon-box .container .data .image img {
    width: 450px;
    height: 450px;
  }
  .icon-box.content-image .data {
    flex-direction: row-reverse;
  }
  .icon-box.image-content.imgheight .data .image img, .icon-box.content-image.imgheight .data .image img {
    height: 400px;
  }
  .impactCarousel {
    margin-bottom: 70px;
  }
  .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item img {
    width: 960px;
    height: 525px;
  }
  .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item h4 {
    font-size: 22px;
    line-height: 30px;
    margin: 30px 0 15px;
  }
  .counter {
    padding: 80px;
  }
  .counter .container .data {
    grid-gap: 0px 5px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .counter .container .data h5 {
    font-size: 50px;
    line-height: 45px;
    margin-bottom: 18px;
  }
}
@media (min-width: 1201px) and (max-width: 1368px) {
  .icon-box {
    padding: 30px 0;
  }
  .icon-box .container .data .content {
    width: 60%;
    padding: 40px;
  }
  .icon-box .container .data .content h3 {
    font-size: 28px;
    line-height: 36px;
  }
  .icon-box .container .data .image {
    width: 40%;
  }
  .icon-box .container .data .image img {
    width: 400px;
    height: 400px;
  }
  .icon-box.content-image .data {
    flex-direction: row-reverse;
  }
  .icon-box.image-content.imgheight .data .image img, .icon-box.content-image.imgheight .data .image img {
    height: 350px;
  }
  .impactCarousel {
    margin-bottom: 60px;
  }
  .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item img {
    width: 800px;
    height: 435px;
  }
  .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item h4 {
    font-size: 22px;
    line-height: 30px;
    margin: 25px 0 10px;
  }
  .impactCarousel .container .data .alice-carousel ul.alice-carousel__dots li.alice-carousel__dots-item.__active {
    width: 32px;
  }
  .counter {
    padding: 70px;
  }
  .counter .container .data {
    grid-gap: 0px 5px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .counter .container .data h5 {
    font-size: 40px;
    line-height: 35px;
    margin-bottom: 15px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .icon-box {
    padding: 30px 0;
  }
  .icon-box .container .data .content {
    width: 60%;
    padding: 30px;
  }
  .icon-box .container .data .content h3 {
    font-size: 27px;
    line-height: 32px;
    font-weight: 600;
  }
  .icon-box .container .data .content p br {
    display: none;
  }
  .icon-box .container .data .image {
    width: 40%;
  }
  .icon-box .container .data .image img {
    width: 350px;
    height: 350px;
  }
  .icon-box.content-image .data {
    flex-direction: row-reverse;
  }
  .icon-box.image-content.imgheight .data .image img, .icon-box.content-image.imgheight .data .image img {
    height: 350px;
  }
  .impactCarousel {
    margin-bottom: 60px;
  }
  .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item img {
    width: 600px;
    height: 330px;
  }
  .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin: 20px 0 10px;
  }
  .impactCarousel .container .data .alice-carousel ul.alice-carousel__dots li.alice-carousel__dots-item.__active {
    width: 32px;
  }
  .counter {
    padding: 60px;
  }
  .counter .container .data {
    grid-gap: 0px 5px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .counter .container .data h5 {
    font-size: 35px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .icon-box {
    padding: 30px 0;
  }
  .icon-box .container .data .content {
    width: 50%;
    padding: 30px 10px;
  }
  .icon-box .container .data .content h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
  .icon-box .container .data .content p br {
    display: none;
  }
  .icon-box .container .data .image {
    width: 50%;
  }
  .icon-box .container .data .image img {
    width: 350px;
    height: 350px;
  }
  .icon-box.content-image .data {
    flex-direction: row-reverse;
  }
  .icon-box.image-content.imgheight .data .image img, .icon-box.content-image.imgheight .data .image img {
    height: 100%;
  }
  .impactCarousel {
    margin-bottom: 50px;
  }
  .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item img {
    width: 600px;
    height: 330px;
  }
  .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin: 20px 0 10px;
  }
  .impactCarousel .container .data .alice-carousel ul.alice-carousel__dots li.alice-carousel__dots-item.__active {
    width: 30px;
  }
  .counter {
    padding: 60px 0;
  }
  .counter .container .data {
    grid-gap: 50px 5px;
    grid-template-columns: 1fr 1fr;
  }
  .counter .container .data h5 {
    font-size: 35px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .icon-box {
    padding: 30px 0;
  }
  .icon-box .container .data {
    flex-direction: column;
  }
  .icon-box .container .data .content {
    width: 100%;
    padding: 30px 10px;
    text-align: left;
    align-items: start;
  }
  .icon-box .container .data .content .animate__fadeInUp {
    width: 100%;
  }
  .icon-box .container .data .content h3 {
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    width: 100%;
  }
  .icon-box .container .data .content p br {
    display: none;
  }
  .icon-box .container .data .image {
    width: 100% !important;
    padding: 0 !important;
  }
  .icon-box .container .data .image img {
    width: 100%;
    height: 100%;
  }
  .icon-box.content-image .data {
    flex-direction: column;
  }
  .impactCarousel {
    margin-bottom: 80px;
  }
  .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item img {
    width: 500;
    height: 275px;
  }
  .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item p {
    font-size: 17px;
    line-height: 28px;
  }
  .impactCarousel .container .data .alice-carousel ul.alice-carousel__dots li.alice-carousel__dots-item.__active {
    width: 30px;
  }
  .counter {
    padding: 50px 0;
  }
  .counter .container .data {
    grid-gap: 50px 5px;
    grid-template-columns: 1fr 1fr;
  }
  .counter .container .data h5 {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 10px;
  }
}
@media (max-width: 600px) {
  .icon-box {
    padding: 20px 0;
  }
  .icon-box .container .data {
    flex-direction: column;
  }
  .icon-box .container .data .content {
    padding: 20px 0px 0;
  }
  .icon-box .container .data .content h3 {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    width: 100%;
  }
  .icon-box .container .data .image img {
    width: 100% !important;
    height: auto;
  }
  .icon-box .impactCarousel {
    margin-bottom: 80px;
  }
  .icon-box .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item img {
    width: 100%;
  }
  .icon-box .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item h4 {
    font-size: 20px;
    line-height: 28px;
    margin: 20px 0 10px;
  }
  .icon-box .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item p {
    line-height: 28px;
  }
  .icon-box .impactCarousel .container .data .alice-carousel ul.alice-carousel__dots {
    margin-top: 10px;
  }
  .icon-box .impactCarousel .container .data .alice-carousel ul.alice-carousel__dots li.alice-carousel__dots-item.__active {
    width: 20px;
  }
  .impactCarousel .container .data .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .item img {
    width: 100%;
    height: 200px;
  }
  .impactCarousel .container .data .alice-carousel ul.alice-carousel__dots li.alice-carousel__dots-item {
    margin-right: 5px;
  }
  .impactCarousel .container .data .alice-carousel ul.alice-carousel__dots li.alice-carousel__dots-item.__active {
    width: 8px;
  }
}/*# sourceMappingURL=about.css.map */