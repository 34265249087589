.pageTitle.ceo {
    background-color: #F8F8F8;

    .container-fluid {
        position: relative;
        padding: 0;

        .dots {
            position: absolute;
            top: -20px;
            left: 45%;
            width: auto;
            &.dot-pattern-ten-six.xl {
                section {
                    line-height: 25px;
                    div {
                        margin: 0 7px;
                    }
                }
            }
        }

        .data {
            display: flex;
            padding-top: 50px;

            .authorWrapper {
                padding-left: 250px;
                padding-top: 100px;
                max-width: 60%;
                display: flex;
                flex-direction: column;
                justify-content: end;

                .title {
                    h2 {
                        font-size: 40px;
                        line-height: 50px;
                        font-weight: 700;
                        color: var(--black);

                        span {
                            color: var(--primary);
                        }
                    }
                }

                .quote {
                    display: flex;
                    align-items: start;
                    gap: 40px;
                    margin: 40px 0;

                    img {}

                    p {
                        color: var(--black);
                        font-size: 25px;
                        line-height: 32px;
                    }
                }

                .content {
                    margin-bottom: 40px;
                    max-width: 90%;

                    p {
                        font-size: 22px;
                        line-height: 32px;
                    }
                }

                .authorBox {
                    position: relative;

                    &:after {
                        content: "";
                        width: 60%;
                        height: 2px;
                        background-color: var(--black);
                        position: absolute;
                        bottom: 30px;
                        right: 80px;
                    }

                    h3 {
                        font-size: 40px;
                        line-height: 48px;
                        color: var(--black);
                        font-weight: 600;
                    }

                    p {
                        font-size: 25px;
                        line-height: 32px;
                        color: var(--black);
                        font-weight: 500;
                    }
                }
            }

            .img {
                width: 40%;
                text-align: right;
                position: relative;
                display: flex;
                justify-content: end;
                align-items: center;

                .title {
                    display: none;
                }

                .after {
                    overflow: hidden;
                    width: 100%;
                    position: relative;
                    &:after {
                        // content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        background: #F8F8F8;
                        width: 225px;
                        height: 100%;
                        z-index: 1;
                        transform: skew(-25deg);
                    }
                    img {
                        &.ceoImage {
                            width: 80%;
                        }
                    }
                }
            
                .slanding1 {
                    width: 100px;
                    height: 500px;
                    background-color: transparent;
                    position: absolute;
                    bottom: 0px;
                    z-index: 9;
                    transform: skew(-25deg);
                    overflow: hidden;
                    left: 110px;
            
                    .animate__animated {
                        height: 100%;
                        .inner {
                            width: 100%;
                            height: 100%;
                            background: var(--primary);
                            position: absolute;
                            left: 0;
                            z-index: 999;
                            top: 0;
                        }
            
                    }
                }
            
                .slanding2 {
                    width: 100px;
                    height: 350px;
                    background-color: transparent;
                    position: absolute;
                    bottom: 0px;
                    z-index: 9;
                    transform: skew(-25deg);
                    overflow: hidden;
                    left: 175px;
            
                    .animate__animated {
                        height: 100%;
                        .inner {
                            width: 100%;
                            height: 100%;
                            background: var(--secondary);
                            position: absolute;
                            left: 0;
                            z-index: 999;
                            top: 0;
                        }
            
                    }
                }
            }
        }
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {
    .pageTitle.ceo {
    
        .container-fluid {
    
            .dots {
                left: 75%;
            }
    
            .data {
    
                .authorWrapper {
                    padding-left: 150px;
                    padding-top: 60px;
                    max-width: 70%;
    
                    .title {
                        h2 {
                            font-size: 36px;
                            line-height: 40px;
                        }
                    }
    
                    .quote {
                        gap: 25px;
                        margin: 40px 0 20px;

                        img {
                            width: 50px;
                        }
    
                        p {
                            font-size: 18px;
                            line-height: 30px;
                            font-weight: 500;
                        }
                    }
    
                    .content {
                        margin-bottom: 40px;
                        max-width: 90%;
    
                        p {
                            font-size: 18px;
                            line-height: 30px;
                        }
                    }
    
                    .authorBox {
    
                        &:after {
                            width: 60%;
                            bottom: 30px;
                            right: 80px;
                        }
    
                        h3 {
                            font-size: 20px;
                            line-height: 20px;
                        }
    
                        p {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
    
                .img {
                    width: 30%;
    
                    .after {
                        &:after {
                            width: 115px;
                        }
                        img.ceoImage {
                            width: 90%;
                        }
                    }
                
                    .slanding1 {
                        width: 90px;
                        height: 345px;
                        left: 50px;
                    }
                
                    .slanding2 {
                        width: 70px;
                        height: 275px;
                        left: 124px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
    .pageTitle.ceo {
    
        .container-fluid {
    
            .dots {
                left: 72%;
                top: -15px;
            }
    
            .data {
    
                .authorWrapper {
                    padding-left: 120px;
                    padding-top: 10px;
                    max-width: 70%;
    
                    .title {
                        h2 {
                            font-size: 25px;
                            line-height: 30px;
                        }
                    }
    
                    .quote {
                        gap: 15px;
                        margin: 40px 0 20px;

                        img {
                            width: 30px;
                        }
    
                        p {
                            font-size: 16px;
                            line-height: 26px;
                            font-weight: 500;
                        }
                    }
    
                    .content {
                        margin-bottom: 40px;
                        max-width: 90%;
    
                        p {
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }
    
                    .authorBox {
    
                        &:after {
                            width: 50%;
                            bottom: 30px;
                            right: 80px;
                            height: 1px;
                        }
    
                        h3 {
                            font-size: 20px;
                            line-height: 25px;
                        }
    
                        p {
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }
                }
    
                .img {
                    width: 30%;
    
                    .after {
                        &:after {
                            width: 110px;
                        }
                        img.ceoImage {
                            width: 90%;
                        }
                    }
                
                    .slanding1 {
                        width: 60px;
                        height: 260px;
                        left: 35px;
                    }
                
                    .slanding2 {
                        width: 60px;
                        height: 200px;
                        left: 81px;
                    }
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .pageTitle.ceo {
    
        .container-fluid {
    
            .dots {
                left: 10%;
                width: 280px;
                z-index: 99;
            }
    
            .data {
                flex-direction: column-reverse;

                .authorWrapper {
                    padding-left: 30px;
                    padding-top: 30px;
                    padding-right: 30px;
                    max-width: 100%;
    
                    .title {
                        h2 {
                            font-size: 36px;
                            line-height: 40px;
                        }
                    }
    
                    .quote {
                        gap: 25px;
                        margin: 40px 0 20px;

                        img {
                            width: 60px;
                        }
    
                        p {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
    
                    .content {
                        margin-bottom: 40px;
                        max-width: 100%;
    
                        p {
                            font-size: 18px;
                            line-height: 30px;
                        }
                    }
    
                    .authorBox {
    
                        &:after {
                            width: 70%;
                            bottom: 30px;
                            right: 0px;
                            height: 1px;
                        }
    
                        h3 {
                            font-size: 27px;
                            line-height: 28px;
                        }
    
                        p {
                            font-size: 18px;
                            line-height: 26px;
                        }
                    }
                }
    
                .img {
                    width: 100%;
                    justify-content: end;
                    max-width: 50%;
                    margin: 0 auto;
    
                    .after {
                        width: 100%;
                        margin: 0 auto;
                        position: relative;

                        &:after {
                            width: 150px;
                        }
                    }
                
                    .slanding1 {
                        width: 120px;
                        height: 410px;
                        left: 50px;
                    }
                
                    .slanding2 {
                        width: 95px;
                        height: 320px;
                        left: 148px;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .pageTitle.ceo {
        padding-bottom: 0;
    
        .container-fluid {
    
            .dots {
                left: 10%;
                width: 250px;
                z-index: 99;
            }
    
            .data {
                flex-direction: column-reverse;

                .authorWrapper {
                    padding-left: 30px;
                    padding-top: 30px;
                    padding-right: 30px;
                    max-width: 100%;
    
                    .title {
                        h2 {
                            font-size: 28px;
                            line-height: 36px;
                        }
                    }
    
                    .quote {
                        gap: 25px;
                        margin: 40px 0 20px;

                        img {
                            width: 60px;
                        }
    
                        p {
                            font-size: 18px;
                            line-height: 30px;
                        }
                    }
    
                    .content {
                        margin-bottom: 40px;
                        max-width: 100%;
    
                        p {
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
    
                    .authorBox {
    
                        &:after {
                            width: 70%;
                            bottom: 30px;
                            right: 0px;
                            height: 1px;
                        }
    
                        h3 {
                            font-size: 24px;
                            line-height: 30px;
                        }
    
                        p {
                            font-size: 17px;
                            line-height: 24px;
                        }
                    }
                }
    
                .img {
                    width: 100%;
                    justify-content: end;
                    max-width: 60%;
                    margin: 0 auto;
    
                    .after {
                        width: 100%;
                        margin: 0 auto;
                        position: relative;

                        &:after {
                            width: 150px;
                        }
                    }
                
                    .slanding1 {
                        width: 120px;
                        height: 410px;
                        left: 50px;
                    }
                
                    .slanding2 {
                        width: 95px;
                        height: 320px;
                        left: 148px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .pageTitle.ceo {
        padding-bottom: 0;
    
        .container-fluid {
    
            .dots {
                left: 5px;
                width: auto;
                z-index: 99;
                top: -45px;
            }
    
            .data {
                flex-direction: column-reverse;

                .authorWrapper {
                    padding-left: 30px;
                    padding-top: 30px;
                    padding-right: 30px;
                    max-width: 100%;
    
                    .title {
                        h2 {
                            font-size: 28px;
                            line-height: 36px;
                        }
                    }
    
                    .quote {
                        gap: 25px;
                        margin: 40px 0 20px;

                        img {
                            width: 60px;
                        }
    
                        p {
                            font-size: 18px;
                            line-height: 30px;
                        }
                    }
    
                    .content {
                        margin-bottom: 40px;
                        max-width: 100%;
    
                        p {
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
    
                    .authorBox {
    
                        &:after {
                            width: 70%;
                            bottom: 30px;
                            right: 0px;
                            height: 1px;
                        }
    
                        h3 {
                            font-size: 22px;
                            line-height: 30px;
                        }
    
                        p {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
    
                .img {
                    width: 100%;
                    justify-content: end;
                    max-width: 100%;
                    margin: 0 auto;
    
                    .after {
                        width: 100%;
                        margin: 0 auto;
                        position: relative;

                        &:after {
                            width: 150px;
                        }
                    }
                
                    .slanding1 {
                        width: 94px;
                        height: 520px;
                        left: 90px;
                    }
                
                    .slanding2 {
                        width: 80px;
                        height: 365px;
                        left: 148px;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .pageTitle.ceo {
        padding-bottom: 0;
    
        .container-fluid {
    
            .dots {
                left: unset;
                width: 170px;
                z-index: 0;
                top: -30px;
                right: 0;
                section {
                    line-height: 12px !important;
                    div {
                        margin: 0 5px !important;
                    }
                }
            }

            .data {
                flex-direction: column-reverse;
                padding-top: 0;

                .authorWrapper {
                    padding-left: 15px;
                    padding-top: 30px;
                    padding-right: 15px;
                    max-width: 100%;
    
                    .title {
                        h2 {
                            font-size: 28px;
                            line-height: 36px;
                            display: none;
                        }
                    }
    
                    .quote {
                        gap: 15px;
                        margin: 10px 0 20px;
                        flex-direction: column;
                        position: relative;

                        img {
                            width: 60px;
                            position: absolute;
                            opacity: 0.2;
                            left: -10px;
                            top: 0;
                        }
    
                        p {
                            font-size: 16px;
                            line-height: 26px;
                            z-index: 9;
                        }
                    }
    
                    .content {
                        margin-bottom: 20px;
                        max-width: 100%;
    
                        p {
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
    
                    .authorBox {
    
                        &:after {
                            width: 50%;
                            bottom: 30px;
                            right: 0px;
                            height: 1px;
                        }
    
                        h3 {
                            font-size: 20px;
                            line-height: 28px;
                        }
    
                        p {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
    
                .img {
                    width: 100%;
                    justify-content: center;
                    max-width: 100%;
                    margin: 0 auto;

                    .title {
                        display: block;
                        position: absolute;
                        top: 0px;
                        left: 10px;
                        z-index: 99;
                        max-width: 70%;

                        h2 {
                            font-size: 25px;
                            line-height: 32px;
                            font-weight: 700;
                            color: var(--black);
                            text-align: left;

                            br {
                                display: none;
                            }
                        }
                    }
    
                    .after {
                        width: 100%;
                        margin: 0 auto;
                        position: relative;
                        padding-top: 100px;

                        img {
                            &.ceoImage {
                                width: 250px;
                            }
                        }

                        &:after {
                            width: 75px;
                        }
                    }
                
                    .slanding1 {
                        width: 50px;
                        height: 200px;
                        left: unset;
                        right: 175px;
                    }
                
                    .slanding2 {
                        width: 40px;
                        height: 153px;
                        left: unset;
                        right: 146px;
                    }
                }
            }
        }
    }
}