footer {
  padding: 80px 0 0px;
  background: #F8F8F8;
}
footer .container {
  padding-bottom: 180px;
}
footer .container .rowData {
  display: flex;
  flex-direction: row;
  max-width: 80%;
  margin: 0 auto;
}
footer .container .rowData .contact {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-right: 60px;
}
footer .container .rowData .contact .dots {
  width: auto;
  margin-bottom: 60px;
}
footer .container .rowData .contact span {
  color: var(--black);
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 5px;
  width: 100%;
  display: block;
}
footer .container .rowData .contact h2 {
  color: var(--black);
  font-size: 50px;
  line-height: 52px;
  font-weight: 700;
  margin-bottom: 15px;
}
footer .container .rowData .contact h2 span {
  color: var(--primary);
  font-size: 50px;
  line-height: 52px;
}
footer .container .rowData .contact p {
  font-size: 30px;
  line-height: 40px;
  color: var(--black);
  font-weight: 500;
  margin-bottom: 40px;
}
footer .container .rowData .contact ul {
  padding: 0;
}
footer .container .rowData .contact ul li {
  display: flex;
  align-items: center;
  gap: 20px;
}
footer .container .rowData .contact ul li img {
  width: 20px;
  margin: 0;
}
footer .container .rowData .contact ul li a {
  color: var(--black);
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}
footer .container .rowData .contact ul li a:hover {
  color: var(--primary);
}
footer .container .rowData .form {
  width: 50%;
  display: flex;
  align-items: end;
  flex-direction: row;
}
footer .container .rowData .form .form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
footer .container .rowData .form .form-container .form-group {
  position: relative;
  margin-bottom: 35px !important;
}
footer .container .rowData .form .form-container .form-group .form-control {
  width: 100%;
  padding: 10px 0 5px;
  border: 0;
  border-bottom: 1px solid #707070;
  border-radius: 0px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  background-color: transparent;
  color: var(--primary);
  line-height: 30px;
  min-height: 45px;
}
footer .container .rowData .form .form-container .form-group .form-control:focus {
  border-color: var(--primary);
  outline: none;
  box-shadow: none;
}
footer .container .rowData .form .form-container .form-group .form-control:not(:-moz-placeholder-shown) + label {
  top: -15px;
  font-size: 16px;
  color: var(--black);
  border-radius: 0px;
  left: 0px;
}
footer .container .rowData .form .form-container .form-group .form-control:focus + label, footer .container .rowData .form .form-container .form-group .form-control:not(:placeholder-shown) + label {
  top: -15px;
  font-size: 16px;
  color: var(--black);
  border-radius: 0px;
  left: 0px;
}
footer .container .rowData .form .form-container .form-group .form-control#message {
  min-height: 100px;
}
footer .container .rowData .form .form-container .form-group label {
  position: absolute;
  left: 0px;
  top: 12px;
  font-size: 20px;
  color: #777;
  transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
}
footer .container .rowData .form .form-container .form-group p.error {
  color: var(--primary);
  font-size: 14px;
}
footer .container .rowData .form .form-container .submit-button {
  display: block;
  min-height: 60px;
  background-color: var(--primary);
  color: #ffffff;
  border: none;
  border-radius: 0px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  padding: 0 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer .container .rowData .form .form-container .submit-button:hover {
  background-color: var(--secondary);
}
footer .container-fluid {
  background-color: var(--black);
  padding: 30px 130px;
}
footer .container-fluid .rowData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
footer .container-fluid .rowData ul {
  display: flex;
  margin: 0;
  padding: 0;
}
footer .container-fluid .rowData ul.extraLinks {
  gap: 15px;
}
footer .container-fluid .rowData ul.extraLinks li {
  border-right: 1px solid #858585;
  padding-right: 15px;
}
footer .container-fluid .rowData ul.extraLinks li a {
  color: #858585;
}
footer .container-fluid .rowData ul.extraLinks li a:hover {
  color: var(--primary);
}
footer .container-fluid .rowData ul.extraLinks li:last-child {
  border-right: 0;
  padding-right: 0;
}
footer .container-fluid .rowData ul.socialLinks {
  gap: 20px;
}
footer .container-fluid .rowData ul.socialLinks li a {
  color: #858585;
}
footer .container-fluid .rowData ul.socialLinks li a:hover {
  color: var(--primary);
}
footer .container-fluid .rowData p {
  color: #858585;
  font-size: 20px;
  margin: 0;
}
footer .container-fluid .rowData p a {
  color: #858585;
}
footer .container-fluid .rowData p a:hover {
  color: var(--primary);
}

.scroll-to-top {
  position: fixed;
  bottom: 30px;
  left: 60px;
  background: rgba(255, 255, 255, 0.4784313725);
  color: var(--black);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  z-index: 999;
  border-radius: 5px;
  cursor: pointer;
}

@media (min-width: 1369px) and (max-width: 1600px) {
  footer {
    padding: 70px 0 0px;
  }
  footer .container {
    padding-bottom: 140px;
  }
  footer .container .rowData {
    max-width: 90%;
  }
  footer .container .rowData .contact {
    padding-right: 40px;
  }
  footer .container .rowData .contact img {
    width: 300px;
  }
  footer .container .rowData .contact span {
    font-size: 18px;
    line-height: 24px;
  }
  footer .container .rowData .contact h2 {
    font-size: 30px;
    line-height: 35px;
  }
  footer .container .rowData .contact h2 span {
    font-size: 30px;
    line-height: 35px;
  }
  footer .container .rowData .contact p {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 25px;
  }
  footer .container .rowData .contact ul li a {
    font-size: 20px;
    line-height: 32px;
  }
  footer .container .rowData .form {
    width: 60%;
  }
  footer .container .rowData .form .form-container .form-group .form-control {
    font-size: 16px;
    min-height: 50px;
    padding-bottom: 0;
    padding: 20px 0 5px;
  }
  footer .container .rowData .form .form-container .form-group .form-control#message {
    min-height: 90px;
  }
  footer .container .rowData .form .form-container .form-group .form-control:not(:-moz-placeholder-shown) + label {
    top: -10px;
    font-size: 14px;
  }
  footer .container .rowData .form .form-container .form-group .form-control:focus + label, footer .container .rowData .form .form-container .form-group .form-control:not(:placeholder-shown) + label {
    top: -10px;
    font-size: 14px;
  }
  footer .container .rowData .form .form-container .form-group label {
    top: 12px;
    font-size: 16px;
  }
  footer .container .rowData .form .form-container .submit-button {
    min-height: 50px;
    font-size: 16px;
    line-height: 30px;
  }
  footer .container-fluid {
    padding: 30px 100px;
  }
  footer .container-fluid .rowData ul.extraLinks {
    gap: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks li {
    padding-right: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks li a {
    font-size: 16px;
  }
  footer .container-fluid .rowData ul.socialLinks {
    gap: 10px;
  }
  footer .container-fluid .rowData p {
    font-size: 16px;
  }
  .scroll-to-top {
    bottom: 10px;
    left: 30px;
    width: 40px;
    height: 40px;
  }
  .scroll-to-top img {
    width: 10px;
  }
}
@media (min-width: 1201px) and (max-width: 1368px) {
  footer {
    padding: 60px 0 0px;
  }
  footer .container {
    padding-bottom: 60px;
  }
  footer .container .rowData {
    max-width: 90%;
  }
  footer .container .rowData .contact {
    padding-right: 40px;
  }
  footer .container .rowData .contact img {
    width: 250px;
  }
  footer .container .rowData .contact span {
    font-size: 16px;
    line-height: 26px;
  }
  footer .container .rowData .contact h2 {
    font-size: 25px;
    line-height: 30px;
  }
  footer .container .rowData .contact h2 span {
    font-size: 25px;
    line-height: 30px;
  }
  footer .container .rowData .contact p {
    font-size: 16px;
    line-height: 26px;
  }
  footer .container .rowData .contact ul li {
    margin-bottom: 5px;
    gap: 10px;
  }
  footer .container .rowData .contact ul li a {
    font-size: 18px;
    line-height: 26px;
  }
  footer .container .rowData .form {
    width: 60%;
  }
  footer .container .rowData .form .form-container .form-group {
    margin-bottom: 25px !important;
  }
  footer .container .rowData .form .form-container .form-group .form-control {
    font-size: 16px;
    min-height: 50px;
    padding: 20px 0 5px;
  }
  footer .container .rowData .form .form-container .form-group .form-control#message {
    min-height: 90px;
  }
  footer .container .rowData .form .form-container .form-group .form-control:not(:-moz-placeholder-shown) + label {
    top: -10px;
  }
  footer .container .rowData .form .form-container .form-group .form-control:focus + label, footer .container .rowData .form .form-container .form-group .form-control:not(:placeholder-shown) + label {
    top: -10px;
  }
  footer .container .rowData .form .form-container .form-group label {
    top: 20px;
    font-size: 16px;
  }
  footer .container .rowData .form .form-container .submit-button {
    min-height: auto;
    line-height: 30px;
    padding: 10px 24px;
    font-size: 14px;
    font-weight: 600;
  }
  footer .container-fluid {
    padding: 20px 20px 20px 90px;
  }
  footer .container-fluid .rowData ul.extraLinks {
    gap: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks li {
    padding-right: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks li a {
    font-size: 14px;
  }
  footer .container-fluid .rowData ul.socialLinks {
    gap: 15px;
  }
  footer .container-fluid .rowData p {
    font-size: 14px;
  }
  .scroll-to-top {
    bottom: 15px;
    left: 20px;
  }
  .scroll-to-top img {
    width: 12px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  footer {
    padding: 60px 0 0px;
  }
  footer .container {
    padding-bottom: 100px;
  }
  footer .container .rowData {
    max-width: 100%;
  }
  footer .container .rowData .contact {
    padding-right: 40px;
  }
  footer .container .rowData .contact img {
    width: 200px;
  }
  footer .container .rowData .contact span {
    font-size: 16px;
    line-height: 30px;
  }
  footer .container .rowData .contact h2 {
    font-size: 28px;
    line-height: 32px;
  }
  footer .container .rowData .contact h2 span {
    font-size: 28px;
    line-height: 32px;
  }
  footer .container .rowData .contact p {
    font-size: 18px;
    line-height: 24px;
  }
  footer .container .rowData .contact ul li {
    margin-bottom: 5px;
  }
  footer .container .rowData .contact ul li a {
    font-size: 18px;
    line-height: 24px;
  }
  footer .container .rowData .form {
    width: 80%;
  }
  footer .container .rowData .form .form-container .form-group .form-control {
    font-size: 18px;
    min-height: 50px;
    padding: 20px 0 5px;
  }
  footer .container .rowData .form .form-container .form-group .form-control#message {
    min-height: 90px;
  }
  footer .container .rowData .form .form-container .form-group label {
    top: 12px;
    font-size: 18px;
  }
  footer .container .rowData .form .form-container .submit-button {
    min-height: 50px;
    font-size: 18px;
    line-height: 24px;
  }
  footer .container-fluid {
    padding: 20px 10px;
  }
  footer .container-fluid .rowData {
    flex-direction: column;
    gap: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks {
    gap: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks li {
    padding-right: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks li a {
    font-size: 17px;
    line-height: 20px;
  }
  footer .container-fluid .rowData ul.socialLinks {
    gap: 10px;
  }
  footer .container-fluid .rowData p {
    font-size: 17px;
  }
  .scroll-to-top {
    bottom: 20px;
    left: 20px;
  }
  .scroll-to-top img {
    width: 12px;
  }
}
@media (max-width: 992px) {
  footer {
    padding: 50px 0 0px;
  }
  footer .container {
    padding-bottom: 50px;
  }
  footer .container .rowData {
    max-width: 100%;
    flex-direction: column;
  }
  footer .container .rowData .contact {
    padding-right: 40px;
    width: 100%;
  }
  footer .container .rowData .contact img {
    width: 180px;
    margin-bottom: 20px;
  }
  footer .container .rowData .contact span {
    font-size: 16px;
    line-height: 30px;
  }
  footer .container .rowData .contact h2 {
    font-size: 28px;
    line-height: 32px;
  }
  footer .container .rowData .contact h2 span {
    font-size: 28px;
    line-height: 32px;
  }
  footer .container .rowData .contact p {
    font-size: 18px;
    line-height: 24px;
  }
  footer .container .rowData .contact ul li {
    margin-bottom: 5px;
  }
  footer .container .rowData .contact ul li a {
    font-size: 18px;
    line-height: 24px;
  }
  footer .container .rowData .form {
    width: 100%;
    margin-top: 50px;
  }
  footer .container .rowData .form .form-container .form-group .form-control {
    font-size: 18px;
    min-height: 50px;
  }
  footer .container .rowData .form .form-container .form-group .form-control#message {
    min-height: 90px;
  }
  footer .container .rowData .form .form-container .form-group label {
    top: 12px;
    font-size: 18px;
  }
  footer .container .rowData .form .form-container .submit-button {
    min-height: 50px;
    font-size: 18px;
    line-height: 24px;
  }
  footer .container-fluid {
    padding: 20px 10px;
  }
  footer .container-fluid .rowData {
    flex-direction: column;
    gap: 5px;
  }
  footer .container-fluid .rowData ul.extraLinks {
    gap: 5px;
  }
  footer .container-fluid .rowData ul.extraLinks li {
    padding-right: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks li a {
    font-size: 16px;
    line-height: 20px;
  }
  footer .container-fluid .rowData ul.socialLinks {
    gap: 10px;
  }
  footer .container-fluid .rowData p {
    font-size: 16px;
    text-align: center;
  }
  .scroll-to-top {
    bottom: 10px;
    left: 10px;
  }
  .scroll-to-top img {
    width: 12px;
  }
}
@media (max-width: 767px) {
  footer {
    padding: 40px 0 0px;
  }
  footer .container {
    padding-bottom: 40px;
  }
  footer .container .rowData .contact {
    padding-right: 0px;
    width: 100%;
  }
  footer .container .rowData .contact span {
    font-size: 16px;
    line-height: 30px;
  }
  footer .container .rowData .contact h2 {
    font-size: 28px;
    line-height: 32px;
  }
  footer .container .rowData .contact h2 span {
    font-size: 28px;
    line-height: 32px;
  }
  footer .container .rowData .contact p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  footer .container .rowData .contact ul li a {
    font-size: 16px;
    line-height: 22px;
  }
  footer .container .rowData .form .form-container .form-group {
    margin-bottom: 20px;
  }
  footer .container .rowData .form .form-container .form-group .form-control {
    font-size: 16px;
    min-height: 18px;
    min-height: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  footer .container .rowData .form .form-container .form-group .form-control#message {
    min-height: 90px;
  }
  footer .container .rowData .form .form-container .form-group .form-control:not(:-moz-placeholder-shown) + label {
    font-size: 14px;
  }
  footer .container .rowData .form .form-container .form-group .form-control:focus + label, footer .container .rowData .form .form-container .form-group .form-control:not(:placeholder-shown) + label {
    font-size: 14px;
  }
  footer .container .rowData .form .form-container .form-group label {
    top: 12px;
    font-size: 14px;
  }
  footer .container .rowData .form .form-container .submit-button {
    min-height: 50px;
    font-size: 16px;
    line-height: 22px;
  }
  footer .container-fluid {
    padding: 20px 10px;
  }
  footer .container-fluid .rowData {
    flex-direction: column;
    gap: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks {
    gap: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks li {
    padding-right: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks li a {
    font-size: 14px;
    line-height: 20px;
  }
  footer .container-fluid .rowData ul.socialLinks {
    gap: 10px;
  }
  footer .container-fluid .rowData ul.socialLinks li a {
    color: var(--black);
    font-size: 20px;
    background: var(--white);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  footer .container-fluid .rowData p {
    font-size: 14px;
    text-align: center;
    line-height: 22px;
  }
  .scroll-to-top {
    bottom: 20px;
    left: 10px;
    width: 45px;
    height: 45px;
  }
  .scroll-to-top img {
    width: 10px;
  }
}
@media (max-width: 600px) {
  footer {
    padding: 40px 0 0px;
  }
  footer .container {
    padding-bottom: 40px;
  }
  footer .container .rowData .contact {
    padding-right: 0px;
    width: 100%;
  }
  footer .container .rowData .contact span {
    font-size: 16px;
    line-height: 30px;
  }
  footer .container .rowData .contact h2 {
    font-size: 25px;
    line-height: 32px;
    display: inline;
  }
  footer .container .rowData .contact h2 span {
    display: inline;
    font-size: 25px;
    line-height: 32px;
  }
  footer .container .rowData .contact p {
    font-size: 16px;
    line-height: 22px;
    margin: 15px 0 20px;
  }
  footer .container .rowData .contact ul li a {
    font-size: 16px;
    line-height: 22px;
  }
  footer .container .rowData .form .form-container .form-group {
    margin-bottom: 20px !important;
  }
  footer .container .rowData .form .form-container .form-group .form-control {
    font-size: 16px;
    min-height: 18px;
    min-height: 20px;
    padding-top: 20px;
    padding-bottom: 5px;
  }
  footer .container .rowData .form .form-container .form-group .form-control#message {
    min-height: 90px;
  }
  footer .container .rowData .form .form-container .form-group .form-control:not(:-moz-placeholder-shown) + label {
    font-size: 14px;
    top: -10px;
  }
  footer .container .rowData .form .form-container .form-group .form-control:focus + label, footer .container .rowData .form .form-container .form-group .form-control:not(:placeholder-shown) + label {
    font-size: 14px;
    top: -10px;
  }
  footer .container .rowData .form .form-container .form-group label {
    top: 20px;
    font-size: 14px;
  }
  footer .container .rowData .form .form-container .form-group label span {
    font-size: 10px;
  }
  footer .container .rowData .form .form-container .submit-button {
    min-height: 50px;
    font-size: 16px;
    line-height: 22px;
    width: 100%;
  }
  footer .container .rowData .form .form-container .submit-button:hover {
    background-color: var(--primary);
  }
  footer .container-fluid {
    padding: 20px 10px;
  }
  footer .container-fluid .rowData {
    flex-direction: column;
    gap: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks {
    gap: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks li {
    padding-right: 10px;
  }
  footer .container-fluid .rowData ul.extraLinks li a {
    font-size: 14px;
    line-height: 20px;
  }
  footer .container-fluid .rowData ul.socialLinks {
    gap: 10px;
  }
  footer .container-fluid .rowData p {
    font-size: 14px;
    text-align: center;
    line-height: 22px;
  }
  footer .container-fluid .rowData p span {
    display: block;
  }
  .scroll-to-top {
    bottom: 10px;
    left: 10px;
    background: rgb(255, 255, 255);
    border-radius: 0;
  }
}/*# sourceMappingURL=footer.css.map */