footer {
    padding: 80px 0 0px;
    background: #F8F8F8;

    .container {
        padding-bottom: 180px;

        .rowData {
            display: flex;
            flex-direction: row;
            max-width: 80%;
            margin: 0 auto;

            .contact {
                width: 50%;
                display: flex;
                flex-direction: column;
                padding-right: 60px;

                .dots {
                    width: auto;
                    margin-bottom: 60px;
                }

                span {
                    color: var(--black);
                    font-size: 24px;
                    line-height: 32px;
                    margin-bottom: 5px;
                    width: 100%;
                    display: block;
                }

                h2 {
                    color: var(--black);
                    font-size: 50px;
                    line-height: 52px;
                    font-weight: 700;
                    margin-bottom: 15px;

                    span {
                        color: var(--primary);
                        font-size: 50px;
                        line-height: 52px;
                    }
                }

                p {
                    font-size: 30px;
                    line-height: 40px;
                    color: var(--black);
                    font-weight: 500;
                    margin-bottom: 40px;
                }

                ul {
                    padding: 0;

                    li {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        img {
                            width: 20px;
                            margin: 0;
                        }

                        a {
                            color: var(--black);
                            font-size: 30px;
                            line-height: 40px;
                            font-weight: 500;

                            &:hover {
                                color: var(--primary);
                            }
                        }
                    }
                }
            }

            .form {
                width: 50%;
                display: flex;
                align-items: end;
                flex-direction: row;

                .form-container {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    width: 100%;

                    .form-group {
                        position: relative;
                        margin-bottom: 35px !important;

                        .form-control {
                            width: 100%;
                            padding: 10px 0 5px;
                            border: 0;
                            border-bottom: 1px solid #707070;
                            border-radius: 0px;
                            font-size: 16px;
                            transition: border-color 0.3s ease;
                            background-color: transparent;
                            color: var(--primary);
                            line-height: 30px;
                            min-height: 45px;

                            &:focus {
                                border-color: var(--primary);
                                outline: none;
                                box-shadow: none;
                            }

                            &:focus+label,
                            &:not(:placeholder-shown)+label {
                                top: -15px;
                                font-size: 16px;
                                color: var(--black);
                                border-radius: 0px;
                                left: 0px;
                            }

                            &#message {
                                min-height: 100px;
                            }
                        }

                        label {
                            position: absolute;
                            left: 0px;
                            top: 12px;
                            font-size: 20px;
                            color: #777;
                            transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
                        }

                        p.error {
                            color: var(--primary);
                            font-size: 14px;
                        }
                    }

                    .submit-button {
                        display: block;
                        min-height: 60px;
                        background-color: var(--primary);
                        color: #ffffff;
                        border: none;
                        border-radius: 0px;
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: 500;
                        padding: 0 30px;
                        cursor: pointer;
                        transition: background-color 0.3s ease;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            background-color: var(--secondary);
                        }
                    }
                }
            }
        }
    }

    .container-fluid {
        background-color: var(--black);
        padding: 30px 130px;

        .rowData {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            ul {
                display: flex;
                margin: 0;
                padding: 0;

                &.extraLinks {
                    gap: 15px;

                    li {
                        border-right: 1px solid #858585;
                        padding-right: 15px;

                        a {
                            color: #858585;

                            &:hover {
                                color: var(--primary);
                            }
                        }

                        &:last-child {
                            border-right: 0;
                            padding-right: 0;
                        }
                    }
                }

                &.socialLinks {
                    gap: 20px;

                    li {
                        a {
                            color: #858585;

                            &:hover {
                                color: var(--primary);
                            }
                        }
                    }
                }
            }

            p {
                color: #858585;
                font-size: 20px;
                margin: 0;

                a {
                    color: #858585;

                    &:hover {
                        color: var(--primary);
                    }
                }
            }
        }
    }
}

.scroll-to-top {
    position: fixed;
    bottom: 30px;
    left: 60px;
    background: #ffffff7a;
    color: var(--black);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    z-index: 999;
    border-radius: 5px;
    cursor: pointer;
}

// .fixed-button {
//     position: fixed;
//     bottom: 0;
//     right: 0;
//     z-index: 9;
//     width: 50px;
//     height: 50px;
//     border-radius: 100px;
//     background: #f00;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

@media (min-width: 1369px) and (max-width: 1600px) {
    footer {
        padding: 70px 0 0px;

        .container {
            padding-bottom: 140px;

            .rowData {
                max-width: 90%;

                .contact {
                    padding-right: 40px;

                    img {
                        width: 300px;
                    }

                    span {
                        font-size: 18px;
                        line-height: 24px;
                    }

                    h2 {
                        font-size: 30px;
                        line-height: 35px;

                        span {
                            font-size: 30px;
                            line-height: 35px;
                        }
                    }

                    p {
                        font-size: 20px;
                        line-height: 25px;
                        margin-bottom: 25px;
                    }

                    ul {

                        li {

                            a {
                                font-size: 20px;
                                line-height: 32px;
                            }
                        }
                    }
                }

                .form {
                    width: 60%;

                    .form-container {

                        .form-group {

                            .form-control {
                                font-size: 16px;
                                min-height: 50px;
                                padding-bottom: 0;
                                padding: 20px 0 5px;

                                &#message {
                                    min-height: 90px;
                                }

                                &:focus+label,
                                &:not(:placeholder-shown)+label {
                                    top: -10px;
                                    font-size: 14px;
                                }
                            }

                            label {
                                top: 12px;
                                font-size: 16px;
                            }
                        }

                        .submit-button {
                            min-height: 50px;
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }

        .container-fluid {
            padding: 30px 100px;

            .rowData {

                ul {

                    &.extraLinks {
                        gap: 10px;

                        li {
                            padding-right: 10px;

                            a {
                                font-size: 16px;
                            }
                        }
                    }

                    &.socialLinks {
                        gap: 10px;
                    }
                }

                p {
                    font-size: 16px;
                }
            }
        }
    }

    .scroll-to-top {
        bottom: 10px;
        left: 30px;
        width: 40px;
        height: 40px;

        img {
            width: 10px;
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
    footer {
        padding: 60px 0 0px;

        .container {
            padding-bottom: 60px;

            .rowData {
                max-width: 90%;

                .contact {
                    padding-right: 40px;

                    img {
                        width: 250px;
                    }

                    span {
                        font-size: 16px;
                        line-height: 26px;
                    }

                    h2 {
                        font-size: 25px;
                        line-height: 30px;

                        span {
                            font-size: 25px;
                            line-height: 30px;
                        }
                    }

                    p {
                        font-size: 16px;
                        line-height: 26px;
                    }

                    ul {

                        li {
                            margin-bottom: 5px;
                            gap: 10px;

                            a {
                                font-size: 18px;
                                line-height: 26px;
                            }
                        }
                    }
                }

                .form {
                    width: 60%;

                    .form-container {

                        .form-group {
                            margin-bottom: 25px !important;

                            .form-control {
                                font-size: 16px;
                                min-height: 50px;
                                padding: 20px 0 5px;

                                &#message {
                                    min-height: 90px;
                                }
                                
                                &:focus + label, &:not(:placeholder-shown) + label {
                                    top: -10px;
                                }
                            }

                            label {
                                top: 20px;
                                font-size: 16px;
                            }
                        }

                        .submit-button {
                            min-height: auto;
                            line-height: 30px;
                            padding: 10px 24px;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .container-fluid {
            padding: 20px 20px 20px 90px;

            .rowData {

                ul {

                    &.extraLinks {
                        gap: 10px;

                        li {
                            padding-right: 10px;

                            a {
                                font-size: 14px;
                            }
                        }
                    }

                    &.socialLinks {
                        gap: 15px;
                    }
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }

    .scroll-to-top {
        bottom: 15px;
        left: 20px;
        img {
            width: 12px;
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    footer {
        padding: 60px 0 0px;

        .container {
            padding-bottom: 100px;

            .rowData {
                max-width: 100%;

                .contact {
                    padding-right: 40px;

                    img {
                        width: 200px;
                    }

                    span {
                        font-size: 16px;
                        line-height: 30px;
                    }

                    h2 {
                        font-size: 28px;
                        line-height: 32px;

                        span {
                            font-size: 28px;
                            line-height: 32px;
                        }
                    }

                    p {
                        font-size: 18px;
                        line-height: 24px;
                    }

                    ul {

                        li {
                            margin-bottom: 5px;

                            a {
                                font-size: 18px;
                                line-height: 24px;
                            }
                        }
                    }
                }

                .form {
                    width: 80%;

                    .form-container {

                        .form-group {

                            .form-control {
                                font-size: 18px;
                                min-height: 50px;
                                padding: 20px 0 5px;

                                &#message {
                                    min-height: 90px;
                                }
                            }

                            label {
                                top: 12px;
                                font-size: 18px;
                            }
                        }

                        .submit-button {
                            min-height: 50px;
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }

        .container-fluid {
            padding: 20px 10px;

            .rowData {
                flex-direction: column;
                gap: 10px;

                ul {

                    &.extraLinks {
                        gap: 10px;

                        li {
                            padding-right: 10px;

                            a {
                                font-size: 17px;
                                line-height: 20px;
                            }
                        }
                    }

                    &.socialLinks {
                        gap: 10px;
                    }
                }

                p {
                    font-size: 17px;
                }
            }
        }
    }

    .scroll-to-top {
        bottom: 20px;
        left: 20px;
        img {
            width: 12px;
        }
    }
}

@media (max-width: 992px) {
    footer {
        padding: 50px 0 0px;

        .container {
            padding-bottom: 50px;

            .rowData {
                max-width: 100%;
                flex-direction: column;

                .contact {
                    padding-right: 40px;
                    width: 100%;

                    img {
                        width: 180px;
                        margin-bottom: 20px;
                    }

                    span {
                        font-size: 16px;
                        line-height: 30px;
                    }

                    h2 {
                        font-size: 28px;
                        line-height: 32px;

                        span {
                            font-size: 28px;
                            line-height: 32px;
                        }
                    }

                    p {
                        font-size: 18px;
                        line-height: 24px;
                    }

                    ul {

                        li {
                            margin-bottom: 5px;

                            a {
                                font-size: 18px;
                                line-height: 24px;
                            }
                        }
                    }
                }

                .form {
                    width: 100%;
                    margin-top: 50px;

                    .form-container {

                        .form-group {

                            .form-control {
                                font-size: 18px;
                                min-height: 50px;

                                &#message {
                                    min-height: 90px;
                                }
                            }

                            label {
                                top: 12px;
                                font-size: 18px;
                            }
                        }

                        .submit-button {
                            min-height: 50px;
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }

        .container-fluid {
            padding: 20px 10px;

            .rowData {
                flex-direction: column;
                gap: 5px;

                ul {

                    &.extraLinks {
                        gap: 5px;

                        li {
                            padding-right: 10px;

                            a {
                                font-size: 16px;
                                line-height: 20px;
                            }
                        }
                    }

                    &.socialLinks {
                        gap: 10px;
                    }
                }

                p {
                    font-size: 16px;
                    text-align: center;
                }
            }
        }
    }

    .scroll-to-top {
        bottom: 10px;
        left: 10px;
        img {
            width: 12px;
        }
    }
}

@media (max-width: 767px) {
    footer {
        padding: 40px 0 0px;

        .container {
            padding-bottom: 40px;

            .rowData {

                .contact {
                    padding-right: 0px;
                    width: 100%;

                    span {
                        font-size: 16px;
                        line-height: 30px;
                    }

                    h2 {
                        font-size: 28px;
                        line-height: 32px;

                        span {
                            font-size: 28px;
                            line-height: 32px;
                        }
                    }

                    p {
                        font-size: 16px;
                        line-height: 22px;
                        margin-bottom: 20px;
                    }

                    ul {

                        li {

                            a {
                                font-size: 16px;
                                line-height: 22px;
                            }
                        }
                    }
                }

                .form {

                    .form-container {

                        .form-group {
                            margin-bottom: 20px;

                            .form-control {
                                font-size: 16px;
                                min-height: 18px;
                                min-height: 20px;
                                padding-top: 5px;
                                padding-bottom: 5px;

                                &#message {
                                    min-height: 90px;
                                }

                                &:focus+label,
                                &:not(:placeholder-shown)+label {
                                    font-size: 14px;
                                }
                            }

                            label {
                                top: 12px;
                                font-size: 14px;
                            }
                        }

                        .submit-button {
                            min-height: 50px;
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }

        .container-fluid {
            padding: 20px 10px;

            .rowData {
                flex-direction: column;
                gap: 10px;

                ul {

                    &.extraLinks {
                        gap: 10px;

                        li {
                            padding-right: 10px;

                            a {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }

                    &.socialLinks {
                        gap: 10px;
                        li {
                            a {
                                color: var(--black);
                                font-size: 20px;
                                background: var(--white);
                                width: 30px;
                                height: 30px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 5px;
                            }
                        }
                    }
                }

                p {
                    font-size: 14px;
                    text-align: center;
                    line-height: 22px;
                }
            }
        }
    }

    .scroll-to-top {
        bottom: 20px;
        left: 10px;
        width: 45px;
        height: 45px;

        img {
            width: 10px;
        }
    }

}

@media (max-width: 600px) {
    footer {
        padding: 40px 0 0px;

        .container {
            padding-bottom: 40px;

            .rowData {

                .contact {
                    padding-right: 0px;
                    width: 100%;

                    span {
                        font-size: 16px;
                        line-height: 30px;
                    }

                    h2 {
                        font-size: 25px;
                        line-height: 32px;
                        display: inline;

                        span {
                            display: inline;
                            font-size: 25px;
                            line-height: 32px;
                        }
                    }

                    p {
                        font-size: 16px;
                        line-height: 22px;
                        margin: 15px 0 20px;
                    }

                    ul {

                        li {

                            a {
                                font-size: 16px;
                                line-height: 22px;
                            }
                        }
                    }
                }

                .form {

                    .form-container {

                        .form-group {
                            margin-bottom: 20px !important;

                            .form-control {
                                font-size: 16px;
                                min-height: 18px;
                                min-height: 20px;
                                padding-top: 20px;
                                padding-bottom: 5px;


                                &#message {
                                    min-height: 90px;
                                }

                                &:focus+label,
                                &:not(:placeholder-shown)+label {
                                    font-size: 14px;
                                    top: -10px;
                                }
                            }

                            label {
                                top: 20px;
                                font-size: 14px;
                                span {
                                    font-size: 10px;
                                }
                            }
                        }

                        .submit-button {
                            min-height: 50px;
                            font-size: 16px;
                            line-height: 22px;
                            width: 100%;

                            &:hover {
                                background-color: var(--primary);
                            }
                        }
                    }
                }
            }
        }

        .container-fluid {
            padding: 20px 10px;

            .rowData {
                flex-direction: column;
                gap: 10px;

                ul {

                    &.extraLinks {
                        gap: 10px;

                        li {
                            padding-right: 10px;

                            a {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }

                    &.socialLinks {
                        gap: 10px;
                    }
                }

                p {
                    font-size: 14px;
                    text-align: center;
                    line-height: 22px;

                    span {
                        display: block;
                    }
                }
            }
        }
    }

    .scroll-to-top {
        bottom: 10px;
        left: 10px;
        background: rgb(255 255 255);
        border-radius: 0;
    }
}