.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    display: none;
    justify-content: center;
    align-items: center;
    transition: .5s;

    &.show {
        display: flex;
        transition: .5s;

        .popup-content {
            width: 100%;
            height: 100%;
            padding: 50px 0;
            border-radius: 5px;
            position: relative;

            .close-btn {
                position: absolute;
                top: 45px;
                right: 140px;
                font-size: 100px;
                cursor: pointer;
                font-style: normal;
            }

            .container {
                height: 100%;
                .outerWrapper {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .logoWrapper {
                        img {
                            position: relative;
                            width: 200px;
                        }
                    }

                    .menuWrapper {
                        margin: 50px 0;
                        ul {
                            width: 100%;
                            padding: 0;
                            margin: 0;
                            display: flex;
                            li {
                                width: 25%;
                                display: inline-block;

                                &.menuTitle {
                                    span {
                                        color: var(--white);
                                        text-transform: uppercase;
                                        font-weight: 500;
                                        padding-bottom: 30px;
                                        display: block;
                                        border-bottom: 1px solid #9E9E9E;
                                        margin-bottom: 30px;
                                        a {
                                            color: var(--white);
                                            &:hover {
                                                color: var(--primary);
                                            }
                                        }
                                    }
                                }

                                &:first-child {
                                    width: 50%;
                                    ul {
                                        display: inline-block;
                                        margin: 0;
                                        padding: 0;
                                        li {
                                            display: inline-block;
                                            width: 50%;
                                        }
                                    }
                                }
                                ul {
                                    display: inline-block;
                                    margin: 0;
                                    padding: 0;
                                    li {
                                        display: inline-block;
                                        width: 100%;
                                        a {
                                            color: #cacaca;
                                            font-weight: 300;
                                            font-size: 16px;
                                            padding: 3px 0;
                                            display: block;
                                            &:hover {
                                                color: var(--primary);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .aboutWrapper {
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        p {
                            color: var(--white);
                            margin: 0;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }

                }
            }
        }
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {
    .popup {    
        &.show {    
            .popup-content {
                padding: 45px 0;    
                .close-btn {
                    top: 45px;
                    right: 70px;
                    font-size: 90px;
                }    
                .container {
                    .outerWrapper {    
                        .logoWrapper {
                            img {
                                width: 150px;
                            }
                        }    
                        .menuWrapper {
                            margin: 50px 0;
                            ul {
                                li {
                                    width: 25%;    
                                    &.menuTitle {
                                        span {
                                            padding-bottom: 20px;
                                            margin-bottom: 20px;
                                            font-size: 17px;
                                        }
                                    }    
                                    &:first-child {
                                        width: 50%;
                                        ul {
                                            li {
                                                width: 50%;
                                            }
                                        }
                                    }
                                    ul {
                                        li {
                                            a {
                                                font-size: 14px;
                                                padding: 2px 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }    
                        .aboutWrapper {
                            p {
                                font-size: 14px;
                                line-height: 20px;
                            }
                            img {
                                width: 110px;
                            }
                        }    
                    }
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
	.popup {    
        &.show {    
            .popup-content {
                padding: 20px 0;    
                .close-btn {
                    top: 40px;
                    right: 30px;
                    font-size: 80px;
                }    
                .container {
                    .outerWrapper {    
                        .logoWrapper {
                            img {
                                width: 150px;
                            }
                        }    
                        .menuWrapper {
                            margin: 50px 0;
                            ul {
                                li {
                                    width: 25%;    
                                    &.menuTitle {
                                        span {
                                            padding-bottom: 10px;
                                            margin-bottom: 10px;
                                            font-size: 15px;
                                        }
                                    }    
                                    &:first-child {
                                        width: 50%;
                                        ul {
                                            li {
                                                width: 50%;
                                            }
                                        }
                                    }
                                    ul {
                                        li {
                                            a {
                                                font-size: 14px;
                                                padding: 2px 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }    
                        .aboutWrapper {
                            p {
                                font-size: 14px;
                                line-height: 20px;
                            }
                            img {
                                width: 110px;
                            }
                        }    
                    }
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
	.popup {    
        &.show {    
            .popup-content {
                padding: 35px 0;    
                .close-btn {
                    top: 32px;
                    right: 20px;
                    font-size: 70px;
                }    
                .container {
                    .outerWrapper {    
                        .logoWrapper {
                            img {
                                width: 150px;
                            }
                        }    
                        .menuWrapper {
                            margin: 50px 0;
                            ul {
                                li {
                                    width: 25%;    
                                    &.menuTitle {
                                        span {
                                            padding-bottom: 15px;
                                            margin-bottom: 15px;
                                            font-size: 16px;
                                        }
                                    }    
                                    &:first-child {
                                        width: 50%;
                                        ul {
                                            li {
                                                width: 50%;
                                            }
                                        }
                                    }
                                    ul {
                                        li {
                                            a {
                                                font-size: 15px;
                                                padding: 2px 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .aboutWrapper {
                            p {
                                font-size: 14px;
                                line-height: 20px;
                            }
                            img {
                                width: 110px;
                            }
                        }    
                    }
                }
            }
        }
    }
}