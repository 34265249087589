.galleryLightbox {
  margin: 100px 0;
}
.galleryLightbox .my-masonry-grid {
  display: flex;
  margin-left: -20px;
  width: auto;
}
.galleryLightbox .my-masonry-grid .my-masonry-grid_column {
  padding-left: 20px;
  background-clip: padding-box;
}
.galleryLightbox .my-masonry-grid .my-masonry-grid_column .gallery-item {
  margin-bottom: 20px;
  cursor: pointer;
}
.galleryLightbox .my-masonry-grid .my-masonry-grid_column .gallery-item img {
  width: 100%;
  display: block;
  border-radius: 8px;
  transition: transform 0.3s;
}
.galleryLightbox .my-masonry-grid .my-masonry-grid_column .gallery-item img:hover {
  transform: scale(1.01);
}

.yarl__container {
  background-color: rgba(0, 0, 0, 0.8784313725);
}
.yarl__container .yarl__slide {
  padding: 100px;
}

.lightbox-slide img {
  width: 100%;
}

.lightbox-caption {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  padding: 10px;
  font-size: 16px;
}

@media (max-width: 992px) {
  .galleryLightbox {
    margin: 70px 0;
  }
  .galleryLightbox .my-masonry-grid {
    margin-left: -15px;
  }
  .galleryLightbox .my-masonry-grid .my-masonry-grid_column {
    padding-left: 15px;
    background-clip: padding-box;
  }
  .galleryLightbox .my-masonry-grid .my-masonry-grid_column .gallery-item {
    margin-bottom: 15px;
    cursor: pointer;
  }
  .galleryLightbox .my-masonry-grid .my-masonry-grid_column .gallery-item img:hover {
    transform: scale(0);
  }
  .yarl__container .yarl__slide {
    padding: 70px;
  }
}
@media (max-width: 767px) {
  .galleryLightbox {
    margin: 50px 0;
  }
  .galleryLightbox .my-masonry-grid {
    margin-left: -10px;
  }
  .galleryLightbox .my-masonry-grid .my-masonry-grid_column {
    padding-left: 10px;
    background-clip: padding-box;
  }
  .galleryLightbox .my-masonry-grid .my-masonry-grid_column .gallery-item {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .galleryLightbox .my-masonry-grid .my-masonry-grid_column .gallery-item img:hover {
    transform: scale(0);
  }
  .yarl__container .yarl__slide {
    padding: 65px;
  }
}
@media (max-width: 600px) {
  .galleryLightbox {
    margin: 50px 0;
  }
  .galleryLightbox .my-masonry-grid {
    margin-left: -10px;
  }
  .galleryLightbox .my-masonry-grid .my-masonry-grid_column {
    padding-left: 10px;
    background-clip: padding-box;
  }
  .galleryLightbox .my-masonry-grid .my-masonry-grid_column .gallery-item {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .galleryLightbox .my-masonry-grid .my-masonry-grid_column .gallery-item img:hover {
    transform: scale(0);
  }
  .yarl__container .yarl__slide {
    padding: 45px;
  }
}/*# sourceMappingURL=gallery.css.map */