.pageTitle.news.newsDetailsPage.policy {
    .container {
        .data {
            h1 {
                font-weight: 700;
                color: #000;
                margin-bottom: 30px;
                font-size: 55px;
            }
            h2, h3, h4, h5, h6 {
                color: var(--primary) !important;
                font-weight: 600 !important;
                span {
                    color: var(--black) !important;
                }
            }

            p {
                a {
                    color: var(--black);
                    transition: .5s;
                    &:hover {
                        color: var(--primary);
                    }
                }
            }

            ul {
                padding-left: 0;
                li {
                    position: relative;
                    padding-left: 25px;
                    margin-bottom: 10px;
                    &:before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        background-color: var(--primary);
                        position: absolute;
                        top: 10px;
                        left: 0;
                    }
                    a {
                        color: var(--black);
                        transition: .5s;
                        &:hover {
                            color: var(--primary);
                        }
                    }
                }
            }

            strong, b {

            }
        }
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {
	.pageTitle.news.newsDetailsPage.policy {
        .container {
            .data {
                h1 {
                    margin-bottom: 30px;
                    font-size: 50px;
                }
                ul {
                    li {
                        padding-left: 20px;
                        &:before {
                            width: 8px;
                            height: 8px;
                            top: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
	.pageTitle.news.newsDetailsPage.policy {
        .container {
            .data {
                h1 {
                    margin-bottom: 30px;
                    font-size: 45px;
                }
                ul {
                    li {
                        padding-left: 20px;
                        &:before {
                            width: 8px;
                            height: 8px;
                            top: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
	.pageTitle.news.newsDetailsPage.policy {
        .container {
            .data {
                h1 {
                    margin-bottom: 30px;
                    font-size: 40px;
                }
                ul {
                    li {
                        padding-left: 20px;
                        &:before {
                            width: 8px;
                            height: 8px;
                            top: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
	.pageTitle.news.newsDetailsPage.policy {
        .container {
            .data {
                h1 {
                    margin-bottom: 20px;
                    font-size: 35px;
                }

                p {
                    font-size: 18px;
                    line-height: 28px;
                }
                
                ul {
                    li {
                        padding-left: 20px;
                        &:before {
                            width: 8px;
                            height: 8px;
                            top: 10px;
                        }
                    }
                }
            }
        }
    }	
}

@media (max-width: 767px) {
	.pageTitle.news.newsDetailsPage.policy {
        .container {
            .data {
                padding-top: 0px;
                h1 {
                    margin-bottom: 20px;
                    font-size: 30px;
                }

                p {
                    font-size: 16px;
                    line-height: 28px;
                }
                
                ul {
                    li {
                        padding-left: 20px;
                        &:before {
                            width: 8px;
                            height: 8px;
                            top: 10px;
                        }
                    }
                }
            }
        }
    }	
}

@media (max-width: 600px) {
	.pageTitle.news.newsDetailsPage.policy {
        .container {
            .data {
                padding-top: 30px;
                h1 {
                    margin-bottom: 20px;
                    font-size: 30px;
                }

                p {
                    font-size: 16px;
                    line-height: 28px;
                }
                
                ul {
                    li {
                        padding-left: 20px;
                        &:before {
                            width: 8px;
                            height: 8px;
                            top: 10px;
                        }
                    }
                }
            }
        }
    }
}