@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&display=swap');

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --container-width-lg: 87%;
    --container-width-md: 90%;
    --container-width-sm: 95%;
    --primary: #ee2346;
    --secondary: #047fbb;
    --white: #ffffff;
    --black: #000000;
    --text: #808285;
    --grey: #2c2c2c;
    --exo: "Exo", sans-serif;
}

body {
    background: var(--white);
    font-family: var(--exo);
    font-style: italic;
    color: var(--text);
    font-size: 20px;
    line-height: 30px;
}

a {
    text-decoration: none;
    transition: .5s;

    &:hover {
        text-decoration: none;
    }
}

.small,
small {
    font-size: 20px;
    line-height: 30px;
    color: var(--text);
}

.text-red {
    color: var(--primary) !important;
}

.text-grey {
    color: var(--text);
}

.btn-loader {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    animation: spin 1s linear infinite;
    margin-right: 5px;
    margin-bottom: 0 !important;
    float: left;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.pageSubTitle {
    padding-top: 50px;
    padding-bottom: 50px;

    .container {
        .data {
            padding: 50px 160px 0px 400px;
            position: relative;

            #dot-1,
            #dot-2 {
                position: absolute;
                top: 60px;
                left: 100px;
                width: auto;
            }

            h2 {
                font-size: 40px;
                line-height: 50px;
                font-weight: 700;
                color: var(--black);
                margin-bottom: 30px;
                text-transform: capitalize;

                span {
                    color: var(--primary);
                }
            }

            p {
                font-size: 24px;
                line-height: 32px;
            }

            &.no-padding {
                padding-left: 0;
                padding-right: 0;

                p {
                    margin-bottom: 0;
                }

                small {
                    font-size: 20px;
                }
            }
        }
    }
}

.pageSubTitleWithBanner {
    padding-bottom: 50px;

    .container {
        margin-right: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .data {
            padding: 100px;
            position: relative;

            .dot-pattern-ten-six {
                position: absolute;
                top: 60px;
                left: 100px;
                width: auto;
            }

            p {
                font-size: 30px;
                line-height: 40px;
                font-weight: 400;
                color: var(--white);
                margin-bottom: 0;
            }

            h2 {
                font-size: 40px;
                line-height: 50px;
                font-weight: 600;
                color: var(--white);
                margin-bottom: 0;
                text-transform: capitalize;

                span {
                    color: var(--primary);
                }
            }
            a {
                background-color: var(--primary);
                color: var(--white);
                padding: 12px 30px;
                font-weight: 500;
                text-transform: uppercase;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(-0%, -50%);
            
                &:hover {
                    background-color: var(--secondary);
                }
            }
        }

        .dataDetails {
            p {
                margin: 0;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

}

.dot-pattern-ten-six {
    section {
        line-height: 25px;

        div {
            height: 9px;
            width: 9px;
            border-radius: 50%;
            transform: scale(0);
            background-color: red;
            animation: scaling 2.5s ease-in-out infinite;
            display: inline-block;
            margin: 0 7px;

            &:nth-child(0) {
                animation-delay: 0s;
            }

            &:nth-child(1) {
                animation-delay: 0.1s;
            }

            &:nth-child(2) {
                animation-delay: 0.2s;
            }

            &:nth-child(3) {
                animation-delay: 0.3s;
            }

            &:nth-child(4) {
                animation-delay: 0.4s;
            }

            &:nth-child(5) {
                animation-delay: 0.5s;
            }

            &:nth-child(6) {
                animation-delay: 0.6s;
            }

            &:nth-child(7) {
                animation-delay: 0.7s;
            }

            &:nth-child(8) {
                animation-delay: 0.8s;
            }

            &:nth-child(9) {
                animation-delay: 0.9s;
            }

            &:nth-child(10) {
                animation-delay: 1s;
            }

            &:nth-child(11) {
                animation-delay: 1.1s;
            }

            &:nth-child(12) {
                animation-delay: 1.2s;
            }

            &:nth-child(13) {
                animation-delay: 1.3s;
            }

            &:nth-child(14) {
                animation-delay: 1.4s;
            }

            &:nth-child(15) {
                animation-delay: 1.5s;
            }
        }
    }

    &.xl {
        section {
            line-height: 30px;

            div {
                height: 12px;
                width: 12px;
                margin: 0 10px;
            }
        }
    }

    &.lightdots {
        section {
            div {
                animation: scalingLight 2.5s ease-in-out infinite;
            }
        }
    }
}

@keyframes scaling {

    0%,
    100% {
        transform: scale(0.2);
        background-color: #ee2346;
    }

    40% {
        transform: scale(1);
        background-color: #dbe5ee;
    }

    50% {
        transform: scale(1);
        background-color: #047fbb;
    }
}

@keyframes scalingLight {

    0%,
    100% {
        transform: scale(0.2);
        background-color: #ee2346;
    }

    40% {
        transform: scale(1);
        background-color: #000000;
    }

    50% {
        transform: scale(1);
        background-color: #047fbb;
    }
}

@media (min-width: 1600px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 85%;
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 90%;
    }

    body {
        font-size: 18px;
        line-height: 28px;
    }

    .pageSubTitle {
        .container {
            .data {
                padding: 50px 100px 0px 400px;

                img {
                    top: 60px;
                    left: 100px;
                    width: 250px;
                }

                h2 {
                    font-size: 36px;
                    line-height: 42px;
                }

                p {
                    font-size: 20px;
                    line-height: 30px;
                }

                &.no-padding {
                    small {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }
        }
    }

    .pageSubTitleWithBanner {
        padding-bottom: 50px;

        .container {
            .data {
                padding: 90px;

                img {
                    top: 60px;
                    left: 100px;
                    width: 250px;
                }

                p {
                    font-size: 22px;
                    line-height: 32px;
                }

                h2, .type {
                    font-size: 36px;
                    line-height: 42px;
                }
            }

            .dataDetails {
                p {
                    font-size: 22px;
                    line-height: 30px;
                }
            }
        }
    }

    .dot-pattern-ten-six {
        section {
            line-height: 20px;

            div {
                height: 5px;
                width: 5px;
                margin: 0 7px;
            }
        }

        &.xl {
            section {
                line-height: 20px;

                div {
                    height: 8px;
                    width: 8px;
                    margin: 0 8px;
                }
            }
        }
    }

    .btn-loader {
        width: 20px;
        height: 20px;
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
    body, small, .small {
        font-size: 16px;
        line-height: 26px;
    }

    .pageSubTitle {
        .container {
            .data {
                padding: 50px 100px 0px 400px;

                img {
                    top: 60px;
                    left: 100px;
                    width: 250px;
                }

                h2 {
                    font-size: 25px;
                    line-height: 30px;
                }

                p {
                    font-size: 16px;
                    line-height: 26px;
                }

                &.no-padding {
                    small {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }
        }
    }

    .pageSubTitleWithBanner {
        padding-bottom: 20px;

        .container {
            .data {
                padding: 80px;

                img {
                    top: 60px;
                    left: 100px;
                    width: 250px;
                }                

                p {
                    font-size: 20px;
                    line-height: 28px;
                }

                h2, .type {
                    font-size: 25px;
                    line-height: 30px;
                }
            }

            .dataDetails {
                p {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
    }

    .dot-pattern-ten-six {
        section {
            line-height: 20px;

            div {
                height: 5px;
                width: 5px;
                margin: 0 7px;
            }
        }

        &.xl {
            section {
                line-height: 20px;

                div {
                    height: 8px;
                    width: 8px;
                    margin: 0 8px;
                }
            }
        }
    }

    .btn-loader {
        width: 20px;
        height: 20px;
    }
}

@media (min-width: 993px) and (max-width: 1200px) {

    body, p, small {
        font-size: 16px;
        line-height: 26px;
    }

    .pageSubTitle {
        .container {
            .data {
                padding: 50px 80px 0px 300px;

                #dot-1,
                #dot-2 {
                    top: 60px;
                    left: 45px;
                }

                h2 {
                    font-size: 28px;
                    line-height: 36px;
                }

                p {
                    font-size: 18px;
                    line-height: 28px;
                }

                &.no-padding {
                    small {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }
        }
    }

    .pageSubTitleWithBanner {
        padding-bottom: 0px;

        .container {
            .data {
                padding: 70px;

                img {
                    top: 60px;
                    left: 80px;
                    width: 180px;
                }

                p {
                    font-size: 20px;
                    line-height: 30px;
                }

                h2, .type {
                    font-size: 28px;
                    line-height: 36px;
                }
            }

            .dataDetails {
                p {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
    }

    .dot-pattern-ten-six {
        section {
            line-height: 20px;

            div {
                height: 5px;
                width: 5px;
                margin: 0 7px;
            }
        }

        &.xl {
            section {
                line-height: 20px;

                div {
                    height: 8px;
                    width: 8px;
                    margin: 0 6px;
                }
            }
        }
    }

    .btn-loader {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 992px) {

    body, p, small {
        font-size: 16px;
        line-height: 26px;
    }

    .pageSubTitle {
        padding-top: 30px;
        .container {
            .data {
                padding: 50px 0px 0px 215px;

                #dot-1,
                #dot-2 {
                    top: 60px;
                    left: 0;
                }

                .dots {
                    top: 60px;
                    left: 80px;
                    width: 180px;
                }

                h2 {
                    font-size: 24px;
                    line-height: 30px;
                }

                p {
                    font-size: 18px;
                    line-height: 28px;
                }

                &.no-padding {
                    small {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }
        }
    }

    .pageSubTitleWithBanner {
        padding-bottom: 0px;

        .container {
            .data {
                padding: 60px;

                .dots {
                    top: 60px;
                    left: 80px;
                    width: 180px;
                }

                p {
                    font-size: 18px;
                    line-height: 28px;
                }

                h2, .type {
                    font-size: 28px;
                    line-height: 36px;
                }
            }

            .dataDetails {
                p {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
    }

    .dot-pattern-ten-six {
        section {
            line-height: 20px;

            div {
                height: 5px;
                width: 5px;
                margin: 0 7px;
            }
        }

        &.xl {
            section {
                line-height: 20px;

                div {
                    height: 6px;
                    width: 6px;
                    margin: 0 6px;
                }
            }
        }
    }

    .btn-loader {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 767px) {

    body, p, small {
        font-size: 16px;
        line-height: 26px;
    }

    .pageSubTitle {
        .container {
            .data {
                padding: 50px 0px 0px 0px;

                #dot-1,
                #dot-2 {
                    display: none;
                }

                h2 {
                    font-size: 28px;
                    line-height: 36px;

                    br {
                        display: none;
                    }
                }

                p {
                    font-size: 17px;
                    line-height: 28px;
                }

                &.no-padding {
                    small {
                        font-size: 17px;
                        line-height: 28px;
                    }
                }
            }
        }
    }

    .pageSubTitleWithBanner {
        padding-bottom: 0px;

        .container {
            .data {
                padding: 50px;

                img {
                    top: 0px;
                    left: 0px;
                    width: 180px;
                }

                p {
                    font-size: 18px;
                    line-height: 28px;
                }

                h2, .type {
                    font-size: 28px;
                    line-height: 36px;

                    br {
                        display: none;
                    }
                }
                a {
                    position: relative;
                    top: 0%;
                    right: 0;
                    transform: unset;
                    display: inline-block;
                    width: auto;
                    margin-top: 10px;
                
                    &:hover {
                        background-color: var(--secondary);
                    }
                }
            }

            .dataDetails {
                p {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
    }
}

@media (max-width: 600px) {

    body, p, small {
        font-size: 16px;
        line-height: 26px;
    }

    .pageSubTitle {
        padding-top: 0;

        .container {
            .data {
                padding: 50px 0px 0px 0px;

                img {
                    display: none;
                }

                h2 {
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 600;

                    br {
                        display: none;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 26px;
                    margin-bottom: 0;
                }

                &.no-padding {
                    small {
                        font-size: 16px;
                        line-height: 28px;
                    }
                }
            }
        }
    }

    .pageSubTitleWithBanner {
        padding-bottom: 0px;

        .container {
            .data {
                padding: 40px 20px;

                img {
                    display: none;
                }

                h2 {
                    font-size: 25px;
                    line-height: 32px;
                }
            }

            .dataDetails {
                p {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
    }

    .dot-pattern-ten-six {
        section {
            line-height: 15px;

            div {
                height: 4px;
                width: 4px;
                margin: 0 4px;
            }
        }

        &.xl {
            section {
                line-height: 13px;

                div {
                    height: 4px;
                    width: 4px;
                    margin: 0 4px;
                }
            }
        }
    }
}

.grecaptcha-badge {
    visibility: hidden;
}















  
  .type {
    font-size: 40px;
   line-height: 50px;
   font-weight: 600;
   color: var(--white); 
   margin-bottom: 0;
   text-transform: capitalize;
    color:#0000;
    background:
      linear-gradient(-90deg,var(--white) 0px,#0000 0) 10px 0,
      linear-gradient(var(--white) 0 0) 0 0;
    background-size:calc(var(--n)*1ch) 200%;
    -webkit-background-clip:padding-box,text;
    background-clip:padding-box,text;
    background-repeat:no-repeat;
    animation: 
     b .7s infinite steps(1),   
     t calc(var(--n)*.2s) steps(var(--n)) 3s forwards;
  }
  
  @keyframes t{
    from {background-size:0 200%}
  }
  @keyframes b{
    50% {background-position:0 -100%,0 0}
  }