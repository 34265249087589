.clientsCarousel {
    background-color: var(--white);

    .container {
        .data {
            .alice-carousel {
                .alice-carousel__wrapper {
                    .alice-carousel__stage {
                        li.alice-carousel__stage-item {
                            .item {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 200px;
                                margin: 10px;
                                padding: 0 20px;

                                img {
                                    width: 200px;
                                    height: 200px;
                                    object-fit: scale-down;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {
	.clientsCarousel {
        .container {
            .data {
                .alice-carousel {
                    .alice-carousel__wrapper {
                        .alice-carousel__stage {
                            li.alice-carousel__stage-item {
                                .item {
                                    height: 180px;    
                                    img {
                                        width: 180px;
                                        height: 180px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
	.clientsCarousel {
        .container {
            .data {
                .alice-carousel {
                    .alice-carousel__wrapper {
                        .alice-carousel__stage {
                            li.alice-carousel__stage-item {
                                .item {
                                    height: 150px;    
                                    img {
                                        width: 150px;
                                        height: 150px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
	.clientsCarousel {
        .container {
            .data {
                .alice-carousel {
                    .alice-carousel__wrapper {
                        .alice-carousel__stage {
                            li.alice-carousel__stage-item {
                                .item {
                                    height: 150px;    
                                    img {
                                        width: 150px;
                                        height: 150px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
	.clientsCarousel {
        .container {
            .data {
                .alice-carousel {
                    .alice-carousel__wrapper {
                        .alice-carousel__stage {
                            li.alice-carousel__stage-item {
                                .item {
                                    height: 150px;    
                                    img {
                                        width: 150px;
                                        height: 150px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
	
}

@media (max-width: 600px) {
	.clientsCarousel {
        .container {
            .data {
                .alice-carousel {
                    .alice-carousel__wrapper {
                        .alice-carousel__stage {
                            li.alice-carousel__stage-item {
                                .item {
                                    height: 120px;    
                                    img {
                                        width: 120px;
                                        height: 120px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}