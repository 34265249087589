nav#site-nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background: var(--white);
    height: 105px;

    .navbar-brand {
        img {
            width: 250px;
            position: absolute;
            top: 100px;
            transition: .5s;
        }
    }

    .navbar-collapse {
        justify-content: end;

        ul.navbar-nav {
            padding-top: 5px;

            li {
                padding: 0 20px;

                a {
                    color: var(--black);
                    font-size: 20px;
                    font-family: var(--exoItalic);
                    text-transform: uppercase;
                    font-weight: 500;

                    &:hover {
                        color: var(--primary);
                    }
                }
            }
        }
    }

    &.fixedHeader {
        transition: .5s;
        box-shadow: 0px 0px 25px 0px #d5d5d5;

        .navbar-brand {
            transition: .5s;

            img {
                top: 10px;
                width: 135px;
                transition: .5s;
            }
        }
    }
}

.fixed-navbar-nav {
    position: absolute;
    left: 70px;
    transform: rotate(-90deg);
    z-index: 99;
    display: inline-block;
    margin: 0;
    padding: 0;
    width: auto;
    text-align: right;
    transform-origin: 0px 0;
    bottom: 100px;

    li {
        display: inline-block;
        padding: 0 15px;

        a {
            color: var(--black);
            font-size: 18px;
            font-weight: 500;

            &:hover {
                color: var(--primary);
            }
        }
    }
}

.harmburgerMenu {
    margin-left: 15px;

    button {
        background-color: transparent;
        border: 0;
        img {
            width: 20px;
        }
    }
}

.mobileMenuWrapper {
    display: none !important;
}

@media (min-width: 1369px) and (max-width: 1600px) {
    nav#site-nav {
        height: 90px;

        .navbar-brand {
            img {
                width: 200px;
                top: 80px;
                left: 110px;
            }
        }

        .navbar-collapse {
            ul.navbar-nav {
                li {
                    padding: 0 18px;

                    a {
                        font-size: 16px;
                    }
                }
            }
        }

        &.fixedHeader {
            .navbar-brand {
                img {
                    top: 10px;
                    width: 115px;
                    left: 100px;
                }
            }
        }
    }

    .fixed-navbar-nav {
        left: 40px;
        bottom: 100px;
        li {
            padding: 0 10px;
            a {
                font-size: 14px;
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
    nav#site-nav {
        height: 90px;

        .navbar-brand {
            img {
                width: 200px;
                top: 80px;
                left: 100px;
            }
        }

        .navbar-collapse {
            ul.navbar-nav {
                li {
                    padding: 0 10px;

                    a {
                        font-size: 14px;
                    }
                }
            }
        }

        &.fixedHeader {
            .navbar-brand {
                img {
                    top: 10px;
                    width: 115px;
                    left: 70px;
                }
            }
        }
    }

    .fixed-navbar-nav {
        left: 30px;
        bottom: 100px;

        li {
            padding: 0 10px;
            a {
                font-size: 14px;
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    nav#site-nav {
        height: 90px;

        .navbar-brand {
            img {
                width: 175px;
                top: 80px;
                left: 80px;
            }
        }

        .navbar-collapse {
            ul.navbar-nav {
                li {
                    padding: 0 10px;

                    a {
                        font-size: 16px;
                    }
                }
            }
        }

        &.fixedHeader {
            .navbar-brand {
                img {
                    top: 10px;
                    width: 135px;
                    left: 60px;
                }
            }
        }
    }

    .fixed-navbar-nav {
        left: 40px;

        li {
            padding: 0 10px;
            a {
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 992px) {
    nav#site-nav {
        height: 80px;
        // background-color: transparent;

        .navbar-brand {
            img {
                width: 160px;
                top: 80px;
                left: 50px;
            }
        }

        .navbar-collapse {
            display: none;

            ul.navbar-nav {
                li {
                    padding: 0 10px;

                    a {
                        font-size: 18px;
                    }
                }
            }
        }

        &.fixedHeader {

            // background-color: var(--#fff);
            .navbar-brand {
                img {
                    top: 10px;
                    width: 100px;
                    left: 50px;
                }
            }
        }
    }

    .fixed-navbar-nav {
        display: none;
    }

    .mobileMenuWrapper {
        display: block !important;
    }

    label#harm img {
        width: 25px;
    }
}

@media (max-width: 767px) {
    nav#site-nav {
        height: 75px;

        .navbar-brand {
            img {
                width: 160px;
                top: 80px;
                left: 50px;
            }
        }

        &.fixedHeader {
            .navbar-brand {
                img {
                    top: 10px;
                    width: 90px;
                    left: 20px;
                }
            }
        }
    }

    .fixed-navbar-nav {
        display: none;
    }
}

@media (max-width: 600px) {
    nav#site-nav {
        height: 75px;

        .navbar-brand {
            img {
                width: 100px;
                top: 30px;
                left: 15px;
            }
        }

        &.fixedHeader {
            .navbar-brand {
                img {
                    width: 100px;
                    top: 5px;
                    left: 10px;
                    // height: 65px;
                }
            }
        }
    }

    .fixed-navbar-nav {
        left: 0;
        bottom: 30vh;
        display: none;
    }
}














.mobileMenuWrapper {
    display: block;
    margin: 0 auto;
    overflow: visible;
    overflow: initial;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;

    nav {
        height: 50px;
        padding: 0;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        justify-content: end;
    }
    label.m-menu__toggle {
        svg {
            fill: #000;
            stroke: #000;
        }
    }

    nav .m-menu__checkbox {
        display: none;
    }

    nav label.m-menu__toggle {
        cursor: pointer;
        &#harm {
            padding-right: 15px;
            padding-top: 25px;
        }
    }

    nav .m-menu {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 450px;
        width: calc(100vw - 30px);
        height: 100vh;
        -moz-transform: translate3d(-450px, 0, 0);
        -o-transform: translate3d(-450px, 0, 0);
        -ms-transform: translate3d(-450px, 0, 0);
        -webkit-transform: translate3d(-450px, 0, 0);
        transform: translate3d(-450px, 0, 0);
        -moz-transition: transform 0.35s;
        -o-transition: transform 0.35s;
        -webkit-transition: transform 0.35s;
        transition: transform 0.35s;
        z-index: 99;
        overflow: hidden;
        background-color: #fff;
    }

    nav .m-menu__overlay {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
        bottom: 0;
        z-index: 1;
        display: none;
    }

    nav .m-menu__header {
        padding: 0 16px;
        height: 50px;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-around;
        justify-content: space-around;
        -ms-flex-pack: space-around;
        -webkit-align-items: center;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
    }

    nav .m-menu__header span {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        width: 100%;
        color: var(--black);
    }

    nav .m-menu .m-menu {
        -moz-transform: translate3d(480px, 0, 0);
        -o-transform: translate3d(480px, 0, 0);
        -ms-transform: translate3d(480px, 0, 0);
        -webkit-transform: translate3d(480px, 0, 0);
        transform: translate3d(480px, 0, 0);
    }

    nav .m-menu ul {
        height: 100%;
        overflow-y: auto;
        padding-left: 0;
    }

    nav .m-menu ul li a,
    nav .m-menu ul li label {
        display: block;
        text-align: left;
        padding: 0 15px;
        line-height: 47px;
        text-decoration: none;
        color: #333;
        cursor: pointer;
        font-size: 1rem;
        border-bottom: 1px solid #e8e8e8;
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 500;
    }

    nav .m-menu ul li label.a-label__chevron::after {
        content: "";
        position: absolute;
        display: inline-block;
        height: 10px;
        width: 10px;
        border-color: #333;
        border-style: solid;
        border-width: 1px 1px 0 0;
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 50%;
        margin-top: -5px;
        right: 16px;
    }

    nav .m-menu ul li .-invisible {
        border-bottom: 0;
    }

    nav .m-menu .m-menu label.m-menu__toggle {
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        border-bottom: 0;
        padding: 0;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    nav .m-menu__checkbox:checked~.m-menu__overlay {
        display: block;
    }

    nav .m-menu__checkbox:checked~.m-menu {
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .primaryy {
        padding-bottom: 130px;
    }

    .aboutWrapper {
        position: absolute;
        bottom: 0;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000;
        p {
            font-size: 12px;
            line-height: 16px;
            margin: 0;
            color: #fff;
        }
        img {
            width: 70px;
        }
    }

}