.careertabs {
    .scroll-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 18px;
        padding: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        transition: .5s;

        &:hover {
            background-color: var(--secondary);
            svg {
                color: var(--white);
            }
        }

        &.left {
            left: -50px;
        }

        &.right {
            right: -50px;
        }
    }

    .tabs-container {
        position: relative;
        flex-grow: 1;

        .nav-tabs {
            flex-wrap: nowrap;
            border-radius: 0;
            border: 0 !important;
            overflow-y: hidden;
            justify-content: start;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            &::-webkit-scrollbar {
                display: none;
              }

            .nav-link {
                border-radius: 0 !important;
                color: var(--black);
                display: inline-block;
                cursor: pointer;
                padding: 12px 30px;
                position: relative;
                border: 0 !important;

                &:after {
                    content: '';
                    height: 70%;
                    width: 1px;
                    background-color: var(--black);
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translate(0%, -50%);
                }

                &:hover {
                    color: var(--primary) !important;
                }

                &.custom-active-tab {
                    color: var(--white) !important;
                    background-color: var(--primary) !important;

                    &:after {
                        display: none;
                    }
                }
                &:has(+ .custom-active-tab) {
                    &:after {
                        display: none;
                    }
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    .tab-content {
        padding-top: 40px;
        .box {
            background-color: #eee;
            margin-bottom: 40px;
            padding: 40px;
            border-radius: 10px;
            h4 {
                color: var(--black);
                font-size: 30px;
                margin-bottom: 30px;
                font-weight: 600;
                text-transform: capitalize;
            }
            p {
                margin-bottom: 30px;
            }
            .excerpt {
                display: flex;
                justify-content: space-between;
                div {
                    width: 50%;
                    span {
                        padding: 0px;
                    }
                }
                div.link-whole {
                    width: 50%;
                    text-align: right;
                    display: flex;
                    align-items: end;
                    justify-content: end;
                    span {
                        background-color: var(--primary);
                        color: var(--white);
                        padding: 12px 30px;
                        font-weight: 500;
                        cursor: pointer;
                        transition:.5s;
                        display: inline-block;
                        &:hover {
                            background-color: var(--secondary);
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {
	.careertabs {
        .scroll-button {
            font-size: 16px;
            padding: 10px;
            &.left {
                left: -50px;
            }    
            &.right {
                right: -50px;
            }
        }    
        .tabs-container {    
            .nav-tabs {    
                .nav-link {
                    padding: 12px 28px;
                    font-size: 16px;
                }
            }
        }
        .tab-content {
            padding-top: 35px;
            .box {
                margin-bottom: 35px;
                padding: 35px;
                border-radius: 8px;
                h4 {
                    font-size: 27px;
                    margin-bottom: 27px;
                }
                p {
                    margin-bottom: 28px;
                }
                div {
                    span {
                        padding: 10px 28px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
	.careertabs {
        .scroll-button {
            font-size: 16px;
            padding: 10px;
            &.left {
                left: -50px;
            }    
            &.right {
                right: -50px;
            }
        }    
        .tabs-container {    
            .nav-tabs {    
                .nav-link {
                    padding: 10px 25px;
                    font-size: 16px;
                }
            }
        }
        .tab-content {
            padding-top: 35px;
            .box {
                margin-bottom: 35px;
                padding: 35px;
                border-radius: 8px;
                h4 {
                    font-size: 27px;
                    margin-bottom: 27px;
                }
                p {
                    margin-bottom: 28px;
                }
                div {
                    span {
                        padding: 10px 28px;
                    }
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
	.careertabs {
        .d-flex.align-items-center.position-relative {
            margin: 0 50px;
            .scroll-button {
                font-size: 16px;
                padding: 10px;
                &.left {
                    left: -50px;
                }    
                &.right {
                    right: -50px;
                }
            }    
            .tabs-container {    
                .nav-tabs {    
                    .nav-link {
                        padding: 10px 20px;
                        font-size: 16px;
                    }
                }
            }
        }
        .tab-content {
            padding-top: 30px;
            .box {
                margin-bottom: 30px;
                padding: 30px;
                border-radius: 8px;
                h4 {
                    font-size: 24px;
                    margin-bottom: 20px;
                }
                p {
                    margin-bottom: 28px;
                }
                div {
                    span {
                        padding: 10px 28px;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
	.careertabs {
        margin-bottom: 50px;
        .d-flex.align-items-center.position-relative {
            margin: 0 40px;
            .scroll-button {
                font-size: 16px;
                padding: 10px;
                &.left {
                    left: -40px;
                }
                &.right {
                    right: -40px;
                }
            }
            .tabs-container {    
                .nav-tabs {    
                    .nav-link {
                        padding: 8px 15px;
                        font-size: 15px;
                    }
                }
            }
        }
        .tab-content {
            padding-top: 25px;
            .box {
                margin-bottom: 30px;
                padding: 30px;
                border-radius: 8px;
                h4 {
                    font-size: 20px;
                    margin-bottom: 20px;
                }
                p {
                    margin-bottom: 20px;
                }
                .excerpt {
                    div {
                        &.link-whole {
                            span {
                                padding: 12px 20px;
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }
                    }
                }
            }
        }
    }	
}

@media (max-width: 767px) {
	.careertabs {
        .tab-content {
            padding-top: 20px;
            .box {
                margin-bottom: 25px;
                padding: 25px;
                border-radius: 5px;
                h4 {
                    font-size: 18px;
                }
                .excerpt {
                    flex-direction: column;
                    div {
                        width: 100%;
                    }
                    div.link-whole {
                        width: 100%;
                        text-align: left;
                        justify-content: start;
                        margin-top: 15px;
                        span {
                            &:hover {
                                background-color: var(--primary);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) { 
	.careertabs {
        .tab-content {
            padding-top: 10px;
            .box {
                margin-bottom: 20px;
                padding: 25px 20px 30px;
                border-radius: 5px;
                h4 {
                    font-size: 18px;
                    margin-bottom: 10px;
                }
                p {
                    margin-bottom: 15px;
                }
                div {
                    text-align: left !important;
                }
            }
        }
        .scroll-button {
            background-color: #eeeeee;
            &:hover {
                background-color: #eeeeee;
                svg {
                    color: var(--black);
                }
            }
        }
    }
}