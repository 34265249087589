.icon-box {
    padding: 30px 0;
    .container {
        .data {
            display: flex;
            .content {
                width: 60%;
                padding: 40px;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                h3 {
                    font-size: 30px;
                    font-style: italic;
                    font-weight: 700;
                    line-height: 53.16px;
                    color: var(--black);
                }
                p {

                }
                ul {
                    padding-left: 20px;
                    text-align: left;
                    li {
                        list-style-type: disc;
                    }
                }
            }
            .image {
                width: 40%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                img {
                    width: 100%;
                    height: 600px;
                    object-fit: cover;
                }
            }
        }
    }
    &.content-image {
        .data {
            flex-direction: row-reverse;
            .image {
                img {
                    height: 400px;
                }
            }
        }
    }
    &.image-content {
        &.imgheight {
            .data {
                .image {
                    img {
                        height: 400px;
                    }
                }
            }
        }
    }
}

.impactCarousel {
    margin-bottom: 80px;
    .container {
        .data {
            .alice-carousel {
                .alice-carousel__wrapper {
                    ul.alice-carousel__stage {
                        li.alice-carousel__stage-item {
                            .item {
                                text-align: center;
                                img {
                                    width: 1080px;
                                    height: 590px;
                                    object-fit: cover;
                                }
                                h4 {
                                    font-size: 24px;
                                    line-height: 32px;
                                    font-weight: 600;
                                    color: var(--black);
                                    margin: 30px 0 20px;
                                }
                                p {
                                    text-align: left;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
                ul.alice-carousel__dots {
                    li.alice-carousel__dots-item {
                        margin-right: 10px;
                        background-color: rgba(217, 217, 217, 1);
                        &.__active {
                            background-color: var(--primary);
                            width: 35px;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }
}

.counter {
    background-color: rgba(29, 37, 44, 1);
    padding: 100px;
    .container {
        .data {
            display: grid;
            grid-gap: 0px 5px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            padding: 0px;
            text-align: center;
            h5 {
                color: var(--white);
                font-size: 60px;
                line-height: 55px;
                font-weight: 700;
                padding: 0;
                margin-bottom: 20px;
                font-style: normal;
            }
            p {
                color: var(--white);
                margin-bottom: 0;
            }
        }
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {
	.icon-box {
        padding: 30px 0;
        .container {
            .data {
                .content {
                    width: 60%;
                    padding: 40px;
                    h3 {
                        font-size: 28px;
                        line-height: 36px;
                    }
                }
                .image {
                    width: 40%;
                    img {
                        width: 450px;
                        height: 450px;
                    }
                }
            }
        }
        &.content-image {
            .data {
                flex-direction: row-reverse;
            }
        }
        &.image-content.imgheight, &.content-image.imgheight {
            .data {
                .image {
                    img {
                        height: 400px;
                    }
                }
            }
        }
    }
    
    .impactCarousel {
        margin-bottom: 70px;
        .container {
            .data {
                .alice-carousel {
                    .alice-carousel__wrapper {
                        ul.alice-carousel__stage {
                            li.alice-carousel__stage-item {
                                .item {
                                    img {
                                        width: 960px;
                                        height: 525px;
                                    }
                                    h4 {
                                        font-size: 22px;
                                        line-height: 30px;
                                        margin: 30px 0 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .counter {
        padding: 80px;
        .container {
            .data {
                grid-gap: 0px 5px;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                h5 {
                    font-size: 50px;
                    line-height: 45px;
                    margin-bottom: 18px;
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
	.icon-box {
        padding: 30px 0;
        .container {
            .data {
                .content {
                    width: 60%;
                    padding: 40px;
                    h3 {
                        font-size: 28px;
                        line-height: 36px;
                    }
                }
                .image {
                    width: 40%;
                    img {
                        width: 400px;
                        height: 400px;
                    }
                }
            }
        }
        &.content-image {
            .data {
                flex-direction: row-reverse;
            }
        }
        &.image-content.imgheight, &.content-image.imgheight {
            .data {
                .image {
                    img {
                        height: 350px;
                    }
                }
            }
        }
    }
    
    .impactCarousel {
        margin-bottom: 60px;
        .container {
            .data {
                .alice-carousel {
                    .alice-carousel__wrapper {
                        ul.alice-carousel__stage {
                            li.alice-carousel__stage-item {
                                .item {
                                    img {
                                        width: 800px;
                                        height: 435px;
                                    }
                                    h4 {
                                        font-size: 22px;
                                        line-height: 30px;
                                        margin: 25px 0 10px;
                                    }
                                }
                            }
                        }
                    }
                    ul.alice-carousel__dots {
                        li.alice-carousel__dots-item {
                            &.__active {
                                width: 32px;
                            }
                        }
                    }
                }
            }
        }
    }

    .counter {
        padding: 70px;
        .container {
            .data {
                grid-gap: 0px 5px;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                h5 {
                    font-size: 40px;
                    line-height: 35px;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
	.icon-box {
        padding: 30px 0;
        .container {
            .data {
                .content {
                    width: 60%;
                    padding: 30px;
                    h3 {
                        font-size: 27px;
                        line-height: 32px;
                        font-weight: 600;
                    }
                    p {
                        br {
                            display: none;
                        }
                    }
                }
                .image {
                    width: 40%;
                    img {
                        width: 350px;
                        height: 350px;
                    }
                }
            }
        }
        &.content-image {
            .data {
                flex-direction: row-reverse;
            }
        }
        &.image-content.imgheight, &.content-image.imgheight {
            .data {
                .image {
                    img {
                        height: 350px;
                    }
                }
            }
        }
    }
        
    .impactCarousel {
        margin-bottom: 60px;
        .container {
            .data {
                .alice-carousel {
                    .alice-carousel__wrapper {
                        ul.alice-carousel__stage {
                            li.alice-carousel__stage-item {
                                .item {
                                    img {
                                        width: 600px;
                                        height: 330px;
                                    }
                                    h4 {
                                        font-size: 20px;
                                        line-height: 30px;
                                        font-weight: 600;
                                        margin: 20px 0 10px;
                                    }
                                }
                            }
                        }
                    }
                    ul.alice-carousel__dots {
                        li.alice-carousel__dots-item {
                            &.__active {
                                width: 32px;
                            }
                        }
                    }
                }
            }
        }
    }

    .counter {
        padding: 60px;
        .container {
            .data {
                grid-gap: 0px 5px;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                h5 {
                    font-size: 35px;
                    line-height: 30px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@media (max-width: 992px) {
	.icon-box {
        padding: 30px 0;
        .container {
            .data {
                .content {
                    width: 50%;
                    padding: 30px 10px;
                    h3 {
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: 600;
                    }
                    p {
                        br {
                            display: none;
                        }
                    }
                }
                .image {
                    width: 50%;
                    img {
                        width: 350px;
                        height: 350px;
                    }
                }
            }
        }
        &.content-image {
            .data {
                flex-direction: row-reverse;
            }
        }
        &.image-content.imgheight, &.content-image.imgheight {
            .data {
                .image {
                    img {
                        height: 100%;
                    }
                }
            }
        }
    }
        
    .impactCarousel {
        margin-bottom: 50px;
        .container {
            .data {
                .alice-carousel {
                    .alice-carousel__wrapper {
                        ul.alice-carousel__stage {
                            li.alice-carousel__stage-item {
                                .item {
                                    img {
                                        width: 600px;
                                        height: 330px;
                                    }
                                    h4 {
                                        font-size: 20px;
                                        line-height: 28px;
                                        font-weight: 600;
                                        margin: 20px 0 10px;
                                    }
                                }
                            }
                        }
                    }
                    ul.alice-carousel__dots {
                        li.alice-carousel__dots-item {
                            &.__active {
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

    .counter {
        padding: 60px 0;
        .container {
            .data {
                grid-gap: 50px 5px;
                grid-template-columns: 1fr 1fr;
                h5 {
                    font-size: 35px;
                    line-height: 30px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
	.icon-box {
        padding: 30px 0;
        .container {
            .data {
                flex-direction: column;
                .content {
                    width: 100%;
                    padding: 30px 10px;
                    text-align: left;
                    align-items: start;
                    .animate__fadeInUp {
                        width: 100%;
                    }
                    h3 {
                        font-size: 22px;
                        line-height: 32px;
                        text-align: center;
                        width: 100%;
                    }
                    p {
                        br {
                            display: none;
                        }
                    }
                }
                .image {
                    width: 100% !important;
                    padding: 0 !important;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        &.content-image {
            .data {
                flex-direction: column;
            }
        }
    }
        
    .impactCarousel {
        margin-bottom: 80px;
        .container {
            .data {
                .alice-carousel {
                    .alice-carousel__wrapper {
                        ul.alice-carousel__stage {
                            li.alice-carousel__stage-item {
                                .item {
                                    img {
                                        width: 500;
                                        height: 275px;
                                    }
                                    p {
                                        font-size: 17px;
                                        line-height: 28px;
                                    }
                                }
                            }
                        }
                    }
                    ul.alice-carousel__dots {
                        li.alice-carousel__dots-item {
                            &.__active {
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

    .counter {
        padding: 50px 0;
        .container {
            .data {
                grid-gap: 50px 5px;
                grid-template-columns: 1fr 1fr;
                h5 {
                    font-size: 25px;
                    line-height: 25px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@media (max-width: 600px) {
	.icon-box {
        padding: 20px 0;
        .container {
            .data {
                flex-direction: column;
                .content {
                    padding: 20px 0px 0;
                    h3 {
                        font-size: 20px;
                        line-height: 30px;
                        text-align: center;
                        width: 100%;
                    }
                }
                .image {
                    img {
                        width: 100% !important;
                        height: auto;
                    }
                }
            }
        }
        
        .impactCarousel {
            margin-bottom: 80px;
            .container {
                .data {
                    .alice-carousel {
                        .alice-carousel__wrapper {
                            ul.alice-carousel__stage {
                                li.alice-carousel__stage-item {
                                    .item {
                                        img {
                                            width: 100%;
                                        }
                                        h4 {
                                            font-size: 20px;
                                            line-height: 28px;
                                            margin: 20px 0 10px;
                                        }
                                        p {
                                            line-height: 28px;
                                        }
                                    }
                                }
                            }
                        }
                        ul.alice-carousel__dots {
                            margin-top: 10px;
                            li.alice-carousel__dots-item {
                                &.__active {
                                    width: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
        
    .impactCarousel {
        .container {
            .data {
                .alice-carousel {
                    .alice-carousel__wrapper {
                        ul.alice-carousel__stage {
                            li.alice-carousel__stage-item {
                                .item {
                                    img {
                                        width: 100%;
                                        height: 200px;
                                    }
                                }
                            }
                        }
                    }
                    ul.alice-carousel__dots {
                        li.alice-carousel__dots-item {
                            margin-right: 5px;
                            &.__active {
                                width: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}