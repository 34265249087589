.pageTitle.news {
    padding-top: 50px;
    padding-bottom: 120px;
    background-color: #F8F8F8;
    &.newsDetailsPage {
        margin-top: 105px;
        background-color: var(--white);
        padding-bottom: 60px;
        .container {
            .data {
                max-width: 80%;
                margin: 0 auto;
                padding-top: 0;

                div {
                    margin-bottom: 20px;
                    img {
                        width: auto;
                        max-width: 100%;
                    }
                }

                h2 {
                    margin-bottom: 20px;
                }
                
                p {
                    color: var(--text);
                    margin-bottom: 15px;
                    font-size: 20px;
                    line-height: 32px;

                    a {
                        color: var(--black);
                        transition: .5s;
                        &:hover {
                            color: var(--primary);
                        }
                    }
                }
            }
        }
    }
    .container {
        .data {
            padding: 50px 0px 0px 0px;
            position: relative;

            .dots {
                position: absolute;
                bottom: 0;
                right: 0;
                width: auto;
            }

            h2 {
                font-size: 40px;
                line-height: 50px;
                font-weight: 700;
                color: var(--black);

                span {
                    color: var(--primary);
                }
            }

            p {
                font-size: 22px;
                line-height: 36px;
                margin: 0;
                color: var(--black);
            }
        }

        .newsBoxes {
            margin: 50px 0 80px;
            display: grid;
            grid-gap: 40px 30px;
            grid-template-columns: 1fr 1fr 1fr;

            .item {
                position: relative;
                div {
                    overflow: hidden;
                    img {
                        width: 100%;
                        transition: .5s;
                        height: 320px;
                        object-fit: cover;
                    }
                }

                h5 {
                    width: auto;
                    font-size: 30px;
                    font-weight: 500;
                    color: var(--black);
                    margin: 20px 0 0;
                    max-width: 80%;
                    transition: .5s;

                    span {
                        text-transform: uppercase;
                        font-weight: 700;
                    }
                }

                .link-whole {
                    font-size: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
                &:hover {
                    img {
                        transform: scale(1.05) rotate(-1deg);
                    }
                    h5 {
                        color: var(--primary);
                    }
                }
            }
        }

        .allNews {
            text-align: center;
            a {
                background-color: var(--primary);
                padding: 12px 30px;
                color: var(--white);
                font-weight: 500;
                text-transform: uppercase;
                &:hover {
                    background-color: var(--secondary);
                }
            }
        }
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {
	.pageTitle.news { 
        padding-bottom: 90px;
        &.newsDetailsPage {
            margin-top: 105px;
            background-color: var(--white);
            padding-bottom: 60px;
            .container {
                .data {
                    max-width: 80%;
                    
                    p {
                        font-size: 20px;
                        line-height: 32px;
                    }
                }
            }
        }
        .container {
            .data {    
                img {
                    width: 300px;
                }    
                h2 {
                    font-size: 36px;
                    line-height: 45px;
                }    
                p {
                    font-size: 20px;
                    line-height: 32px;
                }
            }    
            .newsBoxes {
                margin: 40px 0 50px;
                grid-gap: 0px 30px;    
                .item {    
                    h5 {
                        margin: 20px 0;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
	.pageTitle.news {
        padding-bottom: 80px;
        &.newsDetailsPage {
            margin-top: 105px;
            background-color: var(--white);
            padding-bottom: 60px;
            .container {
                .data {
                    max-width: 80%;
                    
                    p {
                        font-size: 18px;
                        line-height: 32px;
                    }
                }
            }
        }  
        .container {
            .data {
                img {
                    width: 280px;
                }    
                h2 {
                    font-size: 25px;
                    line-height: 30px;
                }    
                p {
                    font-size: 16px;
                    line-height: 26px;
                }
            }    
            .newsBoxes {
                margin: 30px 0 50px;
                grid-gap: 0px 25px;    
                .item {  
                    div {
                        img {
                            height: 240px;
                        }
                    }  
                    h5 {
                        margin: 20px 0;
                        font-size: 16px;
                    }
                }
            }
            .allNews {
                a {
                    padding: 10px 24px;
                    font-size: 14px;
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
	.pageTitle.news {
        &.newsDetailsPage {
            margin-top: 80px;
            .container {
                .data {
                    max-width: 80%;
                    padding-top: 0;
                    
                    p {
                        font-size: 18px;
                        line-height: 32px;
                    }
                }
            }
        }
        .container {
            .data {
                img {
                    width: 250px;
                }    
                h2 {
                    font-size: 32px;
                    line-height: 42px;
                }    
                p {
                    font-size: 18px;
                    line-height: 32px;
                }
            }    
            .newsBoxes {
                margin: 30px 0 90px;
                grid-gap: 0px 30px;    
                .item {    
                    h5 {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
	.pageTitle.news {
        &.newsDetailsPage {
            margin-top: 80px;
            .container {
                .data {
                    max-width: 90%;
                    padding-top: 0;
                    
                    p {
                        font-size: 16px;
                        line-height: 28px;
                    }
                }
            }
        }
        .container {
            .data {
                img {
                    width: 220px;
                }    
                h2 {
                    font-size: 28px;
                    line-height: 36px;
                }    
                p {
                    font-size: 16px;
                    line-height: 28px;
                }
            }    
            .newsBoxes {
                margin: 30px 0 90px;
                grid-gap: 0px 20px;    
                .item {    
                    h5 {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
	.pageTitle.news {
        &.newsDetailsPage {
            margin-top: 75px;
            .container {
                .data {
                    max-width: 100%;
                    padding-top: 0;
                    
                    p {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }
        }
        .container {
            .data {
                img {
                    width: 220px;
                }    
                h2 {
                    font-size: 28px;
                    line-height: 36px;
                }    
                p {
                    font-size: 16px;
                    line-height: 26px;
                }
            }    
            .newsBoxes {
                margin: 30px 0 90px;
                grid-gap: 50px 20px;
                grid-template-columns: 1fr;
                .item {    
                    h5 {
                        margin-top: 15px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
	.pageTitle.news {
        padding-top: 0;
        padding-bottom: 60px;
        &.newsDetailsPage {
            margin-top: 75px;
            .container {
                .data {
                    max-width: 100%;
                    padding-top: 20px;
                }
            }
        }
        .container {
            .data {
                img {
                    width: 150px;
                }
                h2 {
                    font-size: 25px;
                    line-height: 32px;
                }
                .dots {
                    top: unset;
                }
            }  
            .newsBoxes {
                margin: 30px 0 60px;
            }
            .allNews {
                a {
                    font-size: 16px;
                }
            }
        }
    }
}