.pageSubTitle {

    &.thank-you {
        padding: 0 !important;

        div {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            img {
                width: 600px;
                margin-bottom: 10px;
            }

            h1 {
                color: var(--black);
                font-weight: 700;
                text-transform: uppercase;

                span {
                    color: var(--primary);
                }
            }

            p {
                margin: 20px 0 30px;
            }

            a {
                color: var(--text);

                &:hover {
                    color: var(--primary);
                }

                &.link {
                    background-color: var(--primary);
                    padding: 12px 30px;
                    color: var(--white);
                    font-weight: 500;
                    text-transform: uppercase;

                    &:hover {
                        background-color: var(--secondary);
                    }
                }
            }
        }
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {
    .pageSubTitle {
        &.thank-you {
            div {
                img {
                    width: 500px;
                }

                a {
                    &.link {
                        padding: 12px 30px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
    .pageSubTitle {
        &.thank-you {
            div {
                img {
                    width: 500px;
                }

                a {
                    &.link {
                        padding: 12px 30px;
                    }
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .pageSubTitle {
        &.thank-you {
            div {
                img {
                    width: 500px;
                }

                a {
                    &.link {
                        padding: 12px 30px;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .pageSubTitle {
        &.thank-you {
            div {
                img {
                    width: 400px;
                }

                a {
                    &.link {
                        padding: 12px 30px;
                    }
                }

                p {
                    br {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .pageSubTitle {
        &.thank-you {
            div {
                width: 90%;

                img {
                    width: 400px;
                }

                a {
                    &.link {
                        padding: 12px 30px;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .pageSubTitle {
        &.thank-you {
            div {
                img {
                    width: 100%;
                }
                h1 {
                    span {
                        display: block;
                    }
                }
            }
        }
    }
}