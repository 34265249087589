.joinBannerBg {
  background-color: #1D252C;
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative;
  margin-top: 80px;
}
.joinBannerBg .container {
  position: relative;
}
.joinBannerBg .container .data {
  padding-left: 425px;
}
.joinBannerBg .container .data h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  color: var(--white);
  margin: 5px 0 40px;
}
.joinBannerBg .container .data h2 span {
  color: var(--primary);
}
.joinBannerBg .container .data p {
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  color: var(--white);
}
.joinBannerBg .container .data a {
  background-color: var(--primary);
  padding: 12px 30px;
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
}
.joinBannerBg .container .data a:hover {
  background-color: var(--secondary);
}
.joinBannerBg .container .dots {
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
}
.joinBannerBg:after {
  content: "";
  position: absolute;
  left: -205px;
  top: 0;
  background: var(--white);
  width: 580px;
  height: 100%;
  z-index: 1;
  transform: skew(-30deg);
}
.joinBannerBg .slanding1 {
  width: 80px;
  height: 340px;
  background-color: transparent;
  position: absolute;
  bottom: 0px;
  z-index: 9;
  transform: skew(-30deg);
  overflow: hidden;
  left: 275px;
}
.joinBannerBg .slanding1 .animate__animated {
  height: 100%;
}
.joinBannerBg .slanding1 .animate__animated .inner {
  width: 100%;
  height: 100%;
  background: var(--primary);
  position: absolute;
  left: 0;
  z-index: 999;
  top: 0;
}
.joinBannerBg .slanding2 {
  width: 80px;
  height: 270px;
  background-color: transparent;
  position: absolute;
  bottom: 0px;
  z-index: 9;
  transform: skew(-30deg);
  overflow: hidden;
  left: 335px;
}
.joinBannerBg .slanding2 .animate__animated {
  height: 100%;
}
.joinBannerBg .slanding2 .animate__animated .inner {
  width: 100%;
  height: 100%;
  background: var(--secondary);
  position: absolute;
  left: 0;
  z-index: 999;
  top: 0;
}

.joinAbreco .container {
  position: relative;
}
.joinAbreco .container .data {
  display: flex;
  width: 100%;
  padding: 0 180px;
}
.joinAbreco .container .data .testimonialsWrapper {
  padding-top: 70px;
  padding-right: 40px;
  width: 100%;
  float: left;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.joinAbreco .container .data .testimonialsWrapper h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  color: var(--black);
  margin: 5px 0 20px;
}
.joinAbreco .container .data .testimonialsWrapper h2 span {
  color: var(--primary);
}
.joinAbreco .container .data .testimonialsWrapper p {
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  color: var(--black);
}
.joinAbreco .container .data .testimonialsWrapper .dots {
  position: absolute;
  top: 80px;
  right: 30px;
  width: auto;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li {
  padding: 0 5px;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item {
  position: relative;
  padding-bottom: 0px;
  display: flex;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata {
  width: 50%;
  padding-right: 15px;
  position: relative;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata small {
  color: #808285;
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata a {
  font-size: 14px;
  color: var(--secondary);
  margin-left: 5px;
  position: relative;
  z-index: 9;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata a:hover {
  color: var(--primary);
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata b {
  color: #F2F2F2;
  font-size: 120px;
  position: absolute;
  bottom: 0;
  right: 30px;
  line-height: 80px;
  display: ruby-text;
  font-weight: 900;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .timg {
  width: 50%;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .timg img {
  width: 100%;
  border-radius: 5px;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn {
  margin-right: 15px;
  margin-left: 15px;
  background-color: #ADADAE;
  width: 60px;
  height: 90px;
  transform: skew(-20deg);
  text-align: center;
  line-height: 90px;
  padding: 0;
  cursor: pointer;
  transition: 0.5s;
  position: absolute;
  bottom: 0;
  left: 0;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn img {
  width: 24px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 9;
  filter: brightness(0);
  transition: 0.5s;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn:after {
  content: "";
  background-color: var(--primary);
  width: 100%;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.5s;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn:hover:after {
  height: 100%;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn:hover img {
  filter: brightness(1);
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn {
  background-color: #ADADAE;
  width: 60px;
  height: 90px;
  transform: skew(-20deg);
  text-align: center;
  line-height: 90px;
  padding: 0;
  cursor: pointer;
  transition: 0.5s;
  position: absolute;
  bottom: 0;
  left: 90px;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn img {
  width: 24px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 9;
  filter: brightness(0);
  transition: 0.5s;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn:after {
  content: "";
  background-color: var(--primary);
  width: 100%;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.5s;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn:hover:after {
  height: 100%;
}
.joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn:hover img {
  filter: brightness(1);
}
.joinAbreco .container .data .testimonialsImage {
  width: 50%;
  float: left;
}
.joinAbreco .container .data .testimonialsImage img {
  margin-top: -160px;
  width: 100%;
}

@media (min-width: 1369px) and (max-width: 1600px) {
  .joinBannerBg {
    padding-top: 80px;
    padding-bottom: 120px;
    margin-top: 70px;
  }
  .joinBannerBg .container .data {
    padding-left: 380px;
  }
  .joinBannerBg .container .data h2 {
    font-size: 36px;
    line-height: 45px;
    margin: 5px 0 40px;
  }
  .joinBannerBg .container .data p {
    font-size: 22px;
    line-height: 30px;
  }
  .joinBannerBg .container .data a {
    padding: 12px 30px;
  }
  .joinBannerBg .container img {
    width: 350px;
  }
  .joinBannerBg:after {
    left: -296px;
    top: 0;
    width: 580px;
  }
  .joinBannerBg .slanding1 {
    width: 90px;
    height: 310px;
    bottom: 0px;
    left: 170px;
  }
  .joinBannerBg .slanding2 {
    width: 70px;
    height: 240px;
    bottom: 0px;
    left: 240px;
  }
  .joinAbreco .container .data {
    padding: 0 100px;
  }
  .joinAbreco .container .data .testimonialsWrapper {
    padding-right: 40px;
    width: 100%;
    padding-top: 80px;
  }
  .joinAbreco .container .data .testimonialsWrapper h2 {
    font-size: 36px;
    line-height: 45px;
    margin: 5px 0 20px;
  }
  .joinAbreco .container .data .testimonialsWrapper p {
    font-size: 22px;
    line-height: 30px;
  }
  .joinAbreco .container .data .testimonialsWrapper img {
    top: 30px;
    right: 30px;
    width: 200px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item {
    padding-bottom: 0px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata small {
    font-size: 18px;
    line-height: 26px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata a {
    font-size: 14px;
    margin-left: 5px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata b {
    font-size: 120px;
    right: 20px;
    line-height: 80px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn {
    width: 60px;
    height: 80px;
    line-height: 80px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn img {
    width: 24px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn {
    width: 60px;
    height: 80px;
    line-height: 80px;
    left: 90px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn img {
    width: 24px;
  }
  .joinAbreco .container .data .testimonialsImage {
    width: 50%;
  }
  .joinAbreco .container .data .testimonialsImage img {
    margin-top: -160px;
  }
}
@media (min-width: 1201px) and (max-width: 1368px) {
  .joinBannerBg {
    padding-top: 80px;
    padding-bottom: 90px;
    margin-top: 60px;
  }
  .joinBannerBg .container .data {
    padding-left: 360px;
  }
  .joinBannerBg .container .data h2 {
    font-size: 25px;
    line-height: 30px;
    margin: 5px 0 40px;
  }
  .joinBannerBg .container .data p {
    font-size: 16px;
    line-height: 26px;
  }
  .joinBannerBg .container .data a {
    padding: 12px 30px;
  }
  .joinBannerBg .container img {
    width: 350px;
  }
  .joinBannerBg:after {
    left: -330px;
    top: 0;
    width: 550px;
  }
  .joinBannerBg .slanding1 {
    width: 60px;
    height: 260px;
    bottom: 0px;
    left: 140px;
  }
  .joinBannerBg .slanding2 {
    width: 60px;
    height: 200px;
    bottom: 0px;
    left: 182px;
  }
  .joinAbreco .container .data {
    padding: 0 100px;
  }
  .joinAbreco .container .data .testimonialsWrapper {
    padding-right: 40px;
    width: 100%;
    padding-top: 80px;
  }
  .joinAbreco .container .data .testimonialsWrapper h2 {
    font-size: 25px;
    line-height: 30px;
    margin: 5px 0 20px;
  }
  .joinAbreco .container .data .testimonialsWrapper p {
    font-size: 16px;
    line-height: 26px;
  }
  .joinAbreco .container .data .testimonialsWrapper img {
    top: 30px;
    right: 30px;
    width: 200px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item {
    padding-bottom: 0px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata small {
    font-size: 16px;
    line-height: 24px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata a {
    font-size: 14px;
    margin-left: 5px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata b {
    font-size: 110px;
    right: 20px;
    line-height: 75px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn {
    width: 50px;
    height: 55px;
    line-height: 55px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn img {
    width: 20px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn {
    width: 50px;
    height: 55px;
    line-height: 55px;
    left: 75px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn img {
    width: 20px;
  }
  .joinAbreco .container .data .testimonialsImage {
    width: 50%;
  }
  .joinAbreco .container .data .testimonialsImage img {
    margin-top: -130px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .joinBannerBg {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-top: 50px;
  }
  .joinBannerBg .container .data {
    padding-left: 360px;
  }
  .joinBannerBg .container .data h2 {
    font-size: 32px;
    line-height: 36px;
    margin: 5px 0 30px;
  }
  .joinBannerBg .container .data p {
    font-size: 20px;
    line-height: 28px;
  }
  .joinBannerBg .container .data a {
    padding: 12px 30px;
    font-size: 16px;
  }
  .joinBannerBg .container .dots {
    top: -50px;
  }
  .joinBannerBg:after {
    left: -340px;
    top: 0;
    width: 580px;
  }
  .joinBannerBg .slanding1 {
    width: 100px;
    height: 290px;
    bottom: 0px;
    left: 130px;
  }
  .joinBannerBg .slanding2 {
    width: 80px;
    height: 245px;
    bottom: 0px;
    left: 217px;
  }
  .joinAbreco .container .data {
    padding: 0 50px;
  }
  .joinAbreco .container .data .testimonialsWrapper {
    padding-right: 20px;
    padding-top: 60px;
  }
  .joinAbreco .container .data .testimonialsWrapper h2 {
    font-size: 32px;
    line-height: 36px;
    margin: 5px 0 20px;
  }
  .joinAbreco .container .data .testimonialsWrapper p {
    font-size: 20px;
    line-height: 28px;
  }
  .joinAbreco .container .data .testimonialsWrapper img {
    top: 20px;
    right: 20px;
    width: 150px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item {
    padding-bottom: 0px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata small {
    font-size: 16px;
    line-height: 24px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata a {
    font-size: 14px;
    margin-left: 5px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata b {
    font-size: 100px;
    right: 20px;
    line-height: 70px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn {
    width: 50px;
    height: 60px;
    line-height: 60px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn img {
    width: 18px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn {
    width: 50px;
    height: 60px;
    line-height: 60px;
    left: 75px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn img {
    width: 18px;
  }
  .joinAbreco .container .data .testimonialsImage {
    width: 50%;
    padding-top: 20px;
  }
  .joinAbreco .container .data .testimonialsImage img {
    margin-top: 0px;
  }
}
@media (max-width: 992px) {
  .joinBannerBg {
    padding-top: 70px;
    padding-bottom: 70px;
    margin-top: 40px;
  }
  .joinBannerBg .container .data {
    padding-left: 310px;
  }
  .joinBannerBg .container .data h2 {
    font-size: 28px;
    line-height: 36px;
    margin: 5px 0 30px;
  }
  .joinBannerBg .container .data p {
    font-size: 18px;
    line-height: 24px;
  }
  .joinBannerBg .container .data a {
    padding: 12px 30px;
    font-size: 16px;
  }
  .joinBannerBg .container .dots {
    top: -50px;
  }
  .joinBannerBg:after {
    left: -365px;
    top: 0;
    width: 580px;
  }
  .joinBannerBg .slanding1 {
    width: 90px;
    height: 250px;
    bottom: 0px;
    left: 110px;
  }
  .joinBannerBg .slanding2 {
    width: 70px;
    height: 200px;
    bottom: 0px;
    left: 186px;
  }
  .joinAbreco .container .data {
    padding: 0 0px;
  }
  .joinAbreco .container .data .testimonialsWrapper {
    padding-right: 20px;
    padding-top: 50px;
  }
  .joinAbreco .container .data .testimonialsWrapper h2 {
    font-size: 28px;
    line-height: 36px;
    margin: 5px 0 20px;
  }
  .joinAbreco .container .data .testimonialsWrapper p {
    font-size: 18px;
    line-height: 24px;
  }
  .joinAbreco .container .data .testimonialsWrapper .dots {
    top: 10px;
    right: 10px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item {
    padding-bottom: 0px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata small {
    font-size: 16px;
    line-height: 24px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata a {
    font-size: 14px;
    margin-left: 5px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata b {
    font-size: 90px;
    right: 20px;
    line-height: 70px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn {
    width: 50px;
    height: 60px;
    line-height: 60px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn img {
    width: 18px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn {
    width: 50px;
    height: 60px;
    line-height: 60px;
    left: 75px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn img {
    width: 18px;
  }
  .joinAbreco .container .data .testimonialsImage {
    width: 50%;
    padding-top: 20px;
  }
  .joinAbreco .container .data .testimonialsImage img {
    margin-top: 0px;
  }
}
@media (max-width: 767px) {
  .joinBannerBg {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 30px;
  }
  .joinBannerBg .container .data {
    padding-left: 215px;
  }
  .joinBannerBg .container .data h2 {
    font-size: 28px;
    line-height: 36px;
    margin: 5px 0 20px;
  }
  .joinBannerBg .container .data p {
    font-size: 18px;
    line-height: 24px;
  }
  .joinBannerBg .container .data a {
    padding: 12px 30px;
    font-size: 16px;
  }
  .joinBannerBg .container .dots {
    display: none;
  }
  .joinBannerBg:after {
    left: -415px;
    top: 0;
    width: 580px;
  }
  .joinBannerBg .slanding1 {
    width: 75px;
    height: 220px;
    bottom: 0px;
    left: 75px;
  }
  .joinBannerBg .slanding2 {
    width: 60px;
    height: 160px;
    bottom: 0px;
    left: 133px;
  }
  .joinAbreco .container .data {
    padding: 0 0px;
    flex-direction: column;
  }
  .joinAbreco .container .data .testimonialsWrapper {
    padding-right: 0;
    width: 100%;
    padding-top: 50px;
  }
  .joinAbreco .container .data .testimonialsWrapper h2 {
    font-size: 28px;
    line-height: 36px;
    margin: 5px 0 20px;
  }
  .joinAbreco .container .data .testimonialsWrapper p {
    font-size: 18px;
    line-height: 24px;
  }
  .joinAbreco .container .data .testimonialsWrapper .dots {
    top: 20px;
    right: 0px;
    width: auto;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item {
    padding-bottom: 90px;
    flex-direction: column;
    gap: 20px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata {
    width: 100%;
    padding-right: 0px;
    position: unset;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata small {
    font-size: 16px;
    line-height: 24px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata a {
    font-size: 14px;
    margin-left: 5px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .tdata b {
    font-size: 100px;
    right: 20px;
    line-height: 70px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item .timg {
    width: 100%;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn {
    width: 50px;
    height: 60px;
    line-height: 60px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn img {
    width: 18px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn {
    width: 50px;
    height: 60px;
    line-height: 60px;
    left: 75px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn img {
    width: 18px;
  }
  .joinAbreco .container .data .testimonialsImage {
    width: 100%;
    padding-top: 40px;
  }
  .joinAbreco .container .data .testimonialsImage img {
    margin-top: 0px;
  }
}
@media (max-width: 600px) {
  .joinBannerBg {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 20px;
  }
  .joinBannerBg .container {
    z-index: 99;
  }
  .joinBannerBg .container .data {
    padding-left: 0px;
  }
  .joinBannerBg .container .data h2 {
    font-size: 25px;
    line-height: 32px;
    margin: 5px 0 20px;
  }
  .joinBannerBg .container .data p {
    font-size: 17px;
    line-height: 24px;
  }
  .joinBannerBg .container .data a {
    padding: 12px 30px;
    font-size: 16px;
  }
  .joinBannerBg .container img {
    width: 145px;
    top: -50px;
  }
  .joinBannerBg:after {
    left: -415px;
    top: 0;
    width: 580px;
    display: none;
  }
  .joinBannerBg .slanding1 {
    width: 75px;
    height: 225px;
    bottom: 0px;
    left: 75px;
    opacity: 0.3;
  }
  .joinBannerBg .slanding2 {
    width: 60px;
    height: 170px;
    bottom: 0px;
    left: 133px;
    opacity: 0.3;
  }
  .joinAbreco .container .data {
    padding: 0 0px;
    flex-direction: column;
  }
  .joinAbreco .container .data .testimonialsWrapper {
    padding-right: 0;
    width: 100%;
    padding-top: 50px;
  }
  .joinAbreco .container .data .testimonialsWrapper h2 {
    font-size: 25px;
    line-height: 32px;
    margin: 5px 0 20px;
  }
  .joinAbreco .container .data .testimonialsWrapper p {
    font-size: 17px;
    line-height: 24px;
  }
  .joinAbreco .container .data .testimonialsWrapper img {
    top: 40px;
    right: 0px;
    width: 120px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item {
    padding-bottom: 90px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item small {
    font-size: 16px;
    line-height: 24px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item a {
    font-size: 14px;
    margin-left: 5px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li .item b {
    font-size: 100px;
    right: 20px;
    line-height: 70px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn {
    width: 50px;
    height: 60px;
    line-height: 60px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__prev-btn img {
    width: 18px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn {
    width: 50px;
    height: 60px;
    line-height: 60px;
    left: 75px;
  }
  .joinAbreco .container .data .testimonialsWrapper .testimonials .alice-carousel .alice-carousel__next-btn img {
    width: 18px;
  }
  .joinAbreco .container .data .testimonialsImage {
    width: 100%;
    padding-top: 40px;
  }
  .joinAbreco .container .data .testimonialsImage img {
    margin-top: 0px;
  }
}/*# sourceMappingURL=join.css.map */