.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  display: none;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}
.popup.show {
  display: flex;
  transition: 0.5s;
}
.popup.show .popup-content {
  width: 100%;
  height: 100%;
  padding: 50px 0;
  border-radius: 5px;
  position: relative;
}
.popup.show .popup-content .close-btn {
  position: absolute;
  top: 45px;
  right: 140px;
  font-size: 100px;
  cursor: pointer;
  font-style: normal;
}
.popup.show .popup-content .container {
  height: 100%;
}
.popup.show .popup-content .container .outerWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.popup.show .popup-content .container .outerWrapper .logoWrapper img {
  position: relative;
  width: 200px;
}
.popup.show .popup-content .container .outerWrapper .menuWrapper {
  margin: 50px 0;
}
.popup.show .popup-content .container .outerWrapper .menuWrapper ul {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
}
.popup.show .popup-content .container .outerWrapper .menuWrapper ul li {
  width: 25%;
  display: inline-block;
}
.popup.show .popup-content .container .outerWrapper .menuWrapper ul li.menuTitle span {
  color: var(--white);
  text-transform: uppercase;
  font-weight: 500;
  padding-bottom: 30px;
  display: block;
  border-bottom: 1px solid #9E9E9E;
  margin-bottom: 30px;
}
.popup.show .popup-content .container .outerWrapper .menuWrapper ul li.menuTitle span a {
  color: var(--white);
}
.popup.show .popup-content .container .outerWrapper .menuWrapper ul li.menuTitle span a:hover {
  color: var(--primary);
}
.popup.show .popup-content .container .outerWrapper .menuWrapper ul li:first-child {
  width: 50%;
}
.popup.show .popup-content .container .outerWrapper .menuWrapper ul li:first-child ul {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.popup.show .popup-content .container .outerWrapper .menuWrapper ul li:first-child ul li {
  display: inline-block;
  width: 50%;
}
.popup.show .popup-content .container .outerWrapper .menuWrapper ul li ul {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.popup.show .popup-content .container .outerWrapper .menuWrapper ul li ul li {
  display: inline-block;
  width: 100%;
}
.popup.show .popup-content .container .outerWrapper .menuWrapper ul li ul li a {
  color: #cacaca;
  font-weight: 300;
  font-size: 16px;
  padding: 3px 0;
  display: block;
}
.popup.show .popup-content .container .outerWrapper .menuWrapper ul li ul li a:hover {
  color: var(--primary);
}
.popup.show .popup-content .container .outerWrapper .aboutWrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}
.popup.show .popup-content .container .outerWrapper .aboutWrapper p {
  color: var(--white);
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 1369px) and (max-width: 1600px) {
  .popup.show .popup-content {
    padding: 45px 0;
  }
  .popup.show .popup-content .close-btn {
    top: 45px;
    right: 70px;
    font-size: 90px;
  }
  .popup.show .popup-content .container .outerWrapper .logoWrapper img {
    width: 150px;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper {
    margin: 50px 0;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li {
    width: 25%;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li.menuTitle span {
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-size: 17px;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li:first-child {
    width: 50%;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li:first-child ul li {
    width: 50%;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li ul li a {
    font-size: 14px;
    padding: 2px 0;
  }
  .popup.show .popup-content .container .outerWrapper .aboutWrapper p {
    font-size: 14px;
    line-height: 20px;
  }
  .popup.show .popup-content .container .outerWrapper .aboutWrapper img {
    width: 110px;
  }
}
@media (min-width: 1201px) and (max-width: 1368px) {
  .popup.show .popup-content {
    padding: 20px 0;
  }
  .popup.show .popup-content .close-btn {
    top: 40px;
    right: 30px;
    font-size: 80px;
  }
  .popup.show .popup-content .container .outerWrapper .logoWrapper img {
    width: 150px;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper {
    margin: 50px 0;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li {
    width: 25%;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li.menuTitle span {
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 15px;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li:first-child {
    width: 50%;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li:first-child ul li {
    width: 50%;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li ul li a {
    font-size: 14px;
    padding: 2px 0;
  }
  .popup.show .popup-content .container .outerWrapper .aboutWrapper p {
    font-size: 14px;
    line-height: 20px;
  }
  .popup.show .popup-content .container .outerWrapper .aboutWrapper img {
    width: 110px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .popup.show .popup-content {
    padding: 35px 0;
  }
  .popup.show .popup-content .close-btn {
    top: 32px;
    right: 20px;
    font-size: 70px;
  }
  .popup.show .popup-content .container .outerWrapper .logoWrapper img {
    width: 150px;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper {
    margin: 50px 0;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li {
    width: 25%;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li.menuTitle span {
    padding-bottom: 15px;
    margin-bottom: 15px;
    font-size: 16px;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li:first-child {
    width: 50%;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li:first-child ul li {
    width: 50%;
  }
  .popup.show .popup-content .container .outerWrapper .menuWrapper ul li ul li a {
    font-size: 15px;
    padding: 2px 0;
  }
  .popup.show .popup-content .container .outerWrapper .aboutWrapper p {
    font-size: 14px;
    line-height: 20px;
  }
  .popup.show .popup-content .container .outerWrapper .aboutWrapper img {
    width: 110px;
  }
}/*# sourceMappingURL=harmburger.css.map */