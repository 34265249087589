.pageTitle {
    padding-top: 50px;
    padding-bottom: 50px;

    .container {
        .data {
            padding: 50px 0px 0px 0px;
            position: relative;

            .dots {
                position: absolute;
                bottom: 0;
                right: 0;
                width: auto;
            }

            h2 {
                font-size: 40px;
                line-height: 50px;
                font-weight: 700;
                color: var(--black);

                span {
                    color: var(--primary);
                }
            }

            p {
                font-size: 24px;
                line-height: 32px;
                margin: 0;
                color: var(--black);
            }
        }

        .locationsBoxes {
            margin: 50px 0 100px;
            display: grid;
            grid-gap: 60px 50px;
            grid-template-columns: 1fr 1fr 1fr;

            .item {
                position: relative;

                div {
                    overflow: hidden;

                    img {
                        width: 100%;
                        transition: .5s;
                        height: 320px;
                        object-fit: cover;
                    }
                }

                h5 {
                    width: auto;
                    position: absolute;
                    bottom: -20px;
                    right: 0;
                    background: #fff;
                    border-radius: 30px 0 0 0;
                    padding: 30px 50px 0px 30px;
                    text-align: right;
                    font-size: 30px;
                    font-weight: 700;
                    color: var(--black);
                    margin: 0;

                    svg {
                        color: var(--primary);
                        position: relative;
                        top: -5px;
                    }
                }

                a {
                    font-size: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                &:hover {
                    img {
                        transform: scale(1.05) rotate(-1deg);
                    }
                }
            }
        }
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {
    .pageTitle {
        .container {
            .data {
                img {
                    width: 300px;
                }

                h2 {
                    font-size: 36px;
                    line-height: 45px;
                }

                p {
                    font-size: 22px;
                    line-height: 30px;
                }
            }

            .locationsBoxes {
                margin: 40px 0 90px;
                grid-gap: 60px 45px;

                .item {
                    h5 {
                        bottom: -10px;
                        padding: 15px 40px 0px 20px;
                        font-size: 20px;

                        svg {
                            top: -5px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
    .pageTitle {
        .container {
            .data {
                img {
                    width: 280px;
                }

                h2 {
                    font-size: 25px;
                    line-height: 30px;
                }

                p {
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            .locationsBoxes {
                margin: 30px 0 40px;
                grid-gap: 60px 45px;

                .item {
                    div {    
                        img {
                            height: 220px;
                        }
                    }
                    h5 {
                        bottom: -10px;
                        padding: 15px 35px 0px 15px;
                        font-size: 18px;
                        border-radius: 20px 0 0 0;

                        svg {
                            top: -3px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .pageTitle {
        .container {
            .data {
                img {
                    width: 250px;
                }

                h2 {
                    font-size: 32px;
                    line-height: 42px;
                }

                p {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            .locationsBoxes {
                margin: 30px 0 90px;
                grid-gap: 40px 30px;

                .item {
                    div {    
                        img {
                            height: 210px;
                        }
                    }
                    h5 {
                        bottom: -20px;
                        padding: 20px 35px 0px 20px;
                        font-size: 22px;

                        svg {
                            top: -3px;
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: 992px) {
    .pageTitle {
        .container {
            .data {
                img {
                    width: 220px;
                }

                h2 {
                    font-size: 28px;
                    line-height: 36px;
                }

                p {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            .locationsBoxes {
                margin: 30px 0 90px;
                grid-gap: 30px 20px;
                grid-template-columns: 1fr 1fr;

                .item {
                    div {    
                        img {
                            height: 200px;
                        }
                    }
                    h5 {
                        bottom: -20px;
                        padding: 20px 35px 0px 20px;
                        font-size: 18px;

                        svg {
                            top: -3px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .pageTitle {
        .container {
            .data {
                img {
                    width: 220px;
                }

                h2 {
                    font-size: 28px;
                    line-height: 36px;
                }

                p {
                    font-size: 18px;
                    line-height: 24px;
                }

                .dots {
                    bottom: 50px;
                    right: -25px;
                }
            }

            .locationsBoxes {
                margin: 30px 0 90px;
                grid-gap: 50px 20px;
                grid-template-columns: 1fr;

                .item {
                    div {    
                        img {
                            height: 280px;
                        }
                    }
                    h5 {
                        bottom: -20px;
                        padding: 20px 35px 0px 20px;
                        font-size: 18px;

                        svg {
                            top: -3px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .pageTitle {
        padding-top: 60px;
        padding-bottom: 0;

        .container {
            .data {
                padding-top: 0;

                h2 {
                    font-size: 25px;
                    line-height: 32px;
                }

                p {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 5px;
                }

                small {
                    font-size: 16px;
                    line-height: 20px;

                    br {
                        display: none;
                    }
                }

                img {
                    width: 150px;
                    bottom: unset;
                    top: 0;
                }

                .dots {
                    bottom: unset;
                    right: 0;
                    top: -55px;
                }
            }

            .locationsBoxes {
                margin: 30px 0 90px;
                grid-gap: 50px 20px;
                grid-template-columns: 1fr;

                .item {
                    div {    
                        img {
                            height: 220px;
                        }
                    }
                    h5 {
                        bottom: -20px;
                        padding: 20px 35px 0px 20px;
                        font-size: 18px;

                        svg {
                            top: -3px;
                        }
                    }
                }
            }
        }
    }
}