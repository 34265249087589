.lifeAt {
    background-color: var(--black);
    padding: 70px 0;

    .container {
        .data {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            text-align: center;

            p {
                color: var(--white);
                margin: 0;
            }

            h2 {
                font-size: 40px;
                line-height: 50px;
                font-weight: 600;
                color: var(--white);
                margin: 0;

                span {
                    color: var(--primary);
                }
            }

            #videoWrapper {

                img, iframe, video {
                    margin: 40px 0;
                    width: 60%;
                }

            }

            a {
                background-color: var(--primary);
                padding: 12px 30px;
                color: var(--white);
                font-weight: 500;
                text-transform: uppercase;

                &:hover {
                    background-color: var(--secondary);
                }
            }
        }
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {
    .lifeAt {
        padding: 70px 0;

        .container {
            .data {
                h2 {
                    font-size: 36px;
                    line-height: 45px;
                }

                #videoWrapper {
    
                    img, iframe, video {
                        margin: 40px 0;
                        width: 80%;
                    }
    
                }

                a {
                    padding: 12px 30px;
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
    .lifeAt {
        padding: 60px 0;

        .container {
            .data {
                p {
                    font-size: 16px;
                    line-height: 26px;
                }
                h2 {
                    font-size: 25px;
                    line-height: 30px;
                }

                #videoWrapper {
    
                    img, iframe, video {
                        margin: 20px 0;
                        width: 60%;
                    }
    
                }
                a {
                    padding: 10px 24px;
                    font-size: 14px;
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .lifeAt {
        padding: 60px 0;

        .container {
            .data {
                h2 {
                    font-size: 32px;
                    line-height: 42px;
                }

                #videoWrapper {
    
                    img, iframe, video {
                        margin: 40px 0;
                        width: 90%;
                    }
    
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .lifeAt {
        padding: 50px 0;

        .container {
            .data {
                p {
                    font-size: 18px;
                    line-height: 24px;
                }

                h2 {
                    font-size: 28px;
                    line-height: 36px;
                }

                #videoWrapper {
    
                    img, iframe, video {
                        margin: 40px 0;
                        width: 100%;
                    }
    
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .lifeAt {
        padding: 50px 0;

        .container {
            .data {
                p {
                    font-size: 18px;
                    line-height: 24px;
                }

                h2 {
                    font-size: 28px;
                    line-height: 36px;
                }

                #videoWrapper {
    
                    img, iframe, video {
                        margin: 20px 0;
                    }
    
                }

                a {
                    padding: 10px 20px;
                    font-size: 16px;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .lifeAt {
        padding: 40px 0;

        .container {
            .data {
                p {
                    font-size: 17px;
                    line-height: 24px;
                }

                h2 {
                    font-size: 25px;
                    line-height: 32px;
                }
            }
        }
    }
}




















/* src/components/VideoPlayer.css */
.video-container {
    position: relative;
    width: 100%;
    height: 600px;
  }
  
  video {
    width: 100%;
    height: 100%;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .play-button {
    /* your existing styles */
    transition: opacity 0.5s ease;
  }
  
  .fade-out {
    opacity: 0;
  }
  