.brands {
  padding: 50px 0;
}
.brands .container .row .data {
  display: grid;
  grid-gap: 80px 25px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 0 150px;
}
.brands .container .row .data h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  color: var(--black);
}
.brands .container .row .data h2 span {
  color: var(--primary);
}
.brands .container .row .data p {
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  color: var(--black);
}
.brands .container .row .data .item {
  position: relative;
  border-radius: 20px;
}
.brands .container .row .data .item:first-child {
  grid-column-start: span 3;
  padding-left: 150px;
}
.brands .container .row .data .item .imgWrapper {
  overflow: hidden;
  border-radius: 20px;
}
.brands .container .row .data .item .imgWrapper img {
  width: 100%;
  height: 210px;
  -o-object-fit: cover;
     object-fit: cover;
}
.brands .container .row .data .item .imgWrapper:before {
  content: url(../../images/brandHover.webp);
  width: 0;
  height: 100%;
  background-color: var(--primary);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.5s;
  border-radius: 20px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.brands .container .row .data .item h5 {
  width: 90%;
  position: absolute;
  right: 0;
  background: #fff;
  border-radius: 20px 0 0 0;
  padding: 10px 20px 0px 20px;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  color: var(--black);
  margin: 0;
  top: 90%;
  transition: 0.5s;
}
.brands .container .row .data .item .link-whole {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  cursor: pointer;
}
.brands .container .row .data .item:hover .imgWrapper:before {
  width: 100%;
  opacity: 1;
}
.brands .container .row .data .item:hover h5 {
  color: var(--primary);
}

.pageSubTitle.brandDetails .container .data {
  padding-right: 0;
}
.pageSubTitle.brandDetails .container .data h2 {
  font-size: 30px;
  line-height: 45px;
  font-weight: 400;
}
.pageSubTitle.brandData div {
  margin-bottom: 150px;
}
.pageSubTitle.brandData div h2 {
  font-weight: 700;
  color: var(--black);
  font-size: 40px;
  margin-bottom: 60px;
  max-width: 50%;
  text-transform: uppercase;
}
.pageSubTitle.brandData div img {
  float: right;
  width: 300px;
  height: 300px;
  margin: 0 0 30px 50px;
  -o-object-fit: contain;
     object-fit: contain;
  max-width: 300px;
  background: #f8f8f8;
  border-radius: 10px;
  padding: 30px;
}
.pageSubTitle.brandData div.text-center {
  margin: 50px 0 30px;
}
.pageSubTitle.brandData div.text-center a {
  background-color: var(--primary);
  color: var(--white);
  padding: 12px 30px;
  font-weight: 500;
  text-transform: uppercase;
}
.pageSubTitle.brandData div.text-center a:hover {
  background-color: var(--secondary);
}
.pageSubTitle.brandData div:last-child {
  margin-bottom: 100px;
}

@media (min-width: 1369px) and (max-width: 1600px) {
  .brands {
    padding: 50px 0;
  }
  .brands .container .row .data {
    grid-gap: 70px 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0 60px;
  }
  .brands .container .row .data h2 {
    font-size: 36px;
    line-height: 45px;
  }
  .brands .container .row .data p {
    font-size: 22px;
    line-height: 30px;
  }
  .brands .container .row .data .item:first-child {
    padding-left: 70px;
  }
  .brands .container .row .data .item .imgWrapper img {
    height: 170px;
  }
  .brands .container .row .data .item h5 {
    width: 90%;
    font-size: 16px;
    padding: 10px 12px 0px 12px;
  }
  .pageSubTitle.brandDetails .container .data h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .pageSubTitle.brandData div {
    margin-bottom: 125px;
  }
  .pageSubTitle.brandData div h2 {
    font-size: 36px;
    margin-bottom: 55px;
    max-width: 50%;
  }
  .pageSubTitle.brandData div img {
    width: 280px;
    height: 280px;
    margin: 0 0 20px 30px;
  }
  .pageSubTitle.brandData div.text-center {
    margin: 50px 0 30px;
  }
  .pageSubTitle.brandData div.text-center a {
    padding: 12px 30px;
  }
  .pageSubTitle.brandData div:last-child {
    margin-bottom: 50px;
  }
}
@media (min-width: 1201px) and (max-width: 1368px) {
  .brands {
    padding: 50px 0;
  }
  .brands .container .row .data {
    grid-gap: 50px 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 0 70px;
  }
  .brands .container .row .data h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .brands .container .row .data p {
    font-size: 16px;
    line-height: 26px;
  }
  .brands .container .row .data .item:first-child {
    grid-column-start: span 3;
    padding-left: 70px;
  }
  .brands .container .row .data .item .imgWrapper img {
    height: 170px;
  }
  .brands .container .row .data .item h5 {
    width: 90%;
    font-size: 14px;
  }
  .pageSubTitle.brandDetails .container .data h2 {
    font-size: 26px;
    line-height: 36px;
  }
  .pageSubTitle.brandData div {
    margin-bottom: 100px;
  }
  .pageSubTitle.brandData div h2 {
    font-size: 32px;
    margin-bottom: 42px;
    max-width: 70%;
  }
  .pageSubTitle.brandData div img {
    width: 250px;
    height: 250px;
    margin: 0 0 20px 40px;
  }
  .pageSubTitle.brandData div.text-center {
    margin: 50px 0 30px;
  }
  .pageSubTitle.brandData div.text-center a {
    padding: 12px 30px;
  }
  .pageSubTitle.brandData div:last-child {
    margin-bottom: 30px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .brands {
    padding: 50px 0;
  }
  .brands .container .row .data {
    grid-gap: 80px 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 0 60px;
  }
  .brands .container .row .data h2 {
    font-size: 32px;
    line-height: 42px;
  }
  .brands .container .row .data p {
    font-size: 20px;
    line-height: 28px;
  }
  .brands .container .row .data .item:first-child {
    grid-column-start: span 3;
    padding-left: 50px;
  }
  .brands .container .row .data .item .imgWrapper {
    border-radius: 10px;
  }
  .brands .container .row .data .item .imgWrapper::before {
    border-radius: 10px;
  }
  .brands .container .row .data .item .imgWrapper img {
    height: 170px;
    border-radius: 10px;
  }
  .brands .container .row .data .item h5 {
    width: 90%;
    font-size: 16px;
  }
  .pageSubTitle.brandDetails .container .data h2 {
    font-size: 24px;
    line-height: 34px;
  }
  .pageSubTitle.brandData div {
    margin-bottom: 100px;
  }
  .pageSubTitle.brandData div h2 {
    font-size: 28px;
    margin-bottom: 38px;
    max-width: 70%;
  }
  .pageSubTitle.brandData div img {
    width: 200px;
    height: 200px;
    margin: 0 0 20px 20px;
    padding: 20px;
  }
  .pageSubTitle.brandData div.text-center {
    margin: 50px 0 30px;
  }
  .pageSubTitle.brandData div.text-center a {
    padding: 12px 30px;
  }
  .pageSubTitle.brandData div:last-child {
    margin-bottom: 30px;
  }
}
@media (max-width: 992px) {
  .brands {
    padding: 50px 0;
  }
  .brands .container .row .data {
    grid-gap: 80px 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 40px;
  }
  .brands .container .row .data h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .brands .container .row .data p {
    font-size: 18px;
    line-height: 24px;
  }
  .brands .container .row .data small {
    font-size: 16px;
    line-height: 20px;
  }
  .brands .container .row .data .item:first-child {
    grid-column-start: span 4;
    padding-left: 0px;
  }
  .brands .container .row .data .item .imgWrapper {
    border-radius: 10px;
  }
  .brands .container .row .data .item .imgWrapper::before {
    border-radius: 10px;
  }
  .brands .container .row .data .item .imgWrapper img {
    height: 150px;
    border-radius: 10px;
  }
  .brands .container .row .data .item h5 {
    width: 90%;
    font-size: 16px;
  }
  .pageSubTitle.brandDetails .container .data h2 {
    font-size: 22px;
    line-height: 32px;
  }
  .pageSubTitle.brandData div {
    margin-bottom: 100px;
  }
  .pageSubTitle.brandData div h2 {
    font-size: 26px;
    margin-bottom: 36px;
    max-width: 60%;
  }
  .pageSubTitle.brandData div img {
    width: 200px;
    height: 200px;
    margin: 0 0 20px 20px;
    padding: 20px;
  }
  .pageSubTitle.brandData div.text-center {
    margin: 50px 0 30px;
  }
  .pageSubTitle.brandData div.text-center a {
    padding: 12px 30px;
  }
  .pageSubTitle.brandData div:last-child {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .brands {
    padding: 50px 0;
  }
  .brands .container .row .data {
    grid-gap: 60px 20px;
    grid-template-columns: 1fr 1fr;
    padding: 0 0px;
  }
  .brands .container .row .data h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .brands .container .row .data p {
    font-size: 18px;
    line-height: 24px;
  }
  .brands .container .row .data small {
    font-size: 16px;
    line-height: 18px;
  }
  .brands .container .row .data .item:first-child {
    grid-column-start: span 2;
    padding-left: 0px;
  }
  .brands .container .row .data .item .imgWrapper {
    border-radius: 10px;
  }
  .brands .container .row .data .item .imgWrapper img {
    height: 200px;
    border-radius: 10px;
  }
  .brands .container .row .data .item h5 {
    width: 90%;
    font-size: 16px;
  }
  .pageSubTitle.brandDetails .container .data h2 {
    font-size: 20px;
    line-height: 30px;
  }
  .pageSubTitle.brandData {
    padding-top: 0;
  }
  .pageSubTitle.brandData div {
    margin-bottom: 100px;
  }
  .pageSubTitle.brandData div h2 {
    font-size: 24px;
    margin-bottom: 34px;
    max-width: 60%;
  }
  .pageSubTitle.brandData div img {
    width: 150px;
    height: 150px;
    margin: 0 0 20px 20px;
  }
  .pageSubTitle.brandData div.text-center {
    margin: 50px 0 30px;
  }
  .pageSubTitle.brandData div.text-center a {
    padding: 12px 30px;
  }
  .pageSubTitle.brandData div:last-child {
    margin-bottom: 30px;
  }
}
@media (max-width: 600px) {
  .brands {
    padding: 0 0 70px 0;
  }
  .brands .container .row .data {
    grid-gap: 50px 15px;
    grid-template-columns: 1fr 1fr;
    padding: 0 15px;
  }
  .brands .container .row .data h2 {
    font-size: 25px;
    line-height: 32px;
  }
  .brands .container .row .data p {
    font-size: 17px;
    line-height: 24px;
  }
  .brands .container .row .data small {
    font-size: 16px;
    line-height: 18px;
  }
  .brands .container .row .data .item:first-child {
    grid-column-start: span 2;
    padding-left: 0px;
  }
  .brands .container .row .data .item:first-child small br {
    display: none;
  }
  .brands .container .row .data .item .imgWrapper {
    border-radius: 10px;
  }
  .brands .container .row .data .item .imgWrapper img {
    height: 150px;
    border-radius: 10px;
  }
  .brands .container .row .data .item h5 {
    width: 90%;
    font-size: 14px;
  }
  .pageSubTitle.brandDetails .container .data h2 {
    font-size: 20px;
    line-height: 30px;
  }
  .pageSubTitle.brandData {
    padding-top: 0;
  }
  .pageSubTitle.brandData div {
    margin-bottom: 100px;
  }
  .pageSubTitle.brandData div h2 {
    font-size: 20px;
    margin-bottom: 20px;
    max-width: 100%;
  }
  .pageSubTitle.brandData div img {
    float: none;
    width: 190px;
    height: 190px;
    margin: 0 0 20px 0px;
    padding: 10px;
  }
  .pageSubTitle.brandData div.text-center {
    margin: 50px 0 30px;
  }
  .pageSubTitle.brandData div.text-center a {
    padding: 12px 30px;
  }
  .pageSubTitle.brandData div:last-child {
    margin-bottom: 30px;
  }
}/*# sourceMappingURL=brands.css.map */