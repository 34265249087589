.pageTitle.timeline {
    padding-top: 50px;
    padding-bottom: 120px;

    .steps-timeline {
        margin-top: 70px;
        font-size: 14px;
        line-height: 16px;
        position: relative;

        &:before {
            content: '';
            width: 175px;
            height: 100px;
            background: url(../../images/arrow.webp);
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 9;
            transform: translate(0%, -50%);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        &:after {
            content: '';
            width: 175px;
            height: 100px;
            background: url(../../images/arrow.webp);
            position: absolute;
            top: 50%;
            right: 0;
            z-index: 9;
            transform: translate(0%, -50%);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        .step {
            outline: 1px dashed rgba(0, 128, 0, 0);
            margin-bottom: 0px;
            position: relative;
            padding: 240px 0;

            .line {
                height: 240px;
                position: absolute;
                left: 100px;
                width: 1px;

                &:after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 100px;
                    position: absolute;
                    left: -5px;
                }
            }

            .step-milestone {
                display: flex;
                width: 100%;
                height: 100px;
                border-radius: 0;
                margin: 0px auto;
                box-sizing: border-box;
                color: var(--white);
                text-align: center;
                position: relative;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                font-weight: 500;
                padding-left: 50px;

                &:after {
                    content: '';
                    width: 30px;
                    height: 100px;
                    border-bottom: solid 50px transparent;
                    border-top: solid 50px transparent;
                    border-left: solid 50px transparent;
                    display: block;
                    position: absolute;
                    top: 0;
                    right: -50px;
                    z-index: 99;
                }

                &:before {
                    content: '';
                    width: 30px;
                    height: 100px;
                    border-left: solid 50px var(--white);
                    border-bottom: solid 50px transparent;
                    border-top: solid 50px transparent;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .stepData {
                position: absolute;
                width: 300px;
                left: 125px;
                height: 240px;
                padding: 20px 0;

                img {
                    width: 40px;
                }

                .step-title,
                .step-description {
                    margin: 0;
                    display: block;
                }

                .step-title {
                    font-size: 24px;
                    line-height: 24px;
                    font-weight: 500;
                    font-weight: bold;
                    margin: 15px 0;
                    text-transform: uppercase;
                }

                .step-description {
                    overflow: hidden;
                    font-size: 15px;
                    line-height: 22px;
                }
            }

            &:nth-child(1) {
                .line {
                    top: 0;
                    background-color: #014E74;

                    &:after {
                        background-color: #014E74;
                        top: -5px;
                    }
                }

                .step-milestone {
                    background-color: #014E74;

                    &:after {
                        border-left-color: #014E74;
                    }
                }

                .stepData {
                    top: 0;

                    .step-title {
                        color: #014E74;
                    }
                }
            }

            &:nth-child(2) {
                .line {
                    bottom: 0;
                    background-color: #047FBB;

                    &:after {
                        background-color: #047FBB;
                        bottom: -5px;
                    }
                }

                .step-milestone {
                    background-color: #047FBB;

                    &:after {
                        border-left-color: #047FBB;
                    }
                }

                .stepData {
                    bottom: 0;

                    .step-title {
                        color: #047FBB;
                    }
                }
            }

            &:nth-child(3) {
                .line {
                    top: 0;
                    background-color: #FF3E60;

                    &:after {
                        background-color: #FF3E60;
                        top: -5px;
                    }
                }

                .step-milestone {
                    background-color: #FF3E60;

                    &:after {
                        border-left-color: #FF3E60;
                    }
                }

                .stepData {
                    top: 0;

                    .step-title {
                        color: #FF3E60;
                    }
                }
            }

            &:nth-child(4) {
                .line {
                    bottom: 0;
                    background-color: #EE0029;

                    &:after {
                        background-color: #EE0029;
                        bottom: -5px;
                    }
                }

                .step-milestone {
                    background-color: #EE0029;

                    &:after {
                        border-left-color: #EE0029;
                    }
                }

                .stepData {
                    bottom: 0;

                    .step-title {
                        color: #EE0029;
                    }
                }
            }

            &:nth-child(5) {
                .line {
                    top: 0;
                    background-color: #C40727;

                    &:after {
                        background-color: #C40727;
                        top: -5px;
                    }
                }

                .step-milestone {
                    background-color: #C40727;

                    &:after {
                        border-left-color: #C40727;
                    }
                }

                .stepData {
                    top: 0;

                    .step-title {
                        color: #C40727;
                    }
                }
            }
        }
    }
}

.pageTitle.aliceTimeline {
    padding: 50px 0;
    .container {
        .alice-carousel {
            margin-top: 30px;
            max-width: 85%;
            &:before {
                content: "";
                width: 30px;
                height: 90px;
                border-bottom: solid 45px transparent;
                border-top: solid 45px transparent;
                border-left: solid 45px #007eba;
                display: block;
                position: absolute;
                top: 50%;
                left: -45px;
                transform: translate(0%, -50%) rotate(180deg);
            }
            &:after {
                content: "";
                width: 30px;
                height: 90px;
                border-bottom: solid 45px #ffffff;
                border-top: solid 45px #ffffff;
                border-left: solid 45px #ef2346;
                display: block;
                position: absolute;
                top: 50%;
                right: -44px;
                transform: translate(0%, -50%);
            }
            .alice-carousel__wrapper {
                &:before {
                    content: '';
                    background: rgb(0,127,188);
                    background: linear-gradient(90deg, rgba(0,127,188,1) 0%, rgba(239,35,70,1) 100%);
                    height: 90px;
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                } 
                ul.alice-carousel__stage {
                    li.alice-carousel__stage-item {
                        .mbox {
                            display: flex;
                            padding: 300px 0;
                            .myear {
                                text-align: center;
                                font-size: 25px;
                                font-weight: 600;
                                color: var(--white);
                                padding: 30px 0;
                                position: relative;
                                box-shadow: inset -10px 0px 40px -35px #000;

                                &:before {
                                    content: "";
                                    width: 30px;
                                    height: 90px;
                                    border-bottom: solid 50px transparent;
                                    border-top: solid 45px transparent;
                                    border-left: solid 30px transparent;
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 0;
                                    z-index: 99;
                                    transform: translate(0%, -50%);
                                }

                                &:after {
                                    content: "";
                                    width: 30px;
                                    height: 90px;
                                    border-bottom: solid 45px transparent;
                                    border-top: solid 45px transparent;
                                    border-left: solid 30px transparent;
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    right: -30px;
                                    z-index: 99;
                                }
                            }
                            .mdata {
                                position: absolute;
                                left: 50px;
                                // width: 400px;
                                z-index: 99;
                                height: 300px;
                                img {
                                    margin-bottom: 15px;
                                }
                                span {
                                    text-transform: uppercase;
                                    display: block;
                                    margin-bottom: 10px;
                                    color: var(--secondary);
                                    font-weight: 600;
                                    font-size: 18px;
                                }
                                small {
                                    font-size: 16px;
                                    line-height: 16px;
                                }
                            }
                        }
                        &:nth-child(odd) {
                            .mbox {
                                flex-direction: column-reverse;
                                .myear {
                                    // background-color: #5c5c5c;
                                }
                                .mdata {
                                    top: 20px;
                                    span {
                                        color: #014E74;
                                    }
                                    small {
    
                                    }
                                }
                                &:before {
                                    content: '';
                                    top: 0;
                                    left: 20px;
                                    width: 1px;
                                    height: 302px;
                                    background-color: #014E74;
                                    position: absolute;
                                }
                                &:after {
                                    content: '';
                                    top: 0;
                                    left: 15px;
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 50px;
                                    background-color: #014E74;
                                    position: absolute;
                                }
                            }
                        }
                        &:nth-child(even) {
                            .mbox {
                                flex-direction: column;
                                .myear {
                                    // background-color: #cfcfcf;
                                }
                                .mdata {
                                    bottom: -20px;
                                    span {
                                        color: #014E74;
                                    }
                                    small {
    
                                    }
                                }
                                &:before {
                                    content: '';
                                    bottom: 0;
                                    left: 20px;
                                    width: 1px;
                                    height: 300px;
                                    background-color: #014E74;
                                    position: absolute;
                                }
                                &:after {
                                    content: '';
                                    bottom: 0;
                                    left: 15px;
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 50px;
                                    background-color: #014E74;
                                    position: absolute;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
        .alice-carousel__prev-btn, .alice-carousel__next-btn {
            position: absolute;
            top: 50%;
            width: auto;
            cursor: pointer;
            z-index: 1;
            height: 90px;
            background: #ccc;
            width: 145px;
            padding: 0;
            div {
                height: 100%;
                p {
                    width: 100%;
                    height: 100%;
                    span {
                        font-size: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .alice-carousel__prev-btn {
            left: -95px;
            transform: translate(-50%, -50%);
            background: url(../../images/arrowleft.webp) no-repeat center center;
            background-size: contain;
            -webkit-animation: heartbeat2 3s ease-in-out infinite both;
                    animation: heartbeat2 3s ease-in-out infinite both;
        }
        .alice-carousel__next-btn {
            right: -95px;
            transform: translate(-50%, -50%);
            background: url(../../images/arrow.webp) no-repeat center center;
            background-size: contain;
            transform: translate(50%, -50%);
            -webkit-animation: heartbeat1 3s ease-in-out infinite both;
                    animation: heartbeat1 3s ease-in-out infinite both;
        }
    }
}

@media screen and (max-width: 678px) {

    .steps-timeline .step {
        display: block;
    }

    .step-milestone {
        float: left;
        margin-left: 10px;
    }

    .step-title,
    .step-description {
        text-align: left;
        margin-left: 0px;
    }

}

@media screen and (min-width: 678px) {

    .steps-timeline {
        padding-left: 150px;
        padding-right: 200px;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
    }

    .steps-timeline .step {
        -webkit-flex: 1;
        /* Safari 6.1+ */
        -ms-flex: 1;
        /* IE 10 */
        flex: 1;
    }

}

@media (min-width: 1369px) and (max-width: 1600px) {
	.pageTitle.timeline {
        padding-top: 50px;
        padding-bottom: 120px;
    
        .steps-timeline {
            margin-top: 60px;
            font-size: 14px;
            line-height: 16px;
    
            &:before {
                width: 150px;
                height: 90px;
            }
    
            &:after {
                width: 150px;
                height: 90px;
            }
    
            .step {
                padding: 250px 0;
    
                .line {
                    height: 250px;
                    left: 100px;
                }
    
                .step-milestone {
                    height: 90px;
                    font-size: 22px;
    
                    &:after {
                        width: 30px;
                        height: 90px;
                        border-bottom: solid 45px transparent;
                        border-top: solid 45px transparent;
                        border-left: solid 45px transparent;
                        right: -45px;
                    }
    
                    &:before {
                        width: 30px;
                        height: 90px;
                        border-left: solid 45px var(--white);
                        border-bottom: solid 45px transparent;
                        border-top: solid 45px transparent;
                    }
                }
    
                .stepData {
                    width: 300px;
                    left: 125px;
    
                    .step-title {
                        font-size: 20px;
                        line-height: 20px;
                    }
                    .ste-description {
                        font-size: 20px;
                        line-height: 26px;
                    }
                }
            }
        }
    }
    .pageTitle.aliceTimeline {
        .container {
            .alice-carousel {
                max-width: 85%;
                &:before {
                    width: 45px;
                    height: 80px;
                    border-bottom: solid 40px transparent;
                    border-top: solid 40px transparent;
                    border-left: solid 40px #007eba;
                    left: -44px;
                }
                &:after {
                    width: 45px;
                    height: 80px;
                    border-bottom: solid 40px #ffffff;
                    border-top: solid 40px #ffffff;
                    border-left: solid 40px #ef2346;
                    right: -44px;
                }
                .alice-carousel__wrapper {
                    &:before {
                        height: 80px;
                    } 
                    ul.alice-carousel__stage {
                        li.alice-carousel__stage-item {
                            .mbox {
                                padding: 280px 0;
                                .myear {
                                    font-size: 25px;
                                    padding: 20px 0;
    
                                    &:before {
                                        width: 30px;
                                        height: 80px;
                                        border-bottom: solid 50px transparent;
                                        border-top: solid 45px transparent;
                                        border-left: solid 30px transparent;
                                    }
    
                                    &:after {
                                        width: 30px;
                                        height: 80px;
                                        border-bottom: solid 45px transparent;
                                        border-top: solid 45px transparent;
                                        border-left: solid 30px transparent;
                                        right: -30px;
                                    }
                                }
                                .mdata {
                                    left: 40px;
                                    height: 280px;
                                }
                            }
                            &:nth-child(odd) {
                                .mbox {
                                    flex-direction: column-reverse;
                                    .myear {
                                        // background-color: #5c5c5c;
                                    }
                                    .mdata {
                                        top: 20px;
                                    }
                                    &:before {
                                        left: 20px;
                                        height: 282px;
                                    }
                                    &:after {
                                        top: 0;
                                        left: 15px;
                                        width: 10px;
                                        height: 10px;
                                    }
                                }
                            }
                            &:nth-child(even) {
                                .mbox {
                                    flex-direction: column;
                                    .myear {
                                        // background-color: #cfcfcf;
                                    }
                                    .mdata {
                                        bottom: -20px;
                                    }
                                    &:before {
                                        bottom: 0;
                                        left: 20px;
                                        height: 282px;
                                    }
                                    &:after {
                                        bottom: 0;
                                        left: 15px;
                                        width: 10px;
                                        height: 10px;
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
            .alice-carousel__prev-btn, .alice-carousel__next-btn {
                height: 80px;
                width: 145px;
            }
            .alice-carousel__prev-btn {
                left: -80px;
            }
            .alice-carousel__next-btn {
                right: -80px;
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
	.pageTitle.timeline {
        padding-top: 50px;
        padding-bottom: 120px;
    
        .steps-timeline {
            margin-top: 60px;
            font-size: 14px;
            line-height: 16px;
    
            &:before, &:after {
                width: 130px;
                height: 70px;
            }
    
            .step {
                padding: 240px 0;
    
                .line {
                    height: 240px;
                    left: 100px;
                }
    
                .step-milestone {
                    height: 70px;
                    font-size: 18px;
                    padding-right: 25px;
                    justify-content: end;
                    padding-left: 25px;
    
                    &:after {
                        width: 30px;
                        height: 70px;
                        border-bottom: solid 35px transparent;
                        border-top: solid 35px transparent;
                        border-left: solid 35px transparent;
                        right: -35px;
                    }
    
                    &:before {
                        width: 45px;
                        height: 70px;
                        border-left: solid 35px var(--white);
                        border-bottom: solid 35px transparent;
                        border-top: solid 35px transparent;
                    }
                }
    
                .stepData {
                    width: 250px;
                    left: 125px;
                    height: 240px;
    
                    .step-title {
                        font-size: 18px;
                        line-height: 20px;
                    }
                    .ste-description {
                        font-size: 20px;
                        line-height: 26px;
                    }
                }
            }
        }
    }
    .pageTitle.aliceTimeline {
        .container {
            .alice-carousel {
                max-width: 80%;
                &:before {
                    width: 35px;
                    height: 70px;
                    border-bottom: solid 35px transparent;
                    border-top: solid 35px transparent;
                    border-left: solid 35px #007eba;
                    left: -35px;
                }
                &:after {
                    width: 35px;
                    height: 70px;
                    border-bottom: solid 35px #ffffff;
                    border-top: solid 35px #ffffff;
                    border-left: solid 35px #ef2346;
                    right: -35px;
                }
                .alice-carousel__wrapper {
                    &:before {
                        height: 70px;
                    } 
                    ul.alice-carousel__stage {
                        li.alice-carousel__stage-item {
                            .mbox {
                                padding: 280px 0;
                                .myear {
                                    font-size: 22px;
                                    padding: 20px 0;
    
                                    &:before {
                                        width: 30px;
                                        height: 70px;
                                        border-bottom: solid 50px transparent;
                                        border-top: solid 45px transparent;
                                        border-left: solid 30px transparent;
                                    }
    
                                    &:after {
                                        width: 30px;
                                        height: 70px;
                                        border-bottom: solid 45px transparent;
                                        border-top: solid 45px transparent;
                                        border-left: solid 30px transparent;
                                        right: -30px;
                                    }
                                }
                                .mdata {
                                    left: 40px;
                                    height: 280px;
                                    span {
                                        font-size: 16px;
                                    }
                                    small {
                                        font-size: 15px;
                                    }
                                }
                            }
                            &:nth-child(odd) {
                                .mbox {
                                    flex-direction: column-reverse;
                                    .myear {
                                        // background-color: #5c5c5c;
                                    }
                                    .mdata {
                                        top: 20px;
                                    }
                                    &:before {
                                        left: 20px;
                                        height: 282px;
                                    }
                                    &:after {
                                        top: 0;
                                        left: 15px;
                                        width: 10px;
                                        height: 10px;
                                    }
                                }
                            }
                            &:nth-child(even) {
                                .mbox {
                                    flex-direction: column;
                                    .myear {
                                        // background-color: #cfcfcf;
                                    }
                                    .mdata {
                                        bottom: -20px;
                                    }
                                    &:before {
                                        bottom: 0;
                                        left: 20px;
                                        height: 282px;
                                    }
                                    &:after {
                                        bottom: 0;
                                        left: 15px;
                                        width: 10px;
                                        height: 10px;
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
            .alice-carousel__prev-btn, .alice-carousel__next-btn {
                height: 70px;
                width: 145px;
            }
            .alice-carousel__prev-btn {
                left: -70px;
            }
            .alice-carousel__next-btn {
                right: -70px;
            }
        }
    }
}

@media (min-width: 1014px) and (max-width: 1200px) {
    .pageTitle.aliceTimeline {
        .container {
            .alice-carousel {
                max-width: 80%;
                &:before {
                    width: 35px;
                    height: 70px;
                    border-bottom: solid 35px transparent;
                    border-top: solid 35px transparent;
                    border-left: solid 35px #007eba;
                    left: -35px;
                    bottom: 0;
                    top: unset;
                    transform: translate(0%, 0%) rotate(180deg);
                }
                &:after {
                    width: 35px;
                    height: 70px;
                    border-bottom: solid 35px #ffffff;
                    border-top: solid 35px #ffffff;
                    border-left: solid 35px #ef2346;
                    right: -35px;
                    bottom: 0;
                    top: unset;
                    transform: translate(0%, 0%);
                }
                .alice-carousel__wrapper {
                    &:before {
                        height: 70px;
                        top: unset;
                        left: 50%;
                        transform: translate(-50%, 0);
                        bottom: 0;
                    } 
                    ul.alice-carousel__stage {
                        li.alice-carousel__stage-item {
                            .mbox {
                                padding: 220px 0 0;
                                .myear {
                                    font-size: 22px;
                                    padding: 20px 0;
    
                                    &:before {
                                        width: 30px;
                                        height: 70px;
                                        border-bottom: solid 50px transparent;
                                        border-top: solid 45px transparent;
                                        border-left: solid 30px transparent;
                                    }
    
                                    &:after {
                                        width: 30px;
                                        height: 70px;
                                        border-bottom: solid 45px transparent;
                                        border-top: solid 45px transparent;
                                        border-left: solid 30px transparent;
                                        right: -30px;
                                    }
                                }
                                .mdata {
                                    left: 50px;
                                    span {
                                        font-size: 16px;
                                    }
                                    small {
                                        font-size: 15px;
                                    }
                                }
                            }
                            &:nth-child(odd) {
                                .mbox {
                                    flex-direction: column-reverse;
                                    .myear {
                                        // background-color: #5c5c5c;
                                    }
                                    .mdata {
                                        top: 10px;
                                    }
                                    &:before {
                                        left: 20px;
                                        height: 220px;
                                    }
                                    &:after {
                                        top: 0;
                                        left: 15px;
                                        width: 10px;
                                        height: 10px;
                                    }
                                }
                            }
                            &:nth-child(even) {
                                .mbox {
                                    flex-direction: column;
                                    .myear {
                                        // background-color: #cfcfcf;
                                    }
                                    .mdata {
                                        bottom: unset;
                                        top: 0;
                                    }
                                    &:before {
                                        bottom: unset;
                                        top: 0;
                                        left: 20px;
                                        height: 220px;
                                    }
                                    &:after {
                                        bottom: unset;
                                        top: 0;
                                        left: 15px;
                                        width: 10px;
                                        height: 10px;
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
            .alice-carousel__prev-btn, .alice-carousel__next-btn {
                height: 70px;
                width: 145px;
                bottom: 0;
                top: unset;
                animation: unset;
            }
            .alice-carousel__prev-btn {
                left: -70px;
                transform: translate(-50%, -0%);
            }
            .alice-carousel__next-btn {
                right: -70px;
                transform: translate(50%, -0%);
            }
        }
    }
}
@media (min-width: 993px) and (max-width: 1200px) {
	.pageTitle.timeline {
        padding-top: 50px;
        padding-bottom: 120px;
    
        .steps-timeline {
            margin-top: 60px;
            font-size: 14px;
            line-height: 16px;
            padding-left: 125px;
            padding-right: 170px;
    
            &:before {
                width: 150px;
                height: 80px;
            }
    
            &:after {
                width: 150px;
                height: 80px;
            }
    
            .step {
                padding: 240px 0;
    
                .line {
                    height: 240px;
                    left: 100px;
                }
    
                .step-milestone {
                    height: 80px;
                    font-size: 22px;
                    padding-right: 15px;
                    justify-content: end;
    
                    &:after {
                        width: 30px;
                        height: 80px;
                        border-bottom: solid 40px transparent;
                        border-top: solid 40px transparent;
                        border-left: solid 40px transparent;
                        right: -40px;
                    }
    
                    &:before {
                        width: 30px;
                        height: 80px;
                        border-left: solid 40px var(--white);
                        border-bottom: solid 40px transparent;
                        border-top: solid 40px transparent;
                    }
                }
    
                .stepData {
                    width: 205px;
                    left: 115px;
                    height: 240px;
    
                    .step-title {
                        font-size: 18px;
                        line-height: 20px;
                    }
                    .step-description {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1014px) {
    .pageTitle.aliceTimeline {
        .container {
            .alice-carousel {
                max-width: 70%;
                &:before {
                    width: 35px;
                    height: 70px;
                    border-bottom: solid 35px transparent;
                    border-top: solid 35px transparent;
                    border-left: solid 35px #007eba;
                    left: -35px;
                    bottom: 0;
                    top: unset;
                    transform: translate(0%, 0%) rotate(180deg);
                }
                &:after {
                    width: 35px;
                    height: 70px;
                    border-bottom: solid 35px #ffffff;
                    border-top: solid 35px #ffffff;
                    border-left: solid 35px #ef2346;
                    right: -35px;
                    bottom: 0;
                    top: unset;
                    transform: translate(0%, 0%);
                }
                .alice-carousel__wrapper {
                    &:before {
                        height: 70px;
                        top: unset;
                        left: 50%;
                        transform: translate(-50%, 0);
                        bottom: 0;
                    } 
                    ul.alice-carousel__stage {
                        li.alice-carousel__stage-item {
                            .mbox {
                                padding: 230px 0 0;
                                .myear {
                                    font-size: 22px;
                                    padding: 20px 0;
    
                                    &:before {
                                        width: 30px;
                                        height: 70px;
                                        border-bottom: solid 50px transparent;
                                        border-top: solid 45px transparent;
                                        border-left: solid 30px transparent;
                                    }
    
                                    &:after {
                                        width: 30px;
                                        height: 70px;
                                        border-bottom: solid 45px transparent;
                                        border-top: solid 45px transparent;
                                        border-left: solid 30px transparent;
                                        right: -30px;
                                    }
                                }
                                .mdata {
                                    left: 50px;
                                    span {
                                        font-size: 16px;
                                    }
                                    small {
                                        font-size: 15px;
                                    }
                                }
                            }
                            &:nth-child(odd) {
                                .mbox {
                                    flex-direction: column-reverse;
                                    .myear {
                                        // background-color: #5c5c5c;
                                    }
                                    .mdata {
                                        top: 10px;
                                    }
                                    &:before {
                                        left: 20px;
                                        height: 232px;
                                    }
                                    &:after {
                                        top: 0;
                                        left: 15px;
                                        width: 10px;
                                        height: 10px;
                                    }
                                }
                            }
                            &:nth-child(even) {
                                .mbox {
                                    flex-direction: column;
                                    .myear {
                                        // background-color: #cfcfcf;
                                    }
                                    .mdata {
                                        bottom: unset;
                                        top: 10px;
                                    }
                                    &:before {
                                        bottom: unset;
                                        top: 0;
                                        left: 20px;
                                        height: 232px;
                                    }
                                    &:after {
                                        bottom: unset;
                                        top: 0;
                                        left: 15px;
                                        width: 10px;
                                        height: 10px;
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
            .alice-carousel__prev-btn, .alice-carousel__next-btn {
                height: 70px;
                width: 145px;
                bottom: 0;
                top: unset;
                animation: unset;
            }
            .alice-carousel__prev-btn {
                left: -70px;
                transform: translate(-50%, -0%);
            }
            .alice-carousel__next-btn {
                right: -70px;
                transform: translate(50%, -0%);
            }
        }
    }
}

@media (max-width: 992px) {
	.pageTitle.timeline {
        padding-top: 50px;
        padding-bottom: 120px;
    
        .steps-timeline {
            margin-top: 60px;
            font-size: 14px;
            line-height: 16px;
            padding-left: 0px;
            padding-right: 0px;
            flex-direction: column;
            gap: 30px;
    
            &:before {
                width: 150px;
                height: 80px;
                display: none;
            }
    
            &:after {
                width: 150px;
                height: 80px;
                display: none;
            }
    
            .step {
                padding: 0px 0;
                display: flex;
    
                .line {
                    height: 200px;
                    left: 100px;
                }
    
                .step-milestone {
                    height: 100px;
                    font-size: 22px;
                    width: 100px;
                    margin: 0;
                    padding-top: 26px;
                    padding-left: 0px;

                    &nbsp {
                        display: none;
                    }
    
                    &:after {
                        width: 30px;
                        height: 100px;
                        border-left: solid 20px transparent;
                        border-bottom: solid 50px transparent;
                        border-top: solid 50px transparent;
                        right: -30px;
                        transform: rotate(90deg);
                        left: 35px;
                        bottom: -65px;
                        top: unset;
                    }

                    &:before {
                        width: 30px;
                        height: 100px;
                        border-left: solid 20px var(--white);
                        border-bottom: solid 50px transparent;
                        border-top: solid 50px transparent;
                        transform: rotate(90deg);
                        left: 35px;
                        top: -35px;
                    }
                }
    
                .stepData {
                    width: 450px;
                    left: 100px;
                    position: relative;
                    height: auto;
    
                    .step-title {
                        font-size: 22px;
                        line-height: 30px;
                    }
                    .ste-description {
                        font-size: 20px;
                        line-height: 26px;
                    }
                }

                &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                    .line {
                        top: 40px;
                        width: 75px;
                        height: 1px;
                        left: 100px;
    
                        &:after {
                            top: -5px;
                            left: unset;
                            right: -5px;
                        }
                    }
                }
            }
        }
    }
    .pageTitle.aliceTimeline {
        .container {
            .alice-carousel {
                max-width: 70%;
                &:before {
                    width: 35px;
                    height: 60px;
                    border-bottom: solid 30px transparent;
                    border-top: solid 30px transparent;
                    border-left: solid 30px #007eba;
                    left: -34px;
                }
                &:after {
                    width: 30px;
                    height: 60px;
                    border-bottom: solid 30px #ffffff;
                    border-top: solid 30px #ffffff;
                    border-left: solid 30px #ef2346;
                    right: -30px;
                }
                .alice-carousel__wrapper {
                    &:before {
                        height: 60px;
                    } 
                    ul.alice-carousel__stage {
                        li.alice-carousel__stage-item {
                            .mbox {
                                padding: 280px 0 0;
                                .myear {
                                    font-size: 20px;
                                    padding: 15px 0;
    
                                    &:before {
                                        width: 30px;
                                        height: 60px;
                                        border-bottom: solid 50px transparent;
                                        border-top: solid 45px transparent;
                                        border-left: solid 30px transparent;
                                    }
    
                                    &:after {
                                        width: 30px;
                                        height: 60px;
                                        border-bottom: solid 45px transparent;
                                        border-top: solid 45px transparent;
                                        border-left: solid 30px transparent;
                                        right: -30px;
                                    }
                                }
                                .mdata {
                                    left: 50px;
                                    width: auto;
                                }
                            }
                            &:nth-child(odd) {
                                .mbox {
                                    flex-direction: column-reverse;
                                    .myear {
                                        // background-color: #5c5c5c;
                                    }
                                    .mdata {
                                        top: 10px;
                                    }
                                    &:before {
                                        left: 20px;
                                        height: 280px;
                                    }
                                    &:after {
                                        top: 0;
                                        left: 15px;
                                        width: 10px;
                                        height: 10px;
                                    }
                                }
                            }
                            &:nth-child(even) {
                                .mbox {
                                    flex-direction: column;
                                    .myear {
                                        // background-color: #cfcfcf;
                                    }
                                    .mdata {
                                        bottom: unset;
                                        top: 10px;
                                    }
                                    &:before {
                                        bottom: unset;
                                        top: 0;
                                        left: 20px;
                                        height: 280px;
                                    }
                                    &:after {
                                        bottom: unset;
                                        top: 0;
                                        left: 15px;
                                        width: 10px;
                                        height: 10px;
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
            .alice-carousel__prev-btn, .alice-carousel__next-btn {
                height: 60px;
                width: 75px;
                bottom: 0;
                top: unset;
                animation: unset;
            }
            .alice-carousel__prev-btn {
                left: -60px;
                transform: translate(-50%, -0%);
            }
            .alice-carousel__next-btn {
                right: -60px;
                transform: translate(50%, -0%);
            }
        }
    }
}

@media (max-width: 767px) {
	.pageTitle.timeline {
        padding-top: 50px;
        padding-bottom: 120px;
    
        .steps-timeline {
            margin-top: 60px;
            font-size: 14px;
            line-height: 16px;
            padding-left: 0px;
            padding-right: 0px;
            flex-direction: column;
            gap: 50px;
            display: flex;
            margin-left: 0;

    
            &:before {
                width: 150px;
                height: 80px;
                display: none;
            }
    
            &:after {
                width: 150px;
                height: 80px;
                display: none;
            }
    
            .step {
                padding: 0px 0;
                display: flex;
    
                .line {
                    height: 200px;
                    left: 100px;
                }
    
                .step-milestone {
                    height: 80px;
                    font-size: 22px;
                    width: 100px;
                    margin: 0;
    
                    // &:after {
                    //     width: 30px;
                    //     height: 80px;
                    //     border-left: solid 20px transparent;
                    //     border-bottom: solid 40px transparent;
                    //     border-top: solid 40px transparent;
                    //     right: -30px;
                    // }

                    // &:before {
                    //     width: 30px;
                    //     height: 80px;
                    //     border-left: solid 20px var(--white);
                    //     border-bottom: solid 40px transparent;
                    //     border-top: solid 40px transparent;
                    // }
                }
    
                .stepData {
                    width: 300px;
                    left: 100px;
                    position: relative;
    
                    .step-title {
                        font-size: 22px;
                        line-height: 30px;
                    }
                    .ste-description {
                        font-size: 20px;
                        line-height: 26px;
                    }
                }

                &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                    .line {
                        // top: 40px;
                        // width: 60px;
                        // height: 1px;
                        // left: 120px;
    
                        &:after {
                            top: -5px;
                            left: unset;
                            right: -5px;
                        }
                    }
                }
            }
        }
    }
    .pageTitle.aliceTimeline {
        .container {
            .alice-carousel {
                .alice-carousel__wrapper {
                    ul.alice-carousel__stage {
                        li.alice-carousel__stage-item {
                            .mbox {
                                padding: 230px 0 0;
                                .mdata {
                                    left: 40px;
                                }
                            }
                            &:nth-child(odd) {
                                .mbox {
                                    &:before {
                                        height: 230px;
                                    }
                                }
                            }
                            &:nth-child(even) {
                                .mbox {
                                    &:before {
                                        height: 230px;
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
	.pageTitle.timeline {
        padding-top: 50px;
        padding-bottom: 50px;
    
        .steps-timeline {
            margin-top: 60px;
            font-size: 14px;
            line-height: 16px;
            padding-left: 0px;
            padding-right: 0px;
            flex-direction: column;
            gap: 50px;
            display: flex;
            margin-left: 0;

    
            &:before {
                width: 150px;
                height: 80px;
                display: none;
            }
    
            &:after {
                width: 150px;
                height: 80px;
                display: none;
            }
    
            .step {
                padding: 0px 0;
                display: flex;
    
                .line {
                    height: 200px;
                    left: 100px;
                }
    
                .step-milestone {
                    height: 80px;
                    font-size: 16px;
                    width: 80px;
                    margin: 0;
                    padding-top: 20px;
    
                    &:after {
                        width: 30px;
                        height: 80px;
                        border-left: solid 15px transparent;
                        border-bottom: solid 40px transparent;
                        border-top: solid 40px transparent;
                        left: 25px;
                        bottom: -55px;
                    }

                    &:before {
                        width: 30px;
                        height: 80px;
                        border-left: solid 15px var(--white);
                        border-bottom: solid 40px transparent;
                        border-top: solid 40px transparent;
                        left: 25px;
                        top: -25px;                
                    }
                }
    
                .stepData {
                    width: 180px;
                    left: 60px;
    
                    .step-title {
                        font-size: 18px;
                        line-height: 20px;
                    }
                    .ste-description {
                        font-size: 20px;
                        line-height: 26px;
                    }
                }

                &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                    .line {
                        top: 40px;
                        width: 40px;
                        height: 1px;
                        left: 80px;
    
                        &:after {
                            top: -5px;
                            left: unset;
                            right: -5px;
                        }
                    }
                }
            }
        }
    }
    .pageTitle.aliceTimeline {
        .container {
            .alice-carousel {
                max-width: 80%;
                &:before {
                    left: -34px;
                }
                &:after {
                    right: -29px;
                }
                .alice-carousel__wrapper {
                    ul.alice-carousel__stage {
                        li.alice-carousel__stage-item {
                            .mbox {
                                padding: 255px 0 0;
                                .myear {
                                    font-size: 18px;
                                }
                                .mdata {
                                    left: 40px;
                                }
                            }
                            &:nth-child(odd) {
                                .mbox {
                                    &:before {
                                        height: 250px;
                                    }
                                }
                            }
                            &:nth-child(even) {
                                .mbox {
                                    &:before {
                                        height: 250px;
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
            .alice-carousel__prev-btn {
                left: 20px;
            }
            .alice-carousel__next-btn {
                right: 20px;
            }
        }
    }
}

.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	        animation: heartbeat 1.5s ease-in-out infinite both;
}
 @-webkit-keyframes heartbeat1 {
    from {
      -webkit-transform: scale(1) translate(50%, -50%);
              transform: scale(1) translate(50%, -50%);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91) translate(50%, -50%);
              transform: scale(0.91) translate(50%, -50%);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98) translate(50%, -50%);
              transform: scale(0.98) translate(50%, -50%);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87) translate(50%, -50%);
              transform: scale(0.87) translate(50%, -50%);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1) translate(50%, -50%);
              transform: scale(1) translate(50%, -50%);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes heartbeat1 {
    from {
      -webkit-transform: scale(1) translate(50%, -50%);
              transform: scale(1) translate(50%, -50%);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91) translate(50%, -50%);
              transform: scale(0.91) translate(50%, -50%);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98) translate(50%, -50%);
              transform: scale(0.98) translate(50%, -50%);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87) translate(50%, -50%);
              transform: scale(0.87) translate(50%, -50%);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1) translate(50%, -50%);
              transform: scale(1) translate(50%, -50%);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  
  
 @-webkit-keyframes heartbeat2 {
    from {
      -webkit-transform: scale(1) translate(-50%, -50%);
              transform: scale(1) translate(-50%, -50%);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91) translate(-50%, -50%);
              transform: scale(0.91) translate(-50%, -50%);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98) translate(-50%, -50%);
              transform: scale(0.98) translate(-50%, -50%);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87) translate(-50%, -50%);
              transform: scale(0.87) translate(-50%, -50%);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1) translate(-50%, -50%);
              transform: scale(1) translate(-50%, -50%);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes heartbeat2 {
    from {
      -webkit-transform: scale(1) translate(-50%, -50%);
              transform: scale(1) translate(-50%, -50%);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91) translate(-50%, -50%);
              transform: scale(0.91) translate(-50%, -50%);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98) translate(-50%, -50%);
              transform: scale(0.98) translate(-50%, -50%);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87) translate(-50%, -50%);
              transform: scale(0.87) translate(-50%, -50%);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1) translate(-50%, -50%);
              transform: scale(1) translate(-50%, -50%);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  