.pageTitle.news {
  padding-top: 50px;
  padding-bottom: 120px;
  background-color: #F8F8F8;
}
.pageTitle.news.newsDetailsPage {
  margin-top: 105px;
  background-color: var(--white);
  padding-bottom: 60px;
}
.pageTitle.news.newsDetailsPage .container .data {
  max-width: 80%;
  margin: 0 auto;
  padding-top: 0;
}
.pageTitle.news.newsDetailsPage .container .data div {
  margin-bottom: 20px;
}
.pageTitle.news.newsDetailsPage .container .data div img {
  width: auto;
  max-width: 100%;
}
.pageTitle.news.newsDetailsPage .container .data h2 {
  margin-bottom: 20px;
}
.pageTitle.news.newsDetailsPage .container .data p {
  color: var(--text);
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 32px;
}
.pageTitle.news.newsDetailsPage .container .data p a {
  color: var(--black);
  transition: 0.5s;
}
.pageTitle.news.newsDetailsPage .container .data p a:hover {
  color: var(--primary);
}
.pageTitle.news .container .data {
  padding: 50px 0px 0px 0px;
  position: relative;
}
.pageTitle.news .container .data .dots {
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
}
.pageTitle.news .container .data h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  color: var(--black);
}
.pageTitle.news .container .data h2 span {
  color: var(--primary);
}
.pageTitle.news .container .data p {
  font-size: 22px;
  line-height: 36px;
  margin: 0;
  color: var(--black);
}
.pageTitle.news .container .newsBoxes {
  margin: 50px 0 80px;
  display: grid;
  grid-gap: 40px 30px;
  grid-template-columns: 1fr 1fr 1fr;
}
.pageTitle.news .container .newsBoxes .item {
  position: relative;
}
.pageTitle.news .container .newsBoxes .item div {
  overflow: hidden;
}
.pageTitle.news .container .newsBoxes .item div img {
  width: 100%;
  transition: 0.5s;
  height: 320px;
  -o-object-fit: cover;
     object-fit: cover;
}
.pageTitle.news .container .newsBoxes .item h5 {
  width: auto;
  font-size: 30px;
  font-weight: 500;
  color: var(--black);
  margin: 20px 0 0;
  max-width: 80%;
  transition: 0.5s;
}
.pageTitle.news .container .newsBoxes .item h5 span {
  text-transform: uppercase;
  font-weight: 700;
}
.pageTitle.news .container .newsBoxes .item .link-whole {
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.pageTitle.news .container .newsBoxes .item:hover img {
  transform: scale(1.05) rotate(-1deg);
}
.pageTitle.news .container .newsBoxes .item:hover h5 {
  color: var(--primary);
}
.pageTitle.news .container .allNews {
  text-align: center;
}
.pageTitle.news .container .allNews a {
  background-color: var(--primary);
  padding: 12px 30px;
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
}
.pageTitle.news .container .allNews a:hover {
  background-color: var(--secondary);
}

@media (min-width: 1369px) and (max-width: 1600px) {
  .pageTitle.news {
    padding-bottom: 90px;
  }
  .pageTitle.news.newsDetailsPage {
    margin-top: 105px;
    background-color: var(--white);
    padding-bottom: 60px;
  }
  .pageTitle.news.newsDetailsPage .container .data {
    max-width: 80%;
  }
  .pageTitle.news.newsDetailsPage .container .data p {
    font-size: 20px;
    line-height: 32px;
  }
  .pageTitle.news .container .data img {
    width: 300px;
  }
  .pageTitle.news .container .data h2 {
    font-size: 36px;
    line-height: 45px;
  }
  .pageTitle.news .container .data p {
    font-size: 20px;
    line-height: 32px;
  }
  .pageTitle.news .container .newsBoxes {
    margin: 40px 0 50px;
    grid-gap: 0px 30px;
  }
  .pageTitle.news .container .newsBoxes .item h5 {
    margin: 20px 0;
    font-size: 20px;
  }
}
@media (min-width: 1201px) and (max-width: 1368px) {
  .pageTitle.news {
    padding-bottom: 80px;
  }
  .pageTitle.news.newsDetailsPage {
    margin-top: 105px;
    background-color: var(--white);
    padding-bottom: 60px;
  }
  .pageTitle.news.newsDetailsPage .container .data {
    max-width: 80%;
  }
  .pageTitle.news.newsDetailsPage .container .data p {
    font-size: 18px;
    line-height: 32px;
  }
  .pageTitle.news .container .data img {
    width: 280px;
  }
  .pageTitle.news .container .data h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .pageTitle.news .container .data p {
    font-size: 16px;
    line-height: 26px;
  }
  .pageTitle.news .container .newsBoxes {
    margin: 30px 0 50px;
    grid-gap: 0px 25px;
  }
  .pageTitle.news .container .newsBoxes .item div img {
    height: 240px;
  }
  .pageTitle.news .container .newsBoxes .item h5 {
    margin: 20px 0;
    font-size: 16px;
  }
  .pageTitle.news .container .allNews a {
    padding: 10px 24px;
    font-size: 14px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .pageTitle.news.newsDetailsPage {
    margin-top: 80px;
  }
  .pageTitle.news.newsDetailsPage .container .data {
    max-width: 80%;
    padding-top: 0;
  }
  .pageTitle.news.newsDetailsPage .container .data p {
    font-size: 18px;
    line-height: 32px;
  }
  .pageTitle.news .container .data img {
    width: 250px;
  }
  .pageTitle.news .container .data h2 {
    font-size: 32px;
    line-height: 42px;
  }
  .pageTitle.news .container .data p {
    font-size: 18px;
    line-height: 32px;
  }
  .pageTitle.news .container .newsBoxes {
    margin: 30px 0 90px;
    grid-gap: 0px 30px;
  }
  .pageTitle.news .container .newsBoxes .item h5 {
    font-size: 22px;
  }
}
@media (max-width: 992px) {
  .pageTitle.news.newsDetailsPage {
    margin-top: 80px;
  }
  .pageTitle.news.newsDetailsPage .container .data {
    max-width: 90%;
    padding-top: 0;
  }
  .pageTitle.news.newsDetailsPage .container .data p {
    font-size: 16px;
    line-height: 28px;
  }
  .pageTitle.news .container .data img {
    width: 220px;
  }
  .pageTitle.news .container .data h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .pageTitle.news .container .data p {
    font-size: 16px;
    line-height: 28px;
  }
  .pageTitle.news .container .newsBoxes {
    margin: 30px 0 90px;
    grid-gap: 0px 20px;
  }
  .pageTitle.news .container .newsBoxes .item h5 {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .pageTitle.news.newsDetailsPage {
    margin-top: 75px;
  }
  .pageTitle.news.newsDetailsPage .container .data {
    max-width: 100%;
    padding-top: 0;
  }
  .pageTitle.news.newsDetailsPage .container .data p {
    font-size: 16px;
    line-height: 26px;
  }
  .pageTitle.news .container .data img {
    width: 220px;
  }
  .pageTitle.news .container .data h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .pageTitle.news .container .data p {
    font-size: 16px;
    line-height: 26px;
  }
  .pageTitle.news .container .newsBoxes {
    margin: 30px 0 90px;
    grid-gap: 50px 20px;
    grid-template-columns: 1fr;
  }
  .pageTitle.news .container .newsBoxes .item h5 {
    margin-top: 15px;
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .pageTitle.news {
    padding-top: 0;
    padding-bottom: 60px;
  }
  .pageTitle.news.newsDetailsPage {
    margin-top: 75px;
  }
  .pageTitle.news.newsDetailsPage .container .data {
    max-width: 100%;
    padding-top: 20px;
  }
  .pageTitle.news .container .data img {
    width: 150px;
  }
  .pageTitle.news .container .data h2 {
    font-size: 25px;
    line-height: 32px;
  }
  .pageTitle.news .container .data .dots {
    top: unset;
  }
  .pageTitle.news .container .newsBoxes {
    margin: 30px 0 60px;
  }
  .pageTitle.news .container .allNews a {
    font-size: 16px;
  }
}/*# sourceMappingURL=news.css.map */