.siteBanner {
    position: relative;
    margin-top: 105px;
    height: 90vh;  

    .alice-carousel {
        height: 90vh;
        div {
            height: 100%;
            .alice-carousel__wrapper {
                height: 100%;
                ul {
                    li {
                        .item {
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                            .slide-content {
                                color: var(--white);
                                font-style: italic;
                                height: auto;
                                left: unset;
                                position: absolute;
                                right: 10%;
                                text-align: right;
                                top: 75%;
                                transform: translate(0%, -50%);
                                z-index: 9;
                                width: 50%;
                                white-space: initial;
        
                                h2 {
                                    font-size: 70px;
                                    line-height: 65px;
                                    text-transform: uppercase;
                                    font-weight: 700;
                                    text-shadow: 5px 5px 10px black;
                                }
        
                                span {
                                    font-size: 35px;
                                    line-height: 30px;
                                    text-transform: uppercase;
                                    font-weight: 400;
                                    text-shadow: 5px 5px 10px black;
                                }
                            }
                        }
                        // &:nth-child(2) {
                        //     .item {
                        //         .slide-content {
                        //             top: 30%;
                        //         }
                        //     }
                        // }
                    }
                }
            }
        }

        ul.alice-carousel__dots {
            position: absolute;
            bottom: 30px;
            right: 10%;
            li.alice-carousel__dots-item {
                border-radius: 5px;
                background-color: var(--white);
                margin-right: 10px;
                &.__active {
                    background-color: var(--primary);
                    width: 40px;
                }
            }
        }

        &:after {
            content: "";
            position: absolute;
            left: -270px;
            top: 0;
            background: var(--white);
            width: 710px;
            height: 100%;
            z-index: 1;
            transform: skew(-30deg);
        }
    }

    .Dots {
        position: absolute;
        bottom: 25px;
        right: 100px;
        z-index: 9;
        height: 20px;
    }

    .slanding1 {
        width: 100px;
        height: 500px;
        background-color: transparent;
        position: absolute;
        bottom: -30px;
        z-index: 9;
        transform: skew(-30deg);
        overflow: hidden;
        left: 280px;

        .animate__animated {
            height: 100%;
            .inner {
                width: 100%;
                height: 100%;
                background: var(--primary);
                position: absolute;
                left: 0;
                z-index: 999;
                top: 0;
            }

        }
    }

    .slanding2 {
        width: 100px;
        height: 350px;
        background-color: transparent;
        position: absolute;
        bottom: -30px;
        z-index: 9;
        transform: skew(-30deg);
        overflow: hidden;
        left: 336px;

        .animate__animated {
            height: 100%;
            .inner {
                width: 100%;
                height: 100%;
                background: var(--secondary);
                position: absolute;
                left: 0;
                z-index: 999;
                top: 0;
            }

        }
    }

    #line-1 {
        position: relative;
        z-index: 9;
        transform: skew(-30deg);
        img {
            &.lines {
                position: absolute;
                bottom: -30px;
                left: 140px;
                z-index: 9;
            }
        }
    }

}

@media (min-width: 1369px) and (max-width: 1600px) {
    .siteBanner {
        margin-top: 90px;
        height: 90vh; 

        .alice-carousel {
            height: 90vh;
            div {
                .alice-carousel__wrapper {
                    ul {
                        li {
                            .item {

                                .slide-content {
                                    top: 75%;
                                    right: 10%;
                                    width: 40%;
        
                                    h2 {
                                        font-size: 40px;
                                        line-height: 40px;
                                    }
        
                                    span {
                                        font-size: 30px;
                                        line-height: 30px;
                                    }
                                }
                            }
                            // &:nth-child(2) {
                            //     .item {
                            //         .slide-content {
                            //             top: 30%;
                            //         }
                            //     }
                            // }
                        }
                    }
                }
            }
    
            ul.alice-carousel__dots {
                bottom: 20px;
                right: 10%;
                li.alice-carousel__dots-item {
                    &.__active {
                        width: 40px;
                    }
                }
            }

            &:after {
                content: "";
                position: absolute;
                left: -400px;
                top: 0;
                background: var(--white);
                width: 710px;
                height: 100%;
                z-index: 1;
                transform: skew(-30deg);
            }
        }

        .Dots {
            bottom: 25px;
            right: 100px;
            height: 20px;
        }

        .slanding1 {
            bottom: -20px;
            height: 400px;
            left: 180px;
            width: 80px;
        }
    
        .slanding2 {
            bottom: -20px;
            height: 300px;
            left: 231px;
            width: 80px;
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
    .siteBanner {
        margin-top: 90px;
        height: 90vh;

        .alice-carousel {
            height: 90vh;
            div {
                .alice-carousel__wrapper {
                    ul {
                        li {
                            .item {

                                .slide-content {
                                    top: 75%;
                                    right: 100px;
                                    width: 40%;
        
                                    h2 {
                                        font-size: 36px;
                                        line-height: 36px;
                                    }
        
                                    span {
                                        font-size: 20px;
                                        line-height: 20px;
                                    }
                                }
                            }
                            // &:nth-child(2) {
                            //     .item {
                            //         .slide-content {
                            //             top: 30%;
                            //         }
                            //     }
                            // }
                        }
                    }
                }
            }
    
            ul.alice-carousel__dots {
                bottom: 15px;
                right: 10%;
                li.alice-carousel__dots-item {
                    &.__active {
                        width: 40px;
                    }
                }
            }

            &:after {
                content: "";
                position: absolute;
                left: -185px;
                top: 0;
                background: var(--white);
                width: 460px;
                height: 100%;
                z-index: 1;
                transform: skew(-30deg);
            }
        }

        .Dots {
            bottom: 25px;
            right: 100px;
            height: 20px;
        }

        .slanding1 {
            width: 60px;
            height: 390px;
            bottom: -15px;
            left: 185px;
        }
    
        .slanding2 {
            width: 60px;
            height: 320px;
            bottom: -15px;
            left: 224px;
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .siteBanner {
        margin-top: 90px;
        height: 450px;

        .alice-carousel {
            height: 450px;
            div {
                .alice-carousel__wrapper {
                    ul {
                        li {
                            .item {

                                .slide-content {
                                    top: 75%;
                                    right: 50px;
                                    width: 55%;
        
                                    h2 {
                                        font-size: 40px;
                                        line-height: 40px;
                                    }
        
                                    span {
                                        font-size: 22px;
                                        line-height: 28px;
                                    }
                                }
                            }
                            // &:nth-child(2) {
                            //     .item {
                            //         .slide-content {
                            //             top: 30%;
                            //         }
                            //     }
                            // }
                        }
                    }
                }
            }
    
            ul.alice-carousel__dots {
                bottom: 10px;
                right: 10%;
                li.alice-carousel__dots-item {
                    &.__active {
                        width: 40px;
                    }
                }
            }

            &:after {
                left: -450px;
                width: 710px;
            }
        }

        .Dots {
            bottom: 25px;
            right: 100px;
            height: 20px;
        }

        .slanding1 {
            width: 70px;
            height: 320px;
            bottom: -15px;
            left: 190px;
        }
    
        .slanding2 {
            width: 70px;
            height: 260px;
            bottom: -15px;
            left: 242px;
        }
    }
}

@media (max-width: 992px) {
    .siteBanner {
        margin-top: 80px;
        height: 450px;

        .alice-carousel {
            height: 450px;
            div {
                .alice-carousel__wrapper {
                    ul {
                        li {
                            .item {

                                .slide-content {
                                    top: 75%;
                                    right: 20px;
                                    width: 58%;
        
                                    h2 {
                                        font-size: 30px;
                                        line-height: 30px;
                                    }
        
                                    span {
                                        font-size: 20px;
                                        line-height: 24px;
                                    }
                                }
                            }
                            // &:nth-child(2) {
                            //     .item {
                            //         .slide-content {
                            //             top: 30%;
                            //         }
                            //     }
                            // }
                        }
                    }
                }
            }
    
            ul.alice-carousel__dots {
                bottom: 10px;
                right: 5%;
                li.alice-carousel__dots-item {
                    margin-right: 5px;
                    &.__active {
                        width: 8px;
                    }
                }
            }

            &:after {
                left: -480px;
                width: 650px;
            }
        }

        .Dots {
            bottom: 25px;
            right: 100px;
            height: 20px;
        }

        .slanding1 {
            width: 70px;
            height: 300px;
            bottom: -20px;
            left: 100px;
        }
    
        .slanding2 {
            width: 70px;
            height: 220px;
            bottom: -20px;
            left: 146px;
        }
    }
}

@media (max-width: 767px) {
    .siteBanner {
        margin-top: 75px;
        height: 400px;

        .alice-carousel {
            height: 400px;
            div {
                .alice-carousel__wrapper {
                    ul {
                        li {
                            .item {

                                .slide-content {
                                    top: 75%;
                                    right: 0px;
                                    transform: translate(-10%, -50%);
                                    width: 50%;
        
                                    h2 {
                                        font-size: 25px;
                                        line-height: 25px;
                                    }
        
                                    span {
                                        font-size: 18px;
                                        line-height: 24px;
                                    }
                                }
                            }
                            // &:nth-child(2) {
                            //     .item {
                            //         .slide-content {
                            //             top: 30%;
                            //         }
                            //     }
                            // }
                        }
                    }
                }
            }
    
            ul.alice-carousel__dots {
                bottom: 10px;
                right: 5%;
                li.alice-carousel__dots-item {
                    &.__active {
                        width: 35px;
                    }
                }
            }

            &:after {
                left: -240px;
                width: 400px;
            }
        }

        .Dots {
            bottom: 25px;
            right: 100px;
            height: 20px;
        }
        .slanding1 {
            width: 70px;
            height: 275px;
            bottom: -15px;
            left: 100px;
        }
        .slanding2 {
            width: 50px;
            height: 200px;
            bottom: -15px;
            left: 148px;
        }
    }
}

@media (max-width: 600px) {
    .siteBanner {
        margin-top: 75px;
        height: 300px;

        .alice-carousel {
            height: 300px;
            div {
                .alice-carousel__wrapper {
                    ul {
                        li {
                            .item {

                                .slide-content {
                                    top: 75%;
                                    right: 0px;
                                    width: 60%;
        
                                    h2 {
                                        font-size: 20px;
                                        line-height: 20px;
                                    }
        
                                    span {
                                        font-size: 16px;
                                        line-height: 20px;
                                    }
                                }
                            }
                            // &:nth-child(2) {
                            //     .item {
                            //         .slide-content {
                            //             top: 30%;
                            //         }
                            //     }
                            // }
                        }
                    }
                }
            }
    
            ul.alice-carousel__dots {
                bottom: 5px;
                right: 10px;
                li.alice-carousel__dots-item {
                    margin-right: 5px;
                    &.__active {
                        width: 8px;
                    }
                }
            }

            &:after {
                left: -275px;
                width: 350px;
                transform: skew(-20deg);
            }
        }

        .Dots {
            bottom: 25px;
            right: 100px;
            height: 20px;
        }
        .slanding1 {
            width: 35px;
            height: 175px;
            bottom: -5px;
            left: 35px;
            transform: skew(-20deg);
        }
        .slanding2 {
            width: 35px;
            height: 130px;
            bottom: -5px;
            left: 61px;
            transform: skew(-20deg);
        }
    }
}


.kenburns-top {
	-webkit-animation: kenburns-top 5s ease-out infinite both;
	        animation: kenburns-top 5s ease-out infinite both;
}

 @-webkit-keyframes kenburns-top {
    0% {
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 16%;
              transform-origin: 50% 16%;
    }
    100% {
      -webkit-transform: scale(1.05) translateY(-15px);
              transform: scale(1.05) translateY(-15px);
      -webkit-transform-origin: top;
              transform-origin: top;
    }
  }
  @keyframes kenburns-top {
    0% {
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 16%;
              transform-origin: 50% 16%;
    }
    100% {
      -webkit-transform: scale(1.05) translateY(-15px);
              transform: scale(1.05) translateY(-15px);
      -webkit-transform-origin: top;
              transform-origin: top;
    }
  }

  .kenburns-bottom {
	-webkit-animation: kenburns-bottom 2.8s infinite both;
	        animation: kenburns-bottom 2.8s infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-9-30 12:20:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes kenburns-bottom {
    0% {
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 84%;
              transform-origin: 50% 84%;
    }
    100% {
      -webkit-transform: scale(1.05) translateY(0px);
              transform: scale(1.05) translateY(0px);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
    }
  }
  @keyframes kenburns-bottom {
    0% {
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 84%;
              transform-origin: 50% 84%;
    }
    100% {
      -webkit-transform: scale(1.05) translateY(0px);
              transform: scale(1.05) translateY(0px);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
    }
  }
  
  

.overflow-hidden {
    overflow: hidden;
}




.pulsate-fwd {
	-webkit-animation: pulsate-fwd 6s ease-in-out infinite both;
	        animation: pulsate-fwd 6s ease-in-out infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-9-30 12:35:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    } 
    50% {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  