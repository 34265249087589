.pageSubTitle.benefitsList {
    .container {
        .data {
            ul {
                &.gridBox {
                    display: grid;
                    grid-gap: 80px 25px;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    text-align: center;
                    margin-top: 100px;
                    padding: 0;

                    li {
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        flex-direction: column;
                        background-color: rgb(250, 250, 250);
                        padding: 80px 20px 20px;
                        position: relative;
                        border-radius: 5px;

                        .img {
                            width: 100px;
                            height: 100px;
                            border-radius: 100px;
                            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            top: -50px;
                            background-color: var(--white);

                            img {
                                width: 50px;
                                height: 50px;
                                object-fit: contain;
                            }
                        }

                        p {
                            font-weight: 600;
                            color: var(--black);
                            font-size: 18px;
                            line-height: 23px;
                        }
                    }
                }
            }
        }
    }
}

section.icon-box.content-image {
    .container {
        .data {
            .image {
                width: 45%;
                justify-content: start;
                padding: 0;
                align-items: start;
                padding: 0 50px;

                img {}
            }

            .content.pageContent {
                width: 55%;
                justify-content: start;
                padding: 0;
                text-align: left;

                .accordion {
                    width: 100%;

                    .accordion-item {
                        border: 0;

                        .accordion-header {
                            button {
                                font-size: 20px;
                                font-weight: 600;
                                font-style: italic;
                                padding-left: 0;
                                padding-right: 0;
                                background-color: var(--white);
                                box-shadow: unset;
                                color: var(--black);
                            }
                        }

                        .accordion-body {
                            font-size: 18px;
                            padding: 0 0 20px;
                        }
                    }
                }
            }
            &.flex-direction-column {
                flex-direction: column;
                .content.pageContent {
                    width: 100%;
                }
            }
        }
    }
}

.workCarousel {
    margin-bottom: 80px;

    .alice-carousel {
        width: 90%;
        background-color: var(--white);
        margin: 0px 0px 0 auto;

        .alice-carousel__wrapper {
            ul.alice-carousel__stage {
                padding: 0;

                li.alice-carousel__stage-item {
                    .item {
                        text-align: center;
                        padding: 20px;
                        display: flex;
                        gap: 20px;
                        align-items: start;

                        div {
                            width: 55%;
                            h4 {
                                font-size: 24px;
                                line-height: 32px;
                                font-weight: 600;
                                color: var(--black);
                                margin: 20px 0 20px;
                                text-align: left;
                            }

                            p {
                                text-align: left;
                                margin-bottom: 0;
                            }
                        }
                        img {
                            max-height: 400px;
                            width: 45%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        ul.alice-carousel__dots {
            padding: 0;
            text-align: center;
            margin: 20px 0;

            li.alice-carousel__dots-item {
                margin-right: 10px;
                background-color: rgba(217, 217, 217, 1);

                &.__active {
                    background-color: var(--primary);
                    width: 35px;
                    border-radius: 5px;
                }
            }
        }

        .alice-carousel__prev-btn {
            padding: 0;
            width: auto;
            position: absolute;
            left: -140px;
            bottom: 10px;

            p {
                color: var(--black);
                font-size: 35px;
                font-style: normal;
                padding: 0;
                line-height: 20px;
            }

            &:hover {
                p {
                    color: var(--primary);
                }
            }
        }

        .alice-carousel__next-btn {
            padding: 0;
            width: auto;
            position: absolute;
            left: -100px;
            bottom: 10px;

            p {
                color: var(--black);
                font-size: 35px;
                font-style: normal;
                padding: 0;
                line-height: 20px;
            }

            &:hover {
                p {
                    color: var(--primary);
                }
            }
        }
    }
}

section.half-half.content-image {
    padding: 120px 0;
    .container {
        .data {
            display: flex;
            flex-direction: row-reverse;
            .image {
                width: 50%;
                justify-content: center;
                padding: 0;
                align-items: center;
                padding: 0 50px;

                img {
                    width: 100%;
                }
            }

            .content.pageContent {
                width: 60%;
                padding: 40px;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                h2 {
                    font-size: 30px;
                    font-style: italic;
                    font-weight: 700;
                    line-height: 53.16px;
                    color: var(--black);
                }
                p {
    
                }
                ul {
                    padding-left: 20px;
                    text-align: left;
                    li {
                        list-style-type: disc;
                    }
                }
            }
        }
    }
}

.abrecoTestimonials {
    margin-top: 50px;
    background: #F8F8F8;
    padding: 100px 0;
    .container {
        .data {
            .textTestimonials {
                margin-bottom: 100px;
                .alice-carousel {
                    div {
                        .alice-carousel__wrapper {
                            ul {
                                li {
                                    .item {
                                        text-align: center;
                                        max-width: 70%;
                                        margin: 0 auto;
                                        img {
                                            width: 175px !important;
                                            border-radius: 50px 70px 50px 70px;
                                            margin-bottom: 50px;
                                        }
                                        p {
                                            color: var(--black);
                                        }
                                        h4 {
                                            color: var(--black);
                                            font-weight: 600;
                                            font-size: 24px;
                                            text-transform: capitalize;
                                        }
                                        small {
                                            color: var(--black);
                                            text-transform: capitalize;
                                        }
                                        &:after {
                                            content: '';
                                            background: url('../../images/testomonial-quote.webp');
                                            background-size: contain;
                                            background-repeat: no-repeat;
                                            width: 280px;
                                            height: 280px;
                                            position: absolute;
                                            top: 110px;
                                            left: 50%;
                                            transform: translate(-50%, 0%);
                                            z-index: -1;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    ul.alice-carousel__dots {
                        bottom: 30px;
                        li.alice-carousel__dots-item {
                            border-radius: 5px;
                            background-color: #ccc;
                            margin-right: 10px;
                            &.__active {
                                background-color: var(--primary);
                                width: 40px;
                            }
                        }
                    }
                }
            }
            .videoTestimonials {
                .alice-carousel {
                    div {
                        .alice-carousel__wrapper {
                            ul {
                                li {
                                    .item {
                                        text-align: center;
                                        max-width: 70%;
                                        margin: 0 auto;
                                        img {
                                            width: 200px !important;
                                            border-radius: 50px 70px 50px 70px;
                                            margin-bottom: 50px;
                                        }
                                        p {
                                            color: var(--black);
                                        }
                                        h4 {
                                            color: var(--black);
                                            font-weight: 600;
                                            font-size: 24px;
                                        }
                                        small {
                                            color: var(--black);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    ul.alice-carousel__dots {
                        bottom: 30px;
                        li.alice-carousel__dots-item {
                            border-radius: 5px;
                            background-color: #ccc;
                            margin-right: 10px;
                            &.__active {
                                background-color: var(--primary);
                                width: 40px;
                            }
                        }
                    }
                }
            }
            .link {
                text-align: center;
                margin-top: 100px;
                a {
                    background-color: var(--primary);
                    color: var(--white);
                    padding: 12px 30px;
                    font-weight: 500;
                    text-transform: uppercase;
                    &:hover {
                        background-color: var(--secondary);
                    }
                }
            }
        }
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {
	.pageSubTitle.benefitsList {
        .container {
            .data {
                ul {
                    &.gridBox {
                        grid-gap: 90px 25px;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    
                        li {
                            padding: 50px 20px 20px;
    
                            .img {
                                width: 80px;
                                height: 80px;
                                top: -50px;
    
                                img {
                                    width: 40px;
                                    height: 40px;
                                }
                            }
                            p {
                                font-size: 18px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }

    section.icon-box.content-image {
        .container {
            .data {    
                .content.pageContent {    
                    .accordion {
                        .accordion-item {
                            .accordion-header {
                                button {
                                    font-size: 20px;
                                }
                            }    
                            .accordion-body {
                                font-size: 17px;
                            }
                        }
                    }
                }
            }
        }
    }

    section.half-half.content-image {
        padding: 100px 0;
        .container {
            .data {
                .image {
                    width: 50%;
                    padding: 0 50px;
                }
    
                .content.pageContent {
                    width: 60%;
                    padding: 0 40px;
                    h2 {
                        font-size: 30px;
                        line-height: 40px;
                    }
                    ul {
                        padding-left: 20px;
                    }
                }
            }
        }
    }

    .abrecoTestimonials {
        margin-top: 50px;
        padding: 80px 0;
        .container {
            .data {
                .textTestimonials {
                    margin-bottom: 100px;
                    .alice-carousel {
                        div {
                            .alice-carousel__wrapper {
                                ul {
                                    li {
                                        .item {
                                            max-width: 70%;
                                            img {
                                                width: 150px !important;
                                                border-radius: 40px 60px 40px 60px;
                                                margin-bottom: 50px;
                                            }
                                            h4 {
                                                font-size: 24px;
                                            }
                                            &:after {
                                                width: 250px;
                                                height: 250px;
                                                top: 110px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                        ul.alice-carousel__dots {
                            bottom: 30px;
                        }
                    }
                }
                .videoTestimonials {
                    .alice-carousel {
                        div {
                            .alice-carousel__wrapper {
                                ul {
                                    li {
                                        .item {
                                            max-width: 70%;
                                            img {
                                                width: 200px !important;
                                                border-radius: 50px 70px 50px 70px;
                                                margin-bottom: 50px;
                                            }
                                            h4 {
                                                font-size: 24px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                        ul.alice-carousel__dots {
                            bottom: 30px;
                        }
                    }
                }
                .link {
                    margin-top: 80px;
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
	.pageSubTitle.benefitsList {
        .container {
            .data {
                ul {
                    &.gridBox {
                        grid-gap: 80px 20px;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        margin-top: 100px;
    
                        li {
                            padding: 50px 20px 20px;
    
                            .img {
                                width: 80px;
                                height: 80px;
                                top: -50px;
    
                                img {
                                    width: 35px;
                                    height: 35px;
                                }
                            }
                            p {
                                font-size: 18px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }

    section.icon-box.content-image {
        .container {
            .data {    
                .content.pageContent {    
                    .accordion {
                        .accordion-item {
                            .accordion-header {
                                button {
                                    font-size: 18px;
                                }
                            }    
                            .accordion-body {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .workCarousel {
        margin-bottom: 60px;    
        .alice-carousel {
            width: 90%;
            margin: 50px 0px 0 auto;    
            .alice-carousel__wrapper {
                ul.alice-carousel__stage {
                    padding: 0;    
                    li.alice-carousel__stage-item {
                        .item {
                            padding: 20px;    
                            h4 {
                                font-size: 22px;
                                line-height: 32px;
                                margin: 20px 0 20px;
                            }
                        }
                    }
                }
            }    
            ul.alice-carousel__dots {
                margin: 20px 0;    
                li.alice-carousel__dots-item {
                    margin-right: 10px;    
                    &.__active {
                        width: 35px;
                    }
                }
            }
    
            .alice-carousel__prev-btn {
                left: -140px;
    
                p {
                    font-size: 35px;
                    line-height: 20px;
                }
            }
    
            .alice-carousel__next-btn {
                left: -100px;
    
                p {
                    font-size: 35px;
                    line-height: 20px;
                }
            }
        }
    }

    section.half-half.content-image {
        padding: 80px 0;
        .container {
            .data {    
                .content.pageContent {
                    h2 {
                        font-size: 28px;
                        line-height: 36px;
                    }
                }
            }
        }
    }

    .abrecoTestimonials {
        margin-top: 40px;
        padding: 70px 0;
        .container {
            .data {
                .textTestimonials {
                    margin-bottom: 80px;
                    .alice-carousel {
                        div {
                            .alice-carousel__wrapper {
                                ul {
                                    li {
                                        .item {
                                            max-width: 70%;
                                            img {
                                                width: 130px !important;
                                                border-radius: 35px 55px 35px 55px;
                                                margin-bottom: 40px;
                                            }
                                            h4 {
                                                font-size: 22px;
                                            }
                                            small {
                                                font-size: 18px;
                                            }
                                            &:after {
                                                width: 230px;
                                                height: 230px;
                                                top: 80px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .videoTestimonials {
                    .alice-carousel {
                        div {
                            .alice-carousel__wrapper {
                                ul {
                                    li {
                                        .item {
                                            max-width: 70%;
                                            img {
                                                width: 180px !important;
                                                border-radius: 50px 70px 50px 70px;
                                                margin-bottom: 40px;
                                            }
                                            h4 {
                                                font-size: 22px;
                                            }
                                            small {
                                                font-size: 18px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .link {
                    margin-top: 70px;
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
	.pageSubTitle.benefitsList {
        .container {
            .data {
                ul {
                    &.gridBox {
                        grid-gap: 80px 20px;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
    
                        li {
                            padding: 50px 20px 20px;
    
                            .img {
                                width: 80px;
                                height: 80px;
                                top: -50px;
    
                                img {
                                    width: 35px;
                                    height: 35px;
                                }
                            }
                            p {
                                font-size: 18px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }

    section.icon-box.content-image {
        .container {
            .data {    
                .content.pageContent {    
                    .accordion {
                        .accordion-item {
                            .accordion-header {
                                button {
                                    font-size: 18px;
                                }
                            }    
                            .accordion-body {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .workCarousel {
        margin-bottom: 60px;    
        .alice-carousel {
            width: 90%;
            margin: 50px 0px 0 auto;    
            .alice-carousel__wrapper {
                ul.alice-carousel__stage {
                    padding: 0;    
                    li.alice-carousel__stage-item {
                        .item {
                            padding: 20px;    
                            h4 {
                                font-size: 22px;
                                line-height: 32px;
                                margin: 20px 0 20px;
                            }
                        }
                    }
                }
            }    
            ul.alice-carousel__dots {
                margin: 20px 0;    
                li.alice-carousel__dots-item {
                    margin-right: 10px;    
                    &.__active {
                        width: 35px;
                    }
                }
            }
    
            .alice-carousel__prev-btn {
                left: -100px;
    
                p {
                    font-size: 35px;
                    line-height: 20px;
                }
            }
    
            .alice-carousel__next-btn {
                left: -50px;
    
                p {
                    font-size: 35px;
                    line-height: 20px;
                }
            }
        }
    }

    section.half-half.content-image {
        padding: 70px 0;
        .container {
            .data {
                .image {
                    width: 50%;
                    padding: 0 0px;
                }
    
                .content.pageContent {
                    width: 60%;
                    padding: 0 30px;
                    h2 {
                        font-size: 26px;
                        line-height: 35px;
                    }
                }
            }
        }
    }

    .abrecoTestimonials {
        margin-top: 60px;
        padding: 60px 0;
        .container {
            .data {
                .textTestimonials {
                    margin-bottom: 80px;
                    .alice-carousel {
                        div {
                            .alice-carousel__wrapper {
                                ul {
                                    li {
                                        .item {
                                            max-width: 80%;
                                            img {
                                                width: 120px !important;
                                                border-radius: 30px 45px 30px 45px;
                                                margin-bottom: 40px;
                                            }
                                            h4 {
                                                font-size: 22px;
                                            }
                                            small {
                                                font-size: 18px;
                                            }
                                            &:after {
                                                width: 210px;
                                                height: 210px;
                                                top: 80px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .videoTestimonials {
                    .alice-carousel {
                        div {
                            .alice-carousel__wrapper {
                                ul {
                                    li {
                                        .item {
                                            max-width: 80%;
                                            img {
                                                width: 160px !important;
                                                border-radius: 30px 50px 30px 50px;
                                                margin-bottom: 40px;
                                            }
                                            h4 {
                                                font-size: 22px;
                                            }
                                            small {
                                                font-size: 18px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .link {
                    margin-top: 60px;
                }
            }
        }
    }
}

@media (max-width: 992px) {
	.pageSubTitle.benefitsList {
        .container {
            .data {
                ul {
                    &.gridBox {
                        grid-gap: 80px 20px;
                        grid-template-columns: 1fr 1fr 1fr;
    
                        li {
                            padding: 50px 20px 20px;
    
                            .img {
                                width: 80px;
                                height: 80px;
                                top: -50px;
    
                                img {
                                    width: 35px;
                                    height: 35px;
                                }
                            }
                            p {
                                font-size: 16px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }

    section.icon-box, section.icon-box.content-image {
        .container {
            .data { 
                flex-direction: column;
                .image {
                    width: 100%;
                    padding: 0;
                    margin-bottom: 0px;
                    align-items: center;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }   
                .content, .content.pageContent {    
                    width: 100%;
                    .accordion {
                        .accordion-item {
                            .accordion-header {
                                button {
                                    font-size: 18px;
                                }
                            }    
                            .accordion-body {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .workCarousel {
        margin-bottom: 60px;    
        .alice-carousel {
            width: 100%;
            margin: 30px 0px 0 0;
            // background-color: #f8f8f8;  
            .alice-carousel__wrapper {
                ul.alice-carousel__stage {
                    padding: 0;    
                    li.alice-carousel__stage-item {
                        .item {
                            padding: 20px;  
                            div {
                                width: 50%;
                                h4 {
                                    font-size: 22px;
                                    line-height: 32px;
                                    margin: 20px 0 20px;
                                }
                            }
                            img {
                                width: 50%;
                                object-fit: unset;
                                max-height: unset;
                            }
                        }
                    }
                }
            }    
            ul.alice-carousel__dots {
                margin: 20px 0;   
                text-align: right !important; 
                li.alice-carousel__dots-item {
                    margin-right: 8px;    
                    &.__active {
                        width: 8px;
                    }
                }
            }
    
            .alice-carousel__prev-btn {
                left: 20px;
    
                p {
                    font-size: 35px;
                    line-height: 20px;
                }
            }
    
            .alice-carousel__next-btn {
                left: 60px;
    
                p {
                    font-size: 35px;
                    line-height: 20px;
                }
            }
        }
    }

    section.half-half.content-image {
        padding: 60px 0;
        .container {
            .data {
                flex-direction: column-reverse;
                .image {
                    width: 100%;
                    padding: 0 0px;
                }
    
                .content.pageContent {
                    width: 100%;
                    padding: 0 30px;
                    margin-bottom: 20px;
                    h2 {
                        font-size: 26px;
                        line-height: 35px;
                    }
                    p {
                        br {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .abrecoTestimonials {
        margin-top: 60px;
        padding: 60px 0;
        .container {
            .data {
                .textTestimonials {
                    margin-bottom: 80px;
                    .alice-carousel {
                        div {
                            .alice-carousel__wrapper {
                                ul {
                                    li {
                                        .item {
                                            max-width: 80%;
                                            img {
                                                width: 110px !important;
                                                border-radius: 30px 50px 30px 50px;
                                                margin-bottom: 30px;
                                            }
                                            h4 {
                                                font-size: 22px;
                                            }
                                            small {
                                                font-size: 18px;
                                            }
                                            &:after {
                                                width: 200px;
                                                height: 200px;
                                                top: 80px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .videoTestimonials {
                    .alice-carousel {
                        div {
                            .alice-carousel__wrapper {
                                ul {
                                    li {
                                        .item {
                                            max-width: 80%;
                                            img {
                                                width: 160px !important;
                                                border-radius: 30px 50px 30px 50px;
                                                margin-bottom: 40px;
                                            }
                                            h4 {
                                                font-size: 22px;
                                            }
                                            small {
                                                font-size: 18px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .link {
                    margin-top: 60px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
	.pageSubTitle.benefitsList {
        .container {
            .data {
                ul {
                    &.gridBox {
                        grid-gap: 80px 20px;
                        grid-template-columns: 1fr 1fr;
    
                        li {
                            padding: 50px 15px 20px;
                        }
                    }
                }
            }
        }
    }

    .pageSubTitle.lifePageTitle {
        padding-bottom: 0;
    }

    section.icon-box.content-image {
        padding-top: 0;
        .container {
            .data {    
                .image {
                    img {
                        width: 100%;
                    }
                }
                .content.pageContent {    
                    .accordion {
                        .accordion-item {
                            .accordion-header {
                                button {
                                    font-size: 17px;
                                }
                            }    
                            .accordion-body {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .workCarousel {
        .alice-carousel {
            width: 100%;
            .alice-carousel__wrapper {
                ul.alice-carousel__stage {
                    li.alice-carousel__stage-item {
                        .item {
                            flex-direction: column-reverse;
                            div {
                                width: 100%;
                                h4 {
                                    font-size: 20px;
                                    line-height: 28px;
                                    margin-top: 0;
                                }
                                p {
                                    line-height: 28px;
                                }
                            }
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .abrecoTestimonials {
        margin-top: 50px;
        .container {
            .data {
                .textTestimonials {
                    margin-bottom: 60px;
                    .alice-carousel {
                        div {
                            .alice-carousel__wrapper {
                                ul {
                                    li {
                                        .item {
                                            max-width: 100%;
                                            img {
                                                width: 120px !important;
                                                border-radius: 25px 45px 25px 45px;
                                                margin-bottom: 30px;
                                            }
                                            h4 {
                                                margin-top: 20px;
                                                margin-bottom: 0;
                                                font-size: 18px;
                                            }
                                            small {
                                                font-size: 16px;
                                            }
                                            &:after {
                                                width: 180px;
                                                height: 180px;
                                                top: 80px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .videoTestimonials {
                    .alice-carousel {
                        div {
                            .alice-carousel__wrapper {
                                ul {
                                    li {
                                        .item {
                                            max-width: 100%;
                                            iframe {
                                                height: 300px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
	.pageSubTitle.benefitsList {
        .container {
            .data {
                ul {
                    &.gridBox {
                        grid-gap: 80px 20px;
                        grid-template-columns: 1fr 1fr;
    
                        li {    
                            .img {    
                                img {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .abrecoTestimonials {
        .container {
            .data {
                .textTestimonials {
                    .alice-carousel {
                        div {
                            .alice-carousel__wrapper {
                                ul {
                                    li {
                                        .item {
                                            img {
                                                width: 100px !important;
                                                border-radius: 20px 40px 20px 40px;
                                            }
                                            h4 {
                                                margin-top: 20px;
                                                margin-bottom: 0;
                                                font-size: 18px;
                                            }
                                            small {
                                                font-size: 16px;
                                            }
                                            &:after {
                                                width: 130px;
                                                height: 180px;
                                                top: 80px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ul.alice-carousel__dots {
                            margin-top: 10px;
                            li.alice-carousel__dots-item {
                                margin-right: 8px;    
                                &.__active {
                                    width: 8px;
                                }
                            }
                        }
                    }
                }
                .videoTestimonials {
                    .alice-carousel {
                        div {
                            .alice-carousel__wrapper {
                                ul {
                                    li {
                                        .item {
                                            img {
                                                width: 120px !important;
                                            }
                                            h4 {
                                                margin-top: 20px;
                                                margin-bottom: 0;
                                                font-size: 18px;
                                            }
                                            small {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ul.alice-carousel__dots {
                            margin-top: 10px;
                            li.alice-carousel__dots-item {
                                margin-right: 8px;    
                                &.__active {
                                    width: 8px;
                                }
                            }
                        }
                    }
                }
                .link {
                    a {
                        &:hover {
                            background-color: var(--primary);
                        }
                    }
                }
            }
        }
    }
}