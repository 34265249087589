.pageTitle.csr-activities .locationsBoxes {
  margin-bottom: 0;
}
.pageTitle.csr-activities .locationsBoxes .item p {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  color: var(--black);
  margin: 20px 0;
  transition: 0.5s;
}
.pageTitle.csr-activities .locationsBoxes .item .link-whole {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  cursor: pointer;
}
.pageTitle.csr-activities .locationsBoxes .item:hover p {
  color: var(--primary);
}

.content.related-blog {
  padding: 60px 0;
  background-color: rgb(29, 37, 44);
}
.content.related-blog .data.no-padding {
  padding-top: 0;
}
.content.related-blog .data.no-padding p {
  color: var(--white);
}
.content.related-blog .data.no-padding h2 {
  color: var(--white);
}
.content.related-blog .blog-wrapr {
  display: grid;
  grid-gap: 80px 25px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.content.related-blog .blog-wrapr .item {
  position: relative;
}
.content.related-blog .blog-wrapr .item div {
  overflow: hidden;
  border-radius: 5px;
}
.content.related-blog .blog-wrapr .item div img {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.5s;
}
.content.related-blog .blog-wrapr .item h4 {
  color: var(--white);
  transition: 0.5s;
  padding-top: 10px;
  font-size: 20px;
}
.content.related-blog .blog-wrapr .item .link-whole {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.content.related-blog .blog-wrapr .item:hover div img {
  transform: scale(1.02);
}
.content.related-blog .blog-wrapr .item:hover h4 {
  color: var(--primary);
}

@media (min-width: 1369px) and (max-width: 1600px) {
  .content.related-blog .blog-wrapr .item div img {
    height: 200px;
  }
  .content.related-blog .blog-wrapr .item h4 {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (min-width: 1201px) and (max-width: 1368px) {
  .content.related-blog .blog-wrapr .item div img {
    height: 200px;
  }
  .content.related-blog .blog-wrapr .item h4 {
    font-size: 18px;
    line-height: 26px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .content.related-blog .blog-wrapr {
    grid-gap: 80px 20px;
  }
  .content.related-blog .blog-wrapr .item div img {
    height: 150px;
  }
  .content.related-blog .blog-wrapr .item h4 {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 992px) {
  .content.related-blog {
    padding: 50px 0;
  }
  .content.related-blog .blog-wrapr {
    grid-gap: 20px 20px;
    grid-template-columns: 1fr 1fr;
  }
  .content.related-blog .blog-wrapr .item div img {
    height: 200px;
  }
  .content.related-blog .blog-wrapr .item h4 {
    font-size: 16px;
    line-height: 24px;
  }
  .pageTitle.csr-activities {
    padding-top: 0;
  }
  .pageTitle.csr-activities .locationsBoxes .item p {
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    margin: 20px 0 10px;
  }
}
@media (max-width: 767px) {
  .content.related-blog {
    padding: 50px 0;
  }
  .content.related-blog .blog-wrapr {
    grid-gap: 20px 20px;
    grid-template-columns: 1fr 1fr;
  }
  .content.related-blog .blog-wrapr .item div img {
    height: 180px;
  }
  .content.related-blog .blog-wrapr .item h4 {
    font-size: 16px;
    line-height: 24px;
  }
  .pageTitle.csr-activities {
    margin-bottom: 50px;
    padding-top: 30px;
  }
  .pageTitle.csr-activities .locationsBoxes .item p {
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    margin: 20px 0 10px;
  }
}
@media (max-width: 600px) {
  .content.related-blog {
    padding: 40px 0;
  }
  .content.related-blog .blog-wrapr {
    grid-gap: 20px 10px;
    grid-template-columns: 1fr 1fr;
  }
  .content.related-blog .blog-wrapr .item div img {
    height: 150px;
  }
  .content.related-blog .blog-wrapr .item h4 {
    font-size: 14px;
    line-height: 20px;
  }
  .pageTitle.csr-activities {
    margin-bottom: 50px;
  }
  .pageTitle.csr-activities .locationsBoxes .item p {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    margin: 20px 0 10px;
  }
}/*# sourceMappingURL=csr.css.map */