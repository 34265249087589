.pageTitle.timeline {
  padding-top: 50px;
  padding-bottom: 120px;
}
.pageTitle.timeline .steps-timeline {
  margin-top: 70px;
  font-size: 14px;
  line-height: 16px;
  position: relative;
}
.pageTitle.timeline .steps-timeline:before {
  content: "";
  width: 175px;
  height: 100px;
  background: url(../../images/arrow.webp);
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 9;
  transform: translate(0%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.pageTitle.timeline .steps-timeline:after {
  content: "";
  width: 175px;
  height: 100px;
  background: url(../../images/arrow.webp);
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 9;
  transform: translate(0%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.pageTitle.timeline .steps-timeline .step {
  outline: 1px dashed rgba(0, 128, 0, 0);
  margin-bottom: 0px;
  position: relative;
  padding: 240px 0;
}
.pageTitle.timeline .steps-timeline .step .line {
  height: 240px;
  position: absolute;
  left: 100px;
  width: 1px;
}
.pageTitle.timeline .steps-timeline .step .line:after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100px;
  position: absolute;
  left: -5px;
}
.pageTitle.timeline .steps-timeline .step .step-milestone {
  display: flex;
  width: 100%;
  height: 100px;
  border-radius: 0;
  margin: 0px auto;
  box-sizing: border-box;
  color: var(--white);
  text-align: center;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 500;
  padding-left: 50px;
}
.pageTitle.timeline .steps-timeline .step .step-milestone:after {
  content: "";
  width: 30px;
  height: 100px;
  border-bottom: solid 50px transparent;
  border-top: solid 50px transparent;
  border-left: solid 50px transparent;
  display: block;
  position: absolute;
  top: 0;
  right: -50px;
  z-index: 99;
}
.pageTitle.timeline .steps-timeline .step .step-milestone:before {
  content: "";
  width: 30px;
  height: 100px;
  border-left: solid 50px var(--white);
  border-bottom: solid 50px transparent;
  border-top: solid 50px transparent;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.pageTitle.timeline .steps-timeline .step .stepData {
  position: absolute;
  width: 300px;
  left: 125px;
  height: 240px;
  padding: 20px 0;
}
.pageTitle.timeline .steps-timeline .step .stepData img {
  width: 40px;
}
.pageTitle.timeline .steps-timeline .step .stepData .step-title,
.pageTitle.timeline .steps-timeline .step .stepData .step-description {
  margin: 0;
  display: block;
}
.pageTitle.timeline .steps-timeline .step .stepData .step-title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  font-weight: bold;
  margin: 15px 0;
  text-transform: uppercase;
}
.pageTitle.timeline .steps-timeline .step .stepData .step-description {
  overflow: hidden;
  font-size: 15px;
  line-height: 22px;
}
.pageTitle.timeline .steps-timeline .step:nth-child(1) .line {
  top: 0;
  background-color: #014E74;
}
.pageTitle.timeline .steps-timeline .step:nth-child(1) .line:after {
  background-color: #014E74;
  top: -5px;
}
.pageTitle.timeline .steps-timeline .step:nth-child(1) .step-milestone {
  background-color: #014E74;
}
.pageTitle.timeline .steps-timeline .step:nth-child(1) .step-milestone:after {
  border-left-color: #014E74;
}
.pageTitle.timeline .steps-timeline .step:nth-child(1) .stepData {
  top: 0;
}
.pageTitle.timeline .steps-timeline .step:nth-child(1) .stepData .step-title {
  color: #014E74;
}
.pageTitle.timeline .steps-timeline .step:nth-child(2) .line {
  bottom: 0;
  background-color: #047FBB;
}
.pageTitle.timeline .steps-timeline .step:nth-child(2) .line:after {
  background-color: #047FBB;
  bottom: -5px;
}
.pageTitle.timeline .steps-timeline .step:nth-child(2) .step-milestone {
  background-color: #047FBB;
}
.pageTitle.timeline .steps-timeline .step:nth-child(2) .step-milestone:after {
  border-left-color: #047FBB;
}
.pageTitle.timeline .steps-timeline .step:nth-child(2) .stepData {
  bottom: 0;
}
.pageTitle.timeline .steps-timeline .step:nth-child(2) .stepData .step-title {
  color: #047FBB;
}
.pageTitle.timeline .steps-timeline .step:nth-child(3) .line {
  top: 0;
  background-color: #FF3E60;
}
.pageTitle.timeline .steps-timeline .step:nth-child(3) .line:after {
  background-color: #FF3E60;
  top: -5px;
}
.pageTitle.timeline .steps-timeline .step:nth-child(3) .step-milestone {
  background-color: #FF3E60;
}
.pageTitle.timeline .steps-timeline .step:nth-child(3) .step-milestone:after {
  border-left-color: #FF3E60;
}
.pageTitle.timeline .steps-timeline .step:nth-child(3) .stepData {
  top: 0;
}
.pageTitle.timeline .steps-timeline .step:nth-child(3) .stepData .step-title {
  color: #FF3E60;
}
.pageTitle.timeline .steps-timeline .step:nth-child(4) .line {
  bottom: 0;
  background-color: #EE0029;
}
.pageTitle.timeline .steps-timeline .step:nth-child(4) .line:after {
  background-color: #EE0029;
  bottom: -5px;
}
.pageTitle.timeline .steps-timeline .step:nth-child(4) .step-milestone {
  background-color: #EE0029;
}
.pageTitle.timeline .steps-timeline .step:nth-child(4) .step-milestone:after {
  border-left-color: #EE0029;
}
.pageTitle.timeline .steps-timeline .step:nth-child(4) .stepData {
  bottom: 0;
}
.pageTitle.timeline .steps-timeline .step:nth-child(4) .stepData .step-title {
  color: #EE0029;
}
.pageTitle.timeline .steps-timeline .step:nth-child(5) .line {
  top: 0;
  background-color: #C40727;
}
.pageTitle.timeline .steps-timeline .step:nth-child(5) .line:after {
  background-color: #C40727;
  top: -5px;
}
.pageTitle.timeline .steps-timeline .step:nth-child(5) .step-milestone {
  background-color: #C40727;
}
.pageTitle.timeline .steps-timeline .step:nth-child(5) .step-milestone:after {
  border-left-color: #C40727;
}
.pageTitle.timeline .steps-timeline .step:nth-child(5) .stepData {
  top: 0;
}
.pageTitle.timeline .steps-timeline .step:nth-child(5) .stepData .step-title {
  color: #C40727;
}

.pageTitle.aliceTimeline {
  padding: 50px 0;
}
.pageTitle.aliceTimeline .container .alice-carousel {
  margin-top: 30px;
  max-width: 85%;
}
.pageTitle.aliceTimeline .container .alice-carousel:before {
  content: "";
  width: 30px;
  height: 90px;
  border-bottom: solid 45px transparent;
  border-top: solid 45px transparent;
  border-left: solid 45px #007eba;
  display: block;
  position: absolute;
  top: 50%;
  left: -45px;
  transform: translate(0%, -50%) rotate(180deg);
}
.pageTitle.aliceTimeline .container .alice-carousel:after {
  content: "";
  width: 30px;
  height: 90px;
  border-bottom: solid 45px #ffffff;
  border-top: solid 45px #ffffff;
  border-left: solid 45px #ef2346;
  display: block;
  position: absolute;
  top: 50%;
  right: -44px;
  transform: translate(0%, -50%);
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper:before {
  content: "";
  background: rgb(0, 127, 188);
  background: linear-gradient(90deg, rgb(0, 127, 188) 0%, rgb(239, 35, 70) 100%);
  height: 90px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox {
  display: flex;
  padding: 300px 0;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: var(--white);
  padding: 30px 0;
  position: relative;
  box-shadow: inset -10px 0px 40px -35px #000;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear:before {
  content: "";
  width: 30px;
  height: 90px;
  border-bottom: solid 50px transparent;
  border-top: solid 45px transparent;
  border-left: solid 30px transparent;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 99;
  transform: translate(0%, -50%);
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear:after {
  content: "";
  width: 30px;
  height: 90px;
  border-bottom: solid 45px transparent;
  border-top: solid 45px transparent;
  border-left: solid 30px transparent;
  display: block;
  position: absolute;
  top: 0;
  right: -30px;
  z-index: 99;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata {
  position: absolute;
  left: 50px;
  z-index: 99;
  height: 300px;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata img {
  margin-bottom: 15px;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata span {
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
  color: var(--secondary);
  font-weight: 600;
  font-size: 18px;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata small {
  font-size: 16px;
  line-height: 16px;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox {
  flex-direction: column-reverse;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox .mdata {
  top: 20px;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox .mdata span {
  color: #014E74;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:before {
  content: "";
  top: 0;
  left: 20px;
  width: 1px;
  height: 302px;
  background-color: #014E74;
  position: absolute;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:after {
  content: "";
  top: 0;
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #014E74;
  position: absolute;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox {
  flex-direction: column;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox .mdata {
  bottom: -20px;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox .mdata span {
  color: #014E74;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:before {
  content: "";
  bottom: 0;
  left: 20px;
  width: 1px;
  height: 300px;
  background-color: #014E74;
  position: absolute;
}
.pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:after {
  content: "";
  bottom: 0;
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #014E74;
  position: absolute;
}
.pageTitle.aliceTimeline .container .alice-carousel__prev-btn, .pageTitle.aliceTimeline .container .alice-carousel__next-btn {
  position: absolute;
  top: 50%;
  width: auto;
  cursor: pointer;
  z-index: 1;
  height: 90px;
  background: #ccc;
  width: 145px;
  padding: 0;
}
.pageTitle.aliceTimeline .container .alice-carousel__prev-btn div, .pageTitle.aliceTimeline .container .alice-carousel__next-btn div {
  height: 100%;
}
.pageTitle.aliceTimeline .container .alice-carousel__prev-btn div p, .pageTitle.aliceTimeline .container .alice-carousel__next-btn div p {
  width: 100%;
  height: 100%;
}
.pageTitle.aliceTimeline .container .alice-carousel__prev-btn div p span, .pageTitle.aliceTimeline .container .alice-carousel__next-btn div p span {
  font-size: 0;
  width: 100%;
  height: 100%;
}
.pageTitle.aliceTimeline .container .alice-carousel__prev-btn {
  left: -95px;
  transform: translate(-50%, -50%);
  background: url(../../images/arrowleft.webp) no-repeat center center;
  background-size: contain;
  animation: heartbeat2 3s ease-in-out infinite both;
}
.pageTitle.aliceTimeline .container .alice-carousel__next-btn {
  right: -95px;
  transform: translate(-50%, -50%);
  background: url(../../images/arrow.webp) no-repeat center center;
  background-size: contain;
  transform: translate(50%, -50%);
  animation: heartbeat1 3s ease-in-out infinite both;
}

@media screen and (max-width: 678px) {
  .steps-timeline .step {
    display: block;
  }
  .step-milestone {
    float: left;
    margin-left: 10px;
  }
  .step-title,
  .step-description {
    text-align: left;
    margin-left: 0px;
  }
}
@media screen and (min-width: 678px) {
  .steps-timeline {
    padding-left: 150px;
    padding-right: 200px;
    display: -moz-flex;
    display: flex;
    justify-content: space-between;
  }
  .steps-timeline .step {
    /* Safari 6.1+ */
    /* IE 10 */
    flex: 1;
  }
}
@media (min-width: 1369px) and (max-width: 1600px) {
  .pageTitle.timeline {
    padding-top: 50px;
    padding-bottom: 120px;
  }
  .pageTitle.timeline .steps-timeline {
    margin-top: 60px;
    font-size: 14px;
    line-height: 16px;
  }
  .pageTitle.timeline .steps-timeline:before {
    width: 150px;
    height: 90px;
  }
  .pageTitle.timeline .steps-timeline:after {
    width: 150px;
    height: 90px;
  }
  .pageTitle.timeline .steps-timeline .step {
    padding: 250px 0;
  }
  .pageTitle.timeline .steps-timeline .step .line {
    height: 250px;
    left: 100px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone {
    height: 90px;
    font-size: 22px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone:after {
    width: 30px;
    height: 90px;
    border-bottom: solid 45px transparent;
    border-top: solid 45px transparent;
    border-left: solid 45px transparent;
    right: -45px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone:before {
    width: 30px;
    height: 90px;
    border-left: solid 45px var(--white);
    border-bottom: solid 45px transparent;
    border-top: solid 45px transparent;
  }
  .pageTitle.timeline .steps-timeline .step .stepData {
    width: 300px;
    left: 125px;
  }
  .pageTitle.timeline .steps-timeline .step .stepData .step-title {
    font-size: 20px;
    line-height: 20px;
  }
  .pageTitle.timeline .steps-timeline .step .stepData .ste-description {
    font-size: 20px;
    line-height: 26px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel {
    max-width: 85%;
  }
  .pageTitle.aliceTimeline .container .alice-carousel:before {
    width: 45px;
    height: 80px;
    border-bottom: solid 40px transparent;
    border-top: solid 40px transparent;
    border-left: solid 40px #007eba;
    left: -44px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel:after {
    width: 45px;
    height: 80px;
    border-bottom: solid 40px #ffffff;
    border-top: solid 40px #ffffff;
    border-left: solid 40px #ef2346;
    right: -44px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper:before {
    height: 80px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox {
    padding: 280px 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear {
    font-size: 25px;
    padding: 20px 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear:before {
    width: 30px;
    height: 80px;
    border-bottom: solid 50px transparent;
    border-top: solid 45px transparent;
    border-left: solid 30px transparent;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear:after {
    width: 30px;
    height: 80px;
    border-bottom: solid 45px transparent;
    border-top: solid 45px transparent;
    border-left: solid 30px transparent;
    right: -30px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata {
    left: 40px;
    height: 280px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox {
    flex-direction: column-reverse;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox .mdata {
    top: 20px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:before {
    left: 20px;
    height: 282px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:after {
    top: 0;
    left: 15px;
    width: 10px;
    height: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox {
    flex-direction: column;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox .mdata {
    bottom: -20px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:before {
    bottom: 0;
    left: 20px;
    height: 282px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:after {
    bottom: 0;
    left: 15px;
    width: 10px;
    height: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__prev-btn, .pageTitle.aliceTimeline .container .alice-carousel__next-btn {
    height: 80px;
    width: 145px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__prev-btn {
    left: -80px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__next-btn {
    right: -80px;
  }
}
@media (min-width: 1201px) and (max-width: 1368px) {
  .pageTitle.timeline {
    padding-top: 50px;
    padding-bottom: 120px;
  }
  .pageTitle.timeline .steps-timeline {
    margin-top: 60px;
    font-size: 14px;
    line-height: 16px;
  }
  .pageTitle.timeline .steps-timeline:before, .pageTitle.timeline .steps-timeline:after {
    width: 130px;
    height: 70px;
  }
  .pageTitle.timeline .steps-timeline .step {
    padding: 240px 0;
  }
  .pageTitle.timeline .steps-timeline .step .line {
    height: 240px;
    left: 100px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone {
    height: 70px;
    font-size: 18px;
    padding-right: 25px;
    justify-content: end;
    padding-left: 25px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone:after {
    width: 30px;
    height: 70px;
    border-bottom: solid 35px transparent;
    border-top: solid 35px transparent;
    border-left: solid 35px transparent;
    right: -35px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone:before {
    width: 45px;
    height: 70px;
    border-left: solid 35px var(--white);
    border-bottom: solid 35px transparent;
    border-top: solid 35px transparent;
  }
  .pageTitle.timeline .steps-timeline .step .stepData {
    width: 250px;
    left: 125px;
    height: 240px;
  }
  .pageTitle.timeline .steps-timeline .step .stepData .step-title {
    font-size: 18px;
    line-height: 20px;
  }
  .pageTitle.timeline .steps-timeline .step .stepData .ste-description {
    font-size: 20px;
    line-height: 26px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel {
    max-width: 80%;
  }
  .pageTitle.aliceTimeline .container .alice-carousel:before {
    width: 35px;
    height: 70px;
    border-bottom: solid 35px transparent;
    border-top: solid 35px transparent;
    border-left: solid 35px #007eba;
    left: -35px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel:after {
    width: 35px;
    height: 70px;
    border-bottom: solid 35px #ffffff;
    border-top: solid 35px #ffffff;
    border-left: solid 35px #ef2346;
    right: -35px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper:before {
    height: 70px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox {
    padding: 280px 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear {
    font-size: 22px;
    padding: 20px 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear:before {
    width: 30px;
    height: 70px;
    border-bottom: solid 50px transparent;
    border-top: solid 45px transparent;
    border-left: solid 30px transparent;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear:after {
    width: 30px;
    height: 70px;
    border-bottom: solid 45px transparent;
    border-top: solid 45px transparent;
    border-left: solid 30px transparent;
    right: -30px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata {
    left: 40px;
    height: 280px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata span {
    font-size: 16px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata small {
    font-size: 15px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox {
    flex-direction: column-reverse;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox .mdata {
    top: 20px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:before {
    left: 20px;
    height: 282px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:after {
    top: 0;
    left: 15px;
    width: 10px;
    height: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox {
    flex-direction: column;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox .mdata {
    bottom: -20px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:before {
    bottom: 0;
    left: 20px;
    height: 282px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:after {
    bottom: 0;
    left: 15px;
    width: 10px;
    height: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__prev-btn, .pageTitle.aliceTimeline .container .alice-carousel__next-btn {
    height: 70px;
    width: 145px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__prev-btn {
    left: -70px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__next-btn {
    right: -70px;
  }
}
@media (min-width: 1014px) and (max-width: 1200px) {
  .pageTitle.aliceTimeline .container .alice-carousel {
    max-width: 80%;
  }
  .pageTitle.aliceTimeline .container .alice-carousel:before {
    width: 35px;
    height: 70px;
    border-bottom: solid 35px transparent;
    border-top: solid 35px transparent;
    border-left: solid 35px #007eba;
    left: -35px;
    bottom: 0;
    top: unset;
    transform: translate(0%, 0%) rotate(180deg);
  }
  .pageTitle.aliceTimeline .container .alice-carousel:after {
    width: 35px;
    height: 70px;
    border-bottom: solid 35px #ffffff;
    border-top: solid 35px #ffffff;
    border-left: solid 35px #ef2346;
    right: -35px;
    bottom: 0;
    top: unset;
    transform: translate(0%, 0%);
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper:before {
    height: 70px;
    top: unset;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox {
    padding: 220px 0 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear {
    font-size: 22px;
    padding: 20px 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear:before {
    width: 30px;
    height: 70px;
    border-bottom: solid 50px transparent;
    border-top: solid 45px transparent;
    border-left: solid 30px transparent;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear:after {
    width: 30px;
    height: 70px;
    border-bottom: solid 45px transparent;
    border-top: solid 45px transparent;
    border-left: solid 30px transparent;
    right: -30px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata {
    left: 50px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata span {
    font-size: 16px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata small {
    font-size: 15px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox {
    flex-direction: column-reverse;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox .mdata {
    top: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:before {
    left: 20px;
    height: 220px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:after {
    top: 0;
    left: 15px;
    width: 10px;
    height: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox {
    flex-direction: column;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox .mdata {
    bottom: unset;
    top: 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:before {
    bottom: unset;
    top: 0;
    left: 20px;
    height: 220px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:after {
    bottom: unset;
    top: 0;
    left: 15px;
    width: 10px;
    height: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__prev-btn, .pageTitle.aliceTimeline .container .alice-carousel__next-btn {
    height: 70px;
    width: 145px;
    bottom: 0;
    top: unset;
    animation: unset;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__prev-btn {
    left: -70px;
    transform: translate(-50%, 0%);
  }
  .pageTitle.aliceTimeline .container .alice-carousel__next-btn {
    right: -70px;
    transform: translate(50%, 0%);
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .pageTitle.timeline {
    padding-top: 50px;
    padding-bottom: 120px;
  }
  .pageTitle.timeline .steps-timeline {
    margin-top: 60px;
    font-size: 14px;
    line-height: 16px;
    padding-left: 125px;
    padding-right: 170px;
  }
  .pageTitle.timeline .steps-timeline:before {
    width: 150px;
    height: 80px;
  }
  .pageTitle.timeline .steps-timeline:after {
    width: 150px;
    height: 80px;
  }
  .pageTitle.timeline .steps-timeline .step {
    padding: 240px 0;
  }
  .pageTitle.timeline .steps-timeline .step .line {
    height: 240px;
    left: 100px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone {
    height: 80px;
    font-size: 22px;
    padding-right: 15px;
    justify-content: end;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone:after {
    width: 30px;
    height: 80px;
    border-bottom: solid 40px transparent;
    border-top: solid 40px transparent;
    border-left: solid 40px transparent;
    right: -40px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone:before {
    width: 30px;
    height: 80px;
    border-left: solid 40px var(--white);
    border-bottom: solid 40px transparent;
    border-top: solid 40px transparent;
  }
  .pageTitle.timeline .steps-timeline .step .stepData {
    width: 205px;
    left: 115px;
    height: 240px;
  }
  .pageTitle.timeline .steps-timeline .step .stepData .step-title {
    font-size: 18px;
    line-height: 20px;
  }
  .pageTitle.timeline .steps-timeline .step .stepData .step-description {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 1014px) {
  .pageTitle.aliceTimeline .container .alice-carousel {
    max-width: 70%;
  }
  .pageTitle.aliceTimeline .container .alice-carousel:before {
    width: 35px;
    height: 70px;
    border-bottom: solid 35px transparent;
    border-top: solid 35px transparent;
    border-left: solid 35px #007eba;
    left: -35px;
    bottom: 0;
    top: unset;
    transform: translate(0%, 0%) rotate(180deg);
  }
  .pageTitle.aliceTimeline .container .alice-carousel:after {
    width: 35px;
    height: 70px;
    border-bottom: solid 35px #ffffff;
    border-top: solid 35px #ffffff;
    border-left: solid 35px #ef2346;
    right: -35px;
    bottom: 0;
    top: unset;
    transform: translate(0%, 0%);
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper:before {
    height: 70px;
    top: unset;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox {
    padding: 230px 0 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear {
    font-size: 22px;
    padding: 20px 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear:before {
    width: 30px;
    height: 70px;
    border-bottom: solid 50px transparent;
    border-top: solid 45px transparent;
    border-left: solid 30px transparent;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear:after {
    width: 30px;
    height: 70px;
    border-bottom: solid 45px transparent;
    border-top: solid 45px transparent;
    border-left: solid 30px transparent;
    right: -30px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata {
    left: 50px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata span {
    font-size: 16px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata small {
    font-size: 15px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox {
    flex-direction: column-reverse;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox .mdata {
    top: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:before {
    left: 20px;
    height: 232px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:after {
    top: 0;
    left: 15px;
    width: 10px;
    height: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox {
    flex-direction: column;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox .mdata {
    bottom: unset;
    top: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:before {
    bottom: unset;
    top: 0;
    left: 20px;
    height: 232px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:after {
    bottom: unset;
    top: 0;
    left: 15px;
    width: 10px;
    height: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__prev-btn, .pageTitle.aliceTimeline .container .alice-carousel__next-btn {
    height: 70px;
    width: 145px;
    bottom: 0;
    top: unset;
    animation: unset;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__prev-btn {
    left: -70px;
    transform: translate(-50%, 0%);
  }
  .pageTitle.aliceTimeline .container .alice-carousel__next-btn {
    right: -70px;
    transform: translate(50%, 0%);
  }
}
@media (max-width: 992px) {
  .pageTitle.timeline {
    padding-top: 50px;
    padding-bottom: 120px;
  }
  .pageTitle.timeline .steps-timeline {
    margin-top: 60px;
    font-size: 14px;
    line-height: 16px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    gap: 30px;
  }
  .pageTitle.timeline .steps-timeline:before {
    width: 150px;
    height: 80px;
    display: none;
  }
  .pageTitle.timeline .steps-timeline:after {
    width: 150px;
    height: 80px;
    display: none;
  }
  .pageTitle.timeline .steps-timeline .step {
    padding: 0px 0;
    display: flex;
  }
  .pageTitle.timeline .steps-timeline .step .line {
    height: 200px;
    left: 100px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone {
    height: 100px;
    font-size: 22px;
    width: 100px;
    margin: 0;
    padding-top: 26px;
    padding-left: 0px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestonenbsp {
    display: none;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone:after {
    width: 30px;
    height: 100px;
    border-left: solid 20px transparent;
    border-bottom: solid 50px transparent;
    border-top: solid 50px transparent;
    right: -30px;
    transform: rotate(90deg);
    left: 35px;
    bottom: -65px;
    top: unset;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone:before {
    width: 30px;
    height: 100px;
    border-left: solid 20px var(--white);
    border-bottom: solid 50px transparent;
    border-top: solid 50px transparent;
    transform: rotate(90deg);
    left: 35px;
    top: -35px;
  }
  .pageTitle.timeline .steps-timeline .step .stepData {
    width: 450px;
    left: 100px;
    position: relative;
    height: auto;
  }
  .pageTitle.timeline .steps-timeline .step .stepData .step-title {
    font-size: 22px;
    line-height: 30px;
  }
  .pageTitle.timeline .steps-timeline .step .stepData .ste-description {
    font-size: 20px;
    line-height: 26px;
  }
  .pageTitle.timeline .steps-timeline .step:nth-child(1) .line, .pageTitle.timeline .steps-timeline .step:nth-child(2) .line, .pageTitle.timeline .steps-timeline .step:nth-child(3) .line, .pageTitle.timeline .steps-timeline .step:nth-child(4) .line, .pageTitle.timeline .steps-timeline .step:nth-child(5) .line {
    top: 40px;
    width: 75px;
    height: 1px;
    left: 100px;
  }
  .pageTitle.timeline .steps-timeline .step:nth-child(1) .line:after, .pageTitle.timeline .steps-timeline .step:nth-child(2) .line:after, .pageTitle.timeline .steps-timeline .step:nth-child(3) .line:after, .pageTitle.timeline .steps-timeline .step:nth-child(4) .line:after, .pageTitle.timeline .steps-timeline .step:nth-child(5) .line:after {
    top: -5px;
    left: unset;
    right: -5px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel {
    max-width: 70%;
  }
  .pageTitle.aliceTimeline .container .alice-carousel:before {
    width: 35px;
    height: 60px;
    border-bottom: solid 30px transparent;
    border-top: solid 30px transparent;
    border-left: solid 30px #007eba;
    left: -34px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel:after {
    width: 30px;
    height: 60px;
    border-bottom: solid 30px #ffffff;
    border-top: solid 30px #ffffff;
    border-left: solid 30px #ef2346;
    right: -30px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper:before {
    height: 60px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox {
    padding: 280px 0 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear {
    font-size: 20px;
    padding: 15px 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear:before {
    width: 30px;
    height: 60px;
    border-bottom: solid 50px transparent;
    border-top: solid 45px transparent;
    border-left: solid 30px transparent;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear:after {
    width: 30px;
    height: 60px;
    border-bottom: solid 45px transparent;
    border-top: solid 45px transparent;
    border-left: solid 30px transparent;
    right: -30px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata {
    left: 50px;
    width: auto;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox {
    flex-direction: column-reverse;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox .mdata {
    top: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:before {
    left: 20px;
    height: 280px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:after {
    top: 0;
    left: 15px;
    width: 10px;
    height: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox {
    flex-direction: column;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox .mdata {
    bottom: unset;
    top: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:before {
    bottom: unset;
    top: 0;
    left: 20px;
    height: 280px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:after {
    bottom: unset;
    top: 0;
    left: 15px;
    width: 10px;
    height: 10px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__prev-btn, .pageTitle.aliceTimeline .container .alice-carousel__next-btn {
    height: 60px;
    width: 75px;
    bottom: 0;
    top: unset;
    animation: unset;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__prev-btn {
    left: -60px;
    transform: translate(-50%, 0%);
  }
  .pageTitle.aliceTimeline .container .alice-carousel__next-btn {
    right: -60px;
    transform: translate(50%, 0%);
  }
}
@media (max-width: 767px) {
  .pageTitle.timeline {
    padding-top: 50px;
    padding-bottom: 120px;
  }
  .pageTitle.timeline .steps-timeline {
    margin-top: 60px;
    font-size: 14px;
    line-height: 16px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    gap: 50px;
    display: flex;
    margin-left: 0;
  }
  .pageTitle.timeline .steps-timeline:before {
    width: 150px;
    height: 80px;
    display: none;
  }
  .pageTitle.timeline .steps-timeline:after {
    width: 150px;
    height: 80px;
    display: none;
  }
  .pageTitle.timeline .steps-timeline .step {
    padding: 0px 0;
    display: flex;
  }
  .pageTitle.timeline .steps-timeline .step .line {
    height: 200px;
    left: 100px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone {
    height: 80px;
    font-size: 22px;
    width: 100px;
    margin: 0;
  }
  .pageTitle.timeline .steps-timeline .step .stepData {
    width: 300px;
    left: 100px;
    position: relative;
  }
  .pageTitle.timeline .steps-timeline .step .stepData .step-title {
    font-size: 22px;
    line-height: 30px;
  }
  .pageTitle.timeline .steps-timeline .step .stepData .ste-description {
    font-size: 20px;
    line-height: 26px;
  }
  .pageTitle.timeline .steps-timeline .step:nth-child(1) .line:after, .pageTitle.timeline .steps-timeline .step:nth-child(2) .line:after, .pageTitle.timeline .steps-timeline .step:nth-child(3) .line:after, .pageTitle.timeline .steps-timeline .step:nth-child(4) .line:after, .pageTitle.timeline .steps-timeline .step:nth-child(5) .line:after {
    top: -5px;
    left: unset;
    right: -5px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox {
    padding: 230px 0 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata {
    left: 40px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:before {
    height: 230px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:before {
    height: 230px;
  }
}
@media (max-width: 600px) {
  .pageTitle.timeline {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pageTitle.timeline .steps-timeline {
    margin-top: 60px;
    font-size: 14px;
    line-height: 16px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    gap: 50px;
    display: flex;
    margin-left: 0;
  }
  .pageTitle.timeline .steps-timeline:before {
    width: 150px;
    height: 80px;
    display: none;
  }
  .pageTitle.timeline .steps-timeline:after {
    width: 150px;
    height: 80px;
    display: none;
  }
  .pageTitle.timeline .steps-timeline .step {
    padding: 0px 0;
    display: flex;
  }
  .pageTitle.timeline .steps-timeline .step .line {
    height: 200px;
    left: 100px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone {
    height: 80px;
    font-size: 16px;
    width: 80px;
    margin: 0;
    padding-top: 20px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone:after {
    width: 30px;
    height: 80px;
    border-left: solid 15px transparent;
    border-bottom: solid 40px transparent;
    border-top: solid 40px transparent;
    left: 25px;
    bottom: -55px;
  }
  .pageTitle.timeline .steps-timeline .step .step-milestone:before {
    width: 30px;
    height: 80px;
    border-left: solid 15px var(--white);
    border-bottom: solid 40px transparent;
    border-top: solid 40px transparent;
    left: 25px;
    top: -25px;
  }
  .pageTitle.timeline .steps-timeline .step .stepData {
    width: 180px;
    left: 60px;
  }
  .pageTitle.timeline .steps-timeline .step .stepData .step-title {
    font-size: 18px;
    line-height: 20px;
  }
  .pageTitle.timeline .steps-timeline .step .stepData .ste-description {
    font-size: 20px;
    line-height: 26px;
  }
  .pageTitle.timeline .steps-timeline .step:nth-child(1) .line, .pageTitle.timeline .steps-timeline .step:nth-child(2) .line, .pageTitle.timeline .steps-timeline .step:nth-child(3) .line, .pageTitle.timeline .steps-timeline .step:nth-child(4) .line, .pageTitle.timeline .steps-timeline .step:nth-child(5) .line {
    top: 40px;
    width: 40px;
    height: 1px;
    left: 80px;
  }
  .pageTitle.timeline .steps-timeline .step:nth-child(1) .line:after, .pageTitle.timeline .steps-timeline .step:nth-child(2) .line:after, .pageTitle.timeline .steps-timeline .step:nth-child(3) .line:after, .pageTitle.timeline .steps-timeline .step:nth-child(4) .line:after, .pageTitle.timeline .steps-timeline .step:nth-child(5) .line:after {
    top: -5px;
    left: unset;
    right: -5px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel {
    max-width: 80%;
  }
  .pageTitle.aliceTimeline .container .alice-carousel:before {
    left: -34px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel:after {
    right: -29px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox {
    padding: 255px 0 0;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .myear {
    font-size: 18px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item .mbox .mdata {
    left: 40px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(odd) .mbox:before {
    height: 250px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel .alice-carousel__wrapper ul.alice-carousel__stage li.alice-carousel__stage-item:nth-child(even) .mbox:before {
    height: 250px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__prev-btn {
    left: 20px;
  }
  .pageTitle.aliceTimeline .container .alice-carousel__next-btn {
    right: 20px;
  }
}
.heartbeat {
  animation: heartbeat 1.5s ease-in-out infinite both;
}
@keyframes heartbeat1 {
  from {
    transform: scale(1) translate(50%, -50%);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91) translate(50%, -50%);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98) translate(50%, -50%);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87) translate(50%, -50%);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1) translate(50%, -50%);
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat2 {
  from {
    transform: scale(1) translate(-50%, -50%);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91) translate(-50%, -50%);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98) translate(-50%, -50%);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87) translate(-50%, -50%);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1) translate(-50%, -50%);
    animation-timing-function: ease-out;
  }
}/*# sourceMappingURL=timeline.css.map */