.pageSubTitle {

    &.notfound {
        padding: 0 !important;
        div {
            text-align: center;

            img {
                width: 600px;
            }

            h1 {
                color: var(--black);
                font-weight: 700;
                text-transform: uppercase;

                span {
                    color: var(--primary);
                }
            }

            p {
                margin: 20px 0 30px;
            }

            a {
                background-color: var(--primary);
                padding: 12px 30px;
                color: var(--white);
                font-weight: 500;
                text-transform: uppercase;

                &:hover {
                    background-color: var(--secondary);
                }
            }
        }
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {
    .pageSubTitle {
        &.notfound {
            div {
                img {
                    width: 500px;
                }

                a {
                    padding: 12px 30px;
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
    .pageSubTitle {
        &.notfound {
            div {
                img {
                    width: 400px;
                }

                a {
                    padding: 12px 30px;
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .pageSubTitle {
        &.notfound {
            div {
                img {
                    width: 300px;
                }

                a {
                    padding: 12px 30px;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .pageSubTitle {
        &.notfound {
            div {
                img {
                    width: 300px;
                }

                a {
                    padding: 12px 30px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .pageSubTitle {
        &.notfound {
            div {
                img {
                    width: 300px;
                }

                a {
                    padding: 12px 30px;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .pageSubTitle {
        &.notfound {
            div {
                img {
                    width: 100%;
                }
                p {
                    br {
                        display: none;
                    }
                }
            }
        }
    }
}