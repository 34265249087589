.pageTitle.news.newsDetailsPage.policy .container .data h1 {
  font-weight: 700;
  color: #000;
  margin-bottom: 30px;
  font-size: 55px;
}
.pageTitle.news.newsDetailsPage.policy .container .data h2, .pageTitle.news.newsDetailsPage.policy .container .data h3, .pageTitle.news.newsDetailsPage.policy .container .data h4, .pageTitle.news.newsDetailsPage.policy .container .data h5, .pageTitle.news.newsDetailsPage.policy .container .data h6 {
  color: var(--primary) !important;
  font-weight: 600 !important;
}
.pageTitle.news.newsDetailsPage.policy .container .data h2 span, .pageTitle.news.newsDetailsPage.policy .container .data h3 span, .pageTitle.news.newsDetailsPage.policy .container .data h4 span, .pageTitle.news.newsDetailsPage.policy .container .data h5 span, .pageTitle.news.newsDetailsPage.policy .container .data h6 span {
  color: var(--black) !important;
}
.pageTitle.news.newsDetailsPage.policy .container .data p a {
  color: var(--black);
  transition: 0.5s;
}
.pageTitle.news.newsDetailsPage.policy .container .data p a:hover {
  color: var(--primary);
}
.pageTitle.news.newsDetailsPage.policy .container .data ul {
  padding-left: 0;
}
.pageTitle.news.newsDetailsPage.policy .container .data ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
}
.pageTitle.news.newsDetailsPage.policy .container .data ul li:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--primary);
  position: absolute;
  top: 10px;
  left: 0;
}
.pageTitle.news.newsDetailsPage.policy .container .data ul li a {
  color: var(--black);
  transition: 0.5s;
}
.pageTitle.news.newsDetailsPage.policy .container .data ul li a:hover {
  color: var(--primary);
}
@media (min-width: 1369px) and (max-width: 1600px) {
  .pageTitle.news.newsDetailsPage.policy .container .data h1 {
    margin-bottom: 30px;
    font-size: 50px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data ul li {
    padding-left: 20px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data ul li:before {
    width: 8px;
    height: 8px;
    top: 10px;
  }
}
@media (min-width: 1201px) and (max-width: 1368px) {
  .pageTitle.news.newsDetailsPage.policy .container .data h1 {
    margin-bottom: 30px;
    font-size: 45px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data ul li {
    padding-left: 20px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data ul li:before {
    width: 8px;
    height: 8px;
    top: 10px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .pageTitle.news.newsDetailsPage.policy .container .data h1 {
    margin-bottom: 30px;
    font-size: 40px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data ul li {
    padding-left: 20px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data ul li:before {
    width: 8px;
    height: 8px;
    top: 10px;
  }
}
@media (max-width: 992px) {
  .pageTitle.news.newsDetailsPage.policy .container .data h1 {
    margin-bottom: 20px;
    font-size: 35px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data p {
    font-size: 18px;
    line-height: 28px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data ul li {
    padding-left: 20px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data ul li:before {
    width: 8px;
    height: 8px;
    top: 10px;
  }
}
@media (max-width: 767px) {
  .pageTitle.news.newsDetailsPage.policy .container .data {
    padding-top: 0px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data h1 {
    margin-bottom: 20px;
    font-size: 30px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data p {
    font-size: 16px;
    line-height: 28px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data ul li {
    padding-left: 20px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data ul li:before {
    width: 8px;
    height: 8px;
    top: 10px;
  }
}
@media (max-width: 600px) {
  .pageTitle.news.newsDetailsPage.policy .container .data {
    padding-top: 30px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data h1 {
    margin-bottom: 20px;
    font-size: 30px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data p {
    font-size: 16px;
    line-height: 28px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data ul li {
    padding-left: 20px;
  }
  .pageTitle.news.newsDetailsPage.policy .container .data ul li:before {
    width: 8px;
    height: 8px;
    top: 10px;
  }
}/*# sourceMappingURL=termsAndConditions.css.map */