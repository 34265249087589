.pageSubTitle.thank-you {
  padding: 0 !important;
}
.pageSubTitle.thank-you div {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pageSubTitle.thank-you div img {
  width: 600px;
  margin-bottom: 10px;
}
.pageSubTitle.thank-you div h1 {
  color: var(--black);
  font-weight: 700;
  text-transform: uppercase;
}
.pageSubTitle.thank-you div h1 span {
  color: var(--primary);
}
.pageSubTitle.thank-you div p {
  margin: 20px 0 30px;
}
.pageSubTitle.thank-you div a {
  color: var(--text);
}
.pageSubTitle.thank-you div a:hover {
  color: var(--primary);
}
.pageSubTitle.thank-you div a.link {
  background-color: var(--primary);
  padding: 12px 30px;
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
}
.pageSubTitle.thank-you div a.link:hover {
  background-color: var(--secondary);
}

@media (min-width: 1369px) and (max-width: 1600px) {
  .pageSubTitle.thank-you div img {
    width: 500px;
  }
  .pageSubTitle.thank-you div a.link {
    padding: 12px 30px;
  }
}
@media (min-width: 1201px) and (max-width: 1368px) {
  .pageSubTitle.thank-you div img {
    width: 500px;
  }
  .pageSubTitle.thank-you div a.link {
    padding: 12px 30px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .pageSubTitle.thank-you div img {
    width: 500px;
  }
  .pageSubTitle.thank-you div a.link {
    padding: 12px 30px;
  }
}
@media (max-width: 992px) {
  .pageSubTitle.thank-you div img {
    width: 400px;
  }
  .pageSubTitle.thank-you div a.link {
    padding: 12px 30px;
  }
  .pageSubTitle.thank-you div p br {
    display: none;
  }
}
@media (max-width: 767px) {
  .pageSubTitle.thank-you div {
    width: 90%;
  }
  .pageSubTitle.thank-you div img {
    width: 400px;
  }
  .pageSubTitle.thank-you div a.link {
    padding: 12px 30px;
  }
}
@media (max-width: 600px) {
  .pageSubTitle.thank-you div img {
    width: 100%;
  }
  .pageSubTitle.thank-you div h1 span {
    display: block;
  }
}/*# sourceMappingURL=thankYou.css.map */