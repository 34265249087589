.galleryLightbox {
    margin: 100px 0;
    .my-masonry-grid {
        display: flex;
        margin-left: -20px;
        width: auto;
        .my-masonry-grid_column {
            padding-left: 20px;
            background-clip: padding-box;
            .gallery-item {
                margin-bottom: 20px;
                cursor: pointer;
                img {
                    width: 100%;
                    display: block;
                    border-radius: 8px;
                    transition: transform 0.3s;
                    &:hover {
                        transform: scale(1.01);
                    }
                }
            }
        }
    }
}

.yarl__container {
    background-color: #000000e0;
    .yarl__slide {
        padding: 100px;
    }
}

.lightbox-slide {
    img {
        width: 100%;
    }
}

.lightbox-caption {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    padding: 10px;
    font-size: 16px;
}

@media (min-width: 1369px) and (max-width: 1600px) {
	
}

@media (min-width: 1201px) and (max-width: 1368px) {
	
}

@media (min-width: 993px) and (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	.galleryLightbox {
        margin: 70px 0;
        .my-masonry-grid {
            margin-left: -15px;
            .my-masonry-grid_column {
                padding-left: 15px;
                background-clip: padding-box;
                .gallery-item {
                    margin-bottom: 15px;
                    cursor: pointer;
                    img {
                        &:hover {
                            transform: scale(0);
                        }
                    }
                }
            }
        }
    }.yarl__container .yarl__slide {
        padding: 70px;
    }
}

@media (max-width: 767px) {
	.galleryLightbox {
        margin: 50px 0;
        .my-masonry-grid {
            margin-left: -10px;
            .my-masonry-grid_column {
                padding-left: 10px;
                background-clip: padding-box;
                .gallery-item {
                    margin-bottom: 10px;
                    cursor: pointer;
                    img {
                        &:hover {
                            transform: scale(0);
                        }
                    }
                }
            }
        }
    }
    .yarl__container .yarl__slide {
        padding: 65px;
    }
}

@media (max-width: 600px) {
	.galleryLightbox {
        margin: 50px 0;
        .my-masonry-grid {
            margin-left: -10px;
            .my-masonry-grid_column {
                padding-left: 10px;
                background-clip: padding-box;
                .gallery-item {
                    margin-bottom: 10px;
                    cursor: pointer;
                    img {
                        &:hover {
                            transform: scale(0);
                        }
                    }
                }
            }
        }
    }
    .yarl__container .yarl__slide {
        padding: 45px;
    }
}