.brandPage .brands {
    padding: 130px 0;
}
.pageSubTitleWithBanner.brandsCTA {
    .container {
        background-attachment: fixed;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--black);
            opacity: .2;
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
	.brandPage .brands {
        padding: 110px 0;
    }
}

@media (max-width: 992px) {
	.brandPage .brands {
        padding: 90px 0;
    }
}

@media (max-width: 767px) {
	.brandPage .brands {
        padding: 70px 0;
    }
}

@media (max-width: 600px) {
	.brandPage .brands {
        padding: 60px 0;
    }
}