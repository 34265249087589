.pageTitle.ceo {
  background-color: #F8F8F8;
}
.pageTitle.ceo .container-fluid {
  position: relative;
  padding: 0;
}
.pageTitle.ceo .container-fluid .dots {
  position: absolute;
  top: -20px;
  left: 45%;
  width: auto;
}
.pageTitle.ceo .container-fluid .dots.dot-pattern-ten-six.xl section {
  line-height: 25px;
}
.pageTitle.ceo .container-fluid .dots.dot-pattern-ten-six.xl section div {
  margin: 0 7px;
}
.pageTitle.ceo .container-fluid .data {
  display: flex;
  padding-top: 50px;
}
.pageTitle.ceo .container-fluid .data .authorWrapper {
  padding-left: 250px;
  padding-top: 100px;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.pageTitle.ceo .container-fluid .data .authorWrapper .title h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  color: var(--black);
}
.pageTitle.ceo .container-fluid .data .authorWrapper .title h2 span {
  color: var(--primary);
}
.pageTitle.ceo .container-fluid .data .authorWrapper .quote {
  display: flex;
  align-items: start;
  gap: 40px;
  margin: 40px 0;
}
.pageTitle.ceo .container-fluid .data .authorWrapper .quote p {
  color: var(--black);
  font-size: 25px;
  line-height: 32px;
}
.pageTitle.ceo .container-fluid .data .authorWrapper .content {
  margin-bottom: 40px;
  max-width: 90%;
}
.pageTitle.ceo .container-fluid .data .authorWrapper .content p {
  font-size: 22px;
  line-height: 32px;
}
.pageTitle.ceo .container-fluid .data .authorWrapper .authorBox {
  position: relative;
}
.pageTitle.ceo .container-fluid .data .authorWrapper .authorBox:after {
  content: "";
  width: 60%;
  height: 2px;
  background-color: var(--black);
  position: absolute;
  bottom: 30px;
  right: 80px;
}
.pageTitle.ceo .container-fluid .data .authorWrapper .authorBox h3 {
  font-size: 40px;
  line-height: 48px;
  color: var(--black);
  font-weight: 600;
}
.pageTitle.ceo .container-fluid .data .authorWrapper .authorBox p {
  font-size: 25px;
  line-height: 32px;
  color: var(--black);
  font-weight: 500;
}
.pageTitle.ceo .container-fluid .data .img {
  width: 40%;
  text-align: right;
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
}
.pageTitle.ceo .container-fluid .data .img .title {
  display: none;
}
.pageTitle.ceo .container-fluid .data .img .after {
  overflow: hidden;
  width: 100%;
  position: relative;
}
.pageTitle.ceo .container-fluid .data .img .after:after {
  position: absolute;
  left: 0;
  top: 0;
  background: #F8F8F8;
  width: 225px;
  height: 100%;
  z-index: 1;
  transform: skew(-25deg);
}
.pageTitle.ceo .container-fluid .data .img .after img.ceoImage {
  width: 80%;
}
.pageTitle.ceo .container-fluid .data .img .slanding1 {
  width: 100px;
  height: 500px;
  background-color: transparent;
  position: absolute;
  bottom: 0px;
  z-index: 9;
  transform: skew(-25deg);
  overflow: hidden;
  left: 110px;
}
.pageTitle.ceo .container-fluid .data .img .slanding1 .animate__animated {
  height: 100%;
}
.pageTitle.ceo .container-fluid .data .img .slanding1 .animate__animated .inner {
  width: 100%;
  height: 100%;
  background: var(--primary);
  position: absolute;
  left: 0;
  z-index: 999;
  top: 0;
}
.pageTitle.ceo .container-fluid .data .img .slanding2 {
  width: 100px;
  height: 350px;
  background-color: transparent;
  position: absolute;
  bottom: 0px;
  z-index: 9;
  transform: skew(-25deg);
  overflow: hidden;
  left: 175px;
}
.pageTitle.ceo .container-fluid .data .img .slanding2 .animate__animated {
  height: 100%;
}
.pageTitle.ceo .container-fluid .data .img .slanding2 .animate__animated .inner {
  width: 100%;
  height: 100%;
  background: var(--secondary);
  position: absolute;
  left: 0;
  z-index: 999;
  top: 0;
}

@media (min-width: 1369px) and (max-width: 1600px) {
  .pageTitle.ceo .container-fluid .dots {
    left: 75%;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper {
    padding-left: 150px;
    padding-top: 60px;
    max-width: 70%;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .title h2 {
    font-size: 36px;
    line-height: 40px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote {
    gap: 25px;
    margin: 40px 0 20px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote img {
    width: 50px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .content {
    margin-bottom: 40px;
    max-width: 90%;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .content p {
    font-size: 18px;
    line-height: 30px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox:after {
    width: 60%;
    bottom: 30px;
    right: 80px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox h3 {
    font-size: 20px;
    line-height: 20px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox p {
    font-size: 14px;
    line-height: 20px;
  }
  .pageTitle.ceo .container-fluid .data .img {
    width: 30%;
  }
  .pageTitle.ceo .container-fluid .data .img .after:after {
    width: 115px;
  }
  .pageTitle.ceo .container-fluid .data .img .after img.ceoImage {
    width: 90%;
  }
  .pageTitle.ceo .container-fluid .data .img .slanding1 {
    width: 90px;
    height: 345px;
    left: 50px;
  }
  .pageTitle.ceo .container-fluid .data .img .slanding2 {
    width: 70px;
    height: 275px;
    left: 124px;
  }
}
@media (min-width: 1201px) and (max-width: 1368px) {
  .pageTitle.ceo .container-fluid .dots {
    left: 72%;
    top: -15px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper {
    padding-left: 120px;
    padding-top: 10px;
    max-width: 70%;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .title h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote {
    gap: 15px;
    margin: 40px 0 20px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote img {
    width: 30px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .content {
    margin-bottom: 40px;
    max-width: 90%;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .content p {
    font-size: 16px;
    line-height: 26px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox:after {
    width: 50%;
    bottom: 30px;
    right: 80px;
    height: 1px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox h3 {
    font-size: 20px;
    line-height: 25px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox p {
    font-size: 16px;
    line-height: 26px;
  }
  .pageTitle.ceo .container-fluid .data .img {
    width: 30%;
  }
  .pageTitle.ceo .container-fluid .data .img .after:after {
    width: 110px;
  }
  .pageTitle.ceo .container-fluid .data .img .after img.ceoImage {
    width: 90%;
  }
  .pageTitle.ceo .container-fluid .data .img .slanding1 {
    width: 60px;
    height: 260px;
    left: 35px;
  }
  .pageTitle.ceo .container-fluid .data .img .slanding2 {
    width: 60px;
    height: 200px;
    left: 81px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .pageTitle.ceo .container-fluid .dots {
    left: 10%;
    width: 280px;
    z-index: 99;
  }
  .pageTitle.ceo .container-fluid .data {
    flex-direction: column-reverse;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper {
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 30px;
    max-width: 100%;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .title h2 {
    font-size: 36px;
    line-height: 40px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote {
    gap: 25px;
    margin: 40px 0 20px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote img {
    width: 60px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote p {
    font-size: 20px;
    line-height: 30px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .content {
    margin-bottom: 40px;
    max-width: 100%;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .content p {
    font-size: 18px;
    line-height: 30px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox:after {
    width: 70%;
    bottom: 30px;
    right: 0px;
    height: 1px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox h3 {
    font-size: 27px;
    line-height: 28px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox p {
    font-size: 18px;
    line-height: 26px;
  }
  .pageTitle.ceo .container-fluid .data .img {
    width: 100%;
    justify-content: end;
    max-width: 50%;
    margin: 0 auto;
  }
  .pageTitle.ceo .container-fluid .data .img .after {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .pageTitle.ceo .container-fluid .data .img .after:after {
    width: 150px;
  }
  .pageTitle.ceo .container-fluid .data .img .slanding1 {
    width: 120px;
    height: 410px;
    left: 50px;
  }
  .pageTitle.ceo .container-fluid .data .img .slanding2 {
    width: 95px;
    height: 320px;
    left: 148px;
  }
}
@media (max-width: 992px) {
  .pageTitle.ceo {
    padding-bottom: 0;
  }
  .pageTitle.ceo .container-fluid .dots {
    left: 10%;
    width: 250px;
    z-index: 99;
  }
  .pageTitle.ceo .container-fluid .data {
    flex-direction: column-reverse;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper {
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 30px;
    max-width: 100%;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .title h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote {
    gap: 25px;
    margin: 40px 0 20px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote img {
    width: 60px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote p {
    font-size: 18px;
    line-height: 30px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .content {
    margin-bottom: 40px;
    max-width: 100%;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .content p {
    font-size: 16px;
    line-height: 30px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox:after {
    width: 70%;
    bottom: 30px;
    right: 0px;
    height: 1px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox h3 {
    font-size: 24px;
    line-height: 30px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox p {
    font-size: 17px;
    line-height: 24px;
  }
  .pageTitle.ceo .container-fluid .data .img {
    width: 100%;
    justify-content: end;
    max-width: 60%;
    margin: 0 auto;
  }
  .pageTitle.ceo .container-fluid .data .img .after {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .pageTitle.ceo .container-fluid .data .img .after:after {
    width: 150px;
  }
  .pageTitle.ceo .container-fluid .data .img .slanding1 {
    width: 120px;
    height: 410px;
    left: 50px;
  }
  .pageTitle.ceo .container-fluid .data .img .slanding2 {
    width: 95px;
    height: 320px;
    left: 148px;
  }
}
@media (max-width: 767px) {
  .pageTitle.ceo {
    padding-bottom: 0;
  }
  .pageTitle.ceo .container-fluid .dots {
    left: 5px;
    width: auto;
    z-index: 99;
    top: -45px;
  }
  .pageTitle.ceo .container-fluid .data {
    flex-direction: column-reverse;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper {
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 30px;
    max-width: 100%;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .title h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote {
    gap: 25px;
    margin: 40px 0 20px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote img {
    width: 60px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote p {
    font-size: 18px;
    line-height: 30px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .content {
    margin-bottom: 40px;
    max-width: 100%;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .content p {
    font-size: 16px;
    line-height: 30px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox:after {
    width: 70%;
    bottom: 30px;
    right: 0px;
    height: 1px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox p {
    font-size: 16px;
    line-height: 24px;
  }
  .pageTitle.ceo .container-fluid .data .img {
    width: 100%;
    justify-content: end;
    max-width: 100%;
    margin: 0 auto;
  }
  .pageTitle.ceo .container-fluid .data .img .after {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .pageTitle.ceo .container-fluid .data .img .after:after {
    width: 150px;
  }
  .pageTitle.ceo .container-fluid .data .img .slanding1 {
    width: 94px;
    height: 520px;
    left: 90px;
  }
  .pageTitle.ceo .container-fluid .data .img .slanding2 {
    width: 80px;
    height: 365px;
    left: 148px;
  }
}
@media (max-width: 600px) {
  .pageTitle.ceo {
    padding-bottom: 0;
  }
  .pageTitle.ceo .container-fluid .dots {
    left: unset;
    width: 170px;
    z-index: 0;
    top: -30px;
    right: 0;
  }
  .pageTitle.ceo .container-fluid .dots section {
    line-height: 12px !important;
  }
  .pageTitle.ceo .container-fluid .dots section div {
    margin: 0 5px !important;
  }
  .pageTitle.ceo .container-fluid .data {
    flex-direction: column-reverse;
    padding-top: 0;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper {
    padding-left: 15px;
    padding-top: 30px;
    padding-right: 15px;
    max-width: 100%;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .title h2 {
    font-size: 28px;
    line-height: 36px;
    display: none;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote {
    gap: 15px;
    margin: 10px 0 20px;
    flex-direction: column;
    position: relative;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote img {
    width: 60px;
    position: absolute;
    opacity: 0.2;
    left: -10px;
    top: 0;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .quote p {
    font-size: 16px;
    line-height: 26px;
    z-index: 9;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .content {
    margin-bottom: 20px;
    max-width: 100%;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .content p {
    font-size: 16px;
    line-height: 30px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox:after {
    width: 50%;
    bottom: 30px;
    right: 0px;
    height: 1px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox h3 {
    font-size: 20px;
    line-height: 28px;
  }
  .pageTitle.ceo .container-fluid .data .authorWrapper .authorBox p {
    font-size: 14px;
    line-height: 20px;
  }
  .pageTitle.ceo .container-fluid .data .img {
    width: 100%;
    justify-content: center;
    max-width: 100%;
    margin: 0 auto;
  }
  .pageTitle.ceo .container-fluid .data .img .title {
    display: block;
    position: absolute;
    top: 0px;
    left: 10px;
    z-index: 99;
    max-width: 70%;
  }
  .pageTitle.ceo .container-fluid .data .img .title h2 {
    font-size: 25px;
    line-height: 32px;
    font-weight: 700;
    color: var(--black);
    text-align: left;
  }
  .pageTitle.ceo .container-fluid .data .img .title h2 br {
    display: none;
  }
  .pageTitle.ceo .container-fluid .data .img .after {
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-top: 100px;
  }
  .pageTitle.ceo .container-fluid .data .img .after img.ceoImage {
    width: 250px;
  }
  .pageTitle.ceo .container-fluid .data .img .after:after {
    width: 75px;
  }
  .pageTitle.ceo .container-fluid .data .img .slanding1 {
    width: 50px;
    height: 200px;
    left: unset;
    right: 175px;
  }
  .pageTitle.ceo .container-fluid .data .img .slanding2 {
    width: 40px;
    height: 153px;
    left: unset;
    right: 146px;
  }
}/*# sourceMappingURL=ceo.css.map */