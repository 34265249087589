.careertabs .scroll-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: 0.5s;
}
.careertabs .scroll-button:hover {
  background-color: var(--secondary);
}
.careertabs .scroll-button:hover svg {
  color: var(--white);
}
.careertabs .scroll-button.left {
  left: -50px;
}
.careertabs .scroll-button.right {
  right: -50px;
}
.careertabs .tabs-container {
  position: relative;
  flex-grow: 1;
}
.careertabs .tabs-container .nav-tabs {
  flex-wrap: nowrap;
  border-radius: 0;
  border: 0 !important;
  overflow-y: hidden;
  justify-content: start;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.careertabs .tabs-container .nav-tabs::-webkit-scrollbar {
  display: none;
}
.careertabs .tabs-container .nav-tabs .nav-link {
  border-radius: 0 !important;
  color: var(--black);
  display: inline-block;
  cursor: pointer;
  padding: 12px 30px;
  position: relative;
  border: 0 !important;
}
.careertabs .tabs-container .nav-tabs .nav-link:after {
  content: "";
  height: 70%;
  width: 1px;
  background-color: var(--black);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
}
.careertabs .tabs-container .nav-tabs .nav-link:hover {
  color: var(--primary) !important;
}
.careertabs .tabs-container .nav-tabs .nav-link.custom-active-tab {
  color: var(--white) !important;
  background-color: var(--primary) !important;
}
.careertabs .tabs-container .nav-tabs .nav-link.custom-active-tab:after {
  display: none;
}
.careertabs .tabs-container .nav-tabs .nav-link:has(+ .custom-active-tab):after {
  display: none;
}
.careertabs .tabs-container .nav-tabs .nav-link:last-child:after {
  display: none;
}
.careertabs .tab-content {
  padding-top: 40px;
}
.careertabs .tab-content .box {
  background-color: #eee;
  margin-bottom: 40px;
  padding: 40px;
  border-radius: 10px;
}
.careertabs .tab-content .box h4 {
  color: var(--black);
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: 600;
  text-transform: capitalize;
}
.careertabs .tab-content .box p {
  margin-bottom: 30px;
}
.careertabs .tab-content .box .excerpt {
  display: flex;
  justify-content: space-between;
}
.careertabs .tab-content .box .excerpt div {
  width: 50%;
}
.careertabs .tab-content .box .excerpt div span {
  padding: 0px;
}
.careertabs .tab-content .box .excerpt div.link-whole {
  width: 50%;
  text-align: right;
  display: flex;
  align-items: end;
  justify-content: end;
}
.careertabs .tab-content .box .excerpt div.link-whole span {
  background-color: var(--primary);
  color: var(--white);
  padding: 12px 30px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s;
  display: inline-block;
}
.careertabs .tab-content .box .excerpt div.link-whole span:hover {
  background-color: var(--secondary);
}

@media (min-width: 1369px) and (max-width: 1600px) {
  .careertabs .scroll-button {
    font-size: 16px;
    padding: 10px;
  }
  .careertabs .scroll-button.left {
    left: -50px;
  }
  .careertabs .scroll-button.right {
    right: -50px;
  }
  .careertabs .tabs-container .nav-tabs .nav-link {
    padding: 12px 28px;
    font-size: 16px;
  }
  .careertabs .tab-content {
    padding-top: 35px;
  }
  .careertabs .tab-content .box {
    margin-bottom: 35px;
    padding: 35px;
    border-radius: 8px;
  }
  .careertabs .tab-content .box h4 {
    font-size: 27px;
    margin-bottom: 27px;
  }
  .careertabs .tab-content .box p {
    margin-bottom: 28px;
  }
  .careertabs .tab-content .box div span {
    padding: 10px 28px;
  }
}
@media (min-width: 1201px) and (max-width: 1368px) {
  .careertabs .scroll-button {
    font-size: 16px;
    padding: 10px;
  }
  .careertabs .scroll-button.left {
    left: -50px;
  }
  .careertabs .scroll-button.right {
    right: -50px;
  }
  .careertabs .tabs-container .nav-tabs .nav-link {
    padding: 10px 25px;
    font-size: 16px;
  }
  .careertabs .tab-content {
    padding-top: 35px;
  }
  .careertabs .tab-content .box {
    margin-bottom: 35px;
    padding: 35px;
    border-radius: 8px;
  }
  .careertabs .tab-content .box h4 {
    font-size: 27px;
    margin-bottom: 27px;
  }
  .careertabs .tab-content .box p {
    margin-bottom: 28px;
  }
  .careertabs .tab-content .box div span {
    padding: 10px 28px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .careertabs .d-flex.align-items-center.position-relative {
    margin: 0 50px;
  }
  .careertabs .d-flex.align-items-center.position-relative .scroll-button {
    font-size: 16px;
    padding: 10px;
  }
  .careertabs .d-flex.align-items-center.position-relative .scroll-button.left {
    left: -50px;
  }
  .careertabs .d-flex.align-items-center.position-relative .scroll-button.right {
    right: -50px;
  }
  .careertabs .d-flex.align-items-center.position-relative .tabs-container .nav-tabs .nav-link {
    padding: 10px 20px;
    font-size: 16px;
  }
  .careertabs .tab-content {
    padding-top: 30px;
  }
  .careertabs .tab-content .box {
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 8px;
  }
  .careertabs .tab-content .box h4 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .careertabs .tab-content .box p {
    margin-bottom: 28px;
  }
  .careertabs .tab-content .box div span {
    padding: 10px 28px;
  }
}
@media (max-width: 992px) {
  .careertabs {
    margin-bottom: 50px;
  }
  .careertabs .d-flex.align-items-center.position-relative {
    margin: 0 40px;
  }
  .careertabs .d-flex.align-items-center.position-relative .scroll-button {
    font-size: 16px;
    padding: 10px;
  }
  .careertabs .d-flex.align-items-center.position-relative .scroll-button.left {
    left: -40px;
  }
  .careertabs .d-flex.align-items-center.position-relative .scroll-button.right {
    right: -40px;
  }
  .careertabs .d-flex.align-items-center.position-relative .tabs-container .nav-tabs .nav-link {
    padding: 8px 15px;
    font-size: 15px;
  }
  .careertabs .tab-content {
    padding-top: 25px;
  }
  .careertabs .tab-content .box {
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 8px;
  }
  .careertabs .tab-content .box h4 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .careertabs .tab-content .box p {
    margin-bottom: 20px;
  }
  .careertabs .tab-content .box .excerpt div.link-whole span {
    padding: 12px 20px;
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 767px) {
  .careertabs .tab-content {
    padding-top: 20px;
  }
  .careertabs .tab-content .box {
    margin-bottom: 25px;
    padding: 25px;
    border-radius: 5px;
  }
  .careertabs .tab-content .box h4 {
    font-size: 18px;
  }
  .careertabs .tab-content .box .excerpt {
    flex-direction: column;
  }
  .careertabs .tab-content .box .excerpt div {
    width: 100%;
  }
  .careertabs .tab-content .box .excerpt div.link-whole {
    width: 100%;
    text-align: left;
    justify-content: start;
    margin-top: 15px;
  }
  .careertabs .tab-content .box .excerpt div.link-whole span:hover {
    background-color: var(--primary);
  }
}
@media (max-width: 600px) {
  .careertabs .tab-content {
    padding-top: 10px;
  }
  .careertabs .tab-content .box {
    margin-bottom: 20px;
    padding: 25px 20px 30px;
    border-radius: 5px;
  }
  .careertabs .tab-content .box h4 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .careertabs .tab-content .box p {
    margin-bottom: 15px;
  }
  .careertabs .tab-content .box div {
    text-align: left !important;
  }
  .careertabs .scroll-button {
    background-color: #eeeeee;
  }
  .careertabs .scroll-button:hover {
    background-color: #eeeeee;
  }
  .careertabs .scroll-button:hover svg {
    color: var(--black);
  }
}/*# sourceMappingURL=careerTabs.css.map */