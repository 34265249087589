.pageTitle.csr-activities {
    .locationsBoxes {
        margin-bottom: 0;
        .item {
            div {
                img {

                }
            }
            p {
                font-size: 22px;
                line-height: 28px;
                font-weight: 500;
                color: var(--black);
                margin: 20px 0;
                transition: .5s;
            }
            small {

            }
            .link-whole {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                font-size: 0;
                cursor: pointer;
            }
            &:hover {
                p {
                    color: var(--primary);
                }
            }
        }
    }
}

.content.related-blog {
    padding: 60px 0;
    background-color: rgba(29, 37, 44, 1);
    .data.no-padding {
        padding-top: 0;
        p {
            color: var(--white);
        }
        h2 {
            color: var(--white);
        }
    }
    .blog-wrapr {
        display: grid;
        grid-gap: 80px 25px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .item {
            position: relative;
            div {
                overflow: hidden;
                border-radius: 5px;
                img {
                    width: 100%;
                    height: 200px;
                    object-fit: cover;
                    transition: .5s;
                }
            }
            h4 {
                color: var(--white);
                transition: .5s;
                padding-top: 10px;
                font-size: 20px;
            }
            .link-whole {
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 9;
            }
            &:hover {
                div {
                    img {
                        transform: scale(1.02);
                    }
                }
                h4 {
                    color: var(--primary);
                }
            }
        }
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {
	.content.related-blog {
        .blog-wrapr {
            .item {
                div {
                    img {
                        height: 200px;
                    }
                }
                h4 {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1368px) {
	.content.related-blog {
        .blog-wrapr {
            .item {
                div {
                    img {
                        height: 200px;
                    }
                }
                h4 {
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
	.content.related-blog {
        .blog-wrapr {
            grid-gap: 80px 20px;
            .item {
                div {
                    img {
                        height: 150px;
                    }
                }
                h4 {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}

@media (max-width: 992px) {
	.content.related-blog {
        padding: 50px 0;
        .blog-wrapr {
            grid-gap: 20px 20px;
            grid-template-columns: 1fr 1fr;
            .item {
                div {
                    img {
                        height: 200px;
                    }
                }
                h4 {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
    .pageTitle.csr-activities {
        padding-top: 0;
        .locationsBoxes {
            .item {
                p {
                    font-size: 20px;
                    line-height: 22px;
                    font-weight: 600;
                    margin: 20px 0 10px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
	.content.related-blog {
        padding: 50px 0;
        .blog-wrapr {
            grid-gap: 20px 20px;
            grid-template-columns: 1fr 1fr;
            .item {
                div {
                    img {
                        height: 180px;
                    }
                }
                h4 {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
    .pageTitle.csr-activities {
        margin-bottom: 50px;
        padding-top: 30px;
        .locationsBoxes {
            .item {
                p {
                    font-size: 20px;
                    line-height: 22px;
                    font-weight: 600;
                    margin: 20px 0 10px;
                }
            }
        }
    }
}

@media (max-width: 600px) {
	.content.related-blog {
        padding: 40px 0;
        .blog-wrapr {
            grid-gap: 20px 10px;
            grid-template-columns: 1fr 1fr;
            .item {
                div {
                    img {
                        height: 150px;
                    }
                }
                h4 {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
    .pageTitle.csr-activities {
        margin-bottom: 50px;
        .locationsBoxes {
            .item {
                p {
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 600;
                    margin: 20px 0 10px;
                }
            }
        }
    }
}